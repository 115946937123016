import { getClientDB, getOrganisationIdFirebase } from 'api/AxiosManager';
import {
	collection,
	doc,
	endBefore,
	getDoc,
	getDocs,
	limit,
	limitToLast,
	onSnapshot,
	orderBy,
	query,
	setDoc,
	startAfter,
	updateDoc,
} from 'firebase/firestore';
import moment from 'moment';

export const GetAllOrders = async () => {
	const qry = query(
		collection(getClientDB(), `${getOrganisationIdFirebase()}/POS/orders`),
		orderBy('updated_at', 'desc')
	);
	const resultSnapshots = await getDocs(qry);
	const resultArray = resultSnapshots.docs.map((document) => ({
		...document.data(),
		id: document.id,
		updated_at: moment(document.data().updated_at).format('DD-MM-YYYY'),
	}));

	return resultArray;
};

export const GetOrderById = async (orderId: string) => {
	const response: any = await getDoc(
		doc(getClientDB(), `${getOrganisationIdFirebase()}/POS/orders`, orderId)
	);
	return { ...response.data(), id: response.id };
};

export const GetAllOrderWithPagination = async ({
	firstDocument,
	lastDocument,
	rowsPerPage,
	type,
}) => {
	const tempRowsPerPage = rowsPerPage === 0 ? 10 : rowsPerPage;

	let querySnapshots: any;
	const whereConditions = [];

	if (type === 'next') {
		const qry = query(
			collection(getClientDB(), `/${getOrganisationIdFirebase()}/POS/orders`),
			...whereConditions,
			orderBy('created_at', 'desc'),
			startAfter(lastDocument),
			limit(tempRowsPerPage)
		);

		querySnapshots = await getDocs(qry);
	} else if (type === 'prev') {
		const qry = query(
			collection(getClientDB(), `/${getOrganisationIdFirebase()}/POS/orders`),
			...whereConditions,
			orderBy('created_at', 'desc'),
			endBefore(firstDocument),
			limitToLast(tempRowsPerPage)
		);
		querySnapshots = await getDocs(qry);
	} else {
		const qry: any = query(
			collection(getClientDB(), `/${getOrganisationIdFirebase()}/POS/orders`),
			...whereConditions,
			orderBy('created_at', 'desc'),
			limit(tempRowsPerPage)
		);

		querySnapshots = await getDocs(qry);
	}
	const resultArray = querySnapshots.docs.map((document: any) => ({
		id: document.id,
		...document.data(),
	}));

	return {
		data: resultArray,
		firstDocument: querySnapshots.docs[0],
		lastDocument: querySnapshots.docs[querySnapshots.docs.length - 1],
	};
};

export const GetCounts = async () => {
	const docResult: any = await getDoc(
		doc(getClientDB(), `${getOrganisationIdFirebase()}`, 'Counts')
	);

	return docResult.data();
};

export const CancelOrder = async (orderId: string) => {
	try {
		const docResult: any = await getDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}/POS/orders`, orderId)
		);
		const orderData = docResult.data();
		const updatedData = {
			...orderData,
			order_status: 'Cancelled',
			updated_at: moment().toString(),
		};
		await setDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}/POS/orders`, orderId),
			updatedData
		);

		return {
			success: true,
			message : 'Order Cancelled Successfully'
		};
	} catch (error) {
		return {
			success: false,
		};
	}
};

export const GetOrderStatus = (orderId, callback): any =>
	onSnapshot(
		doc(getClientDB(), `${getOrganisationIdFirebase()}/POS/orders/${orderId}`),
		(snapshot) => {
			callback(snapshot);
		}
	);

export const ChangeCustomerInOrder = async (orderId: string, customer: any) => {
	try {
		await updateDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}/POS/orders`,
				orderId.toString()
			),
			{
				customer_id: customer.id,
				customer_info: customer,
			}
		);

		return {
			success: true,
		};
	} catch (error) {
		return error;
	}
};
