import React from 'react';

function Divider({ variant = 'solid', type = 'horizontal' }: IDividerProps) {
	return (
		<>
			{type === 'horizontal' && (
				<div
					className={`h-[1px] w-full border border-gray-400 ${
						variant === 'dashed' ? 'border-dashed' : 'border-solid'
					}`}
				/>
			)}
			{type === 'vertical' && (
				<div
					className={`h-full border w-[1px] border-gray-400 ${
						variant === 'dashed' ? 'border-dashed' : 'border-solid'
					}`}
				/>
			)}
		</>
	);
}

export default Divider;

export interface IDividerProps {
	variant?: 'solid' | 'dashed';
	type?: 'horizontal' | 'vertical';
}
