import MUIGridGloabl from 'components/MuiGrid/MUIGlobalGrid';
import { IProductInterface } from 'interfaces';
import React from 'react';
import { useSelector } from 'react-redux';
import Modal from 'storybook-mui/build/components/Modal';

function TaxDetails({
	showTaxDetailsModal,
	setShowTaxDetailsModal,
}: TaxDetailsProps) {
	const { laneItems, currency } = useSelector((state: any) => state.posReducer);

	const columns = [
		{
			headerName: 'Item',
			field: 'name',
			flex: 1,
		},
		{
			headerName: 'Price',
			field: 'product_price',
			flex: 1,
			renderCell: (params: any) => (
				<>
					{currency} {params.value}
				</>
			),
		},
		{
			headerName: 'Quantity',
			field: 'quantity',
			flex: 1,
		},
		{
			headerName: 'Taxes Applied',
			field: 'taxes',
			width:250,
			renderCell: (params: any) => (
				<div className='flex flex-col gap-1'>
					{params?.row?.tax_configs.map((taxConfig: any) => (
						<span>{taxConfig.name} : {taxConfig.percentage}%</span>
					))}
				</div>
			),
		},
		{
			headerName: 'Total Tax',
			field: 'total',
			flex: 1,
			renderCell: (params: any) => {
				let tempTaxAmount = 0;
				params.row.tax_configs.forEach((config) => {
					tempTaxAmount +=
						(config.percentage * params.row.product_price * params.row.quantity) /
						100;
				});
				return (
					<h1 className='p-5'>
						{currency} {tempTaxAmount}
					</h1>
				);
			},
		},
	];

	const mappedLaneItems = laneItems.map((item: IProductInterface) => ({
		id: item.product_id,
		...item,
	}));

	return (
		<Modal
			open={showTaxDetailsModal}
			setOpen={() => {
				setShowTaxDetailsModal(false);
			}}
			title='Taxes Applied'
			content={
				<div>
					<MUIGridGloabl
						dataSource={mappedLaneItems}
						columns={columns}
						rowCount={laneItems.length}
						dynamicRowHeight
					/>
				</div>
			}
			modalId='TaxDetailsModal'
			size='lg'
			closeIcon
		/>
	);
}

export default TaxDetails;

export interface TaxDetailsProps {
	showTaxDetailsModal: boolean;
	setShowTaxDetailsModal: (value: boolean) => void;
}
