import Toast, { ToastTypes } from 'components/ToastNotify';
import { CancelOrder } from 'firebaseApis/orders';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';

import { ClearPosData } from 'reducers/POS/PosActions';
import Button from 'storybook-mui/build/components/Button';
import Modal from 'storybook-mui/build/components/Modal';

function CancelOrderModal({
	showCancelOrderModal,
	setShowCancelOrderModal,
}: CancelOrderModalProps) {
	const { orderId } = useSelector((state: any) => state.posReducer);
	const dispatch: any = useDispatch();
	const cancelOrder = async () => {
		try {
			dispatch(showLoading());
			const response: any = await CancelOrder(orderId.toString());
			if (response.success) {
				dispatch(ClearPosData());
				Toast({ title: response.message, type: ToastTypes.SUCCESS });
			} else {
				Toast({ title: response.message, type: ToastTypes.ERROR });
			}
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: error?.message, type: ToastTypes.ERROR });
		}
	};
	return (
		<Modal
			title='Cancel Order'
			content={
				<>
					<h1>Are you sure you want to cancel this order ?</h1>
					<div className='flex justify-end gap-5'>
						<Button
							title='No'
							onClick={() => setShowCancelOrderModal(false)}
							variant='outlined'
							color='primary'
						/>
						<Button
							title='Yes'
							onClick={() => cancelOrder()}
							variant='contained'
							color='primary'
						/>
					</div>
				</>
			}
			modalId='Cancel Order'
			open={showCancelOrderModal}
			setOpen={setShowCancelOrderModal}
			size='sm'
		/>
	);
}

export default CancelOrderModal;

export interface CancelOrderModalProps {
	showCancelOrderModal: boolean;
	setShowCancelOrderModal: React.Dispatch<React.SetStateAction<boolean>>;
}
