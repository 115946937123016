import {
	create,
	getOrganisationIdFirebase,
	getUserFromRedux,
	siteConfigConstants,
} from 'api/AxiosManager';

export const GetProductsAnalytics = async (filters) => {
	try {
		const payload = {
			Organization_Id: getOrganisationIdFirebase() || '',
			Payment_Terminal_Id: getUserFromRedux()?.paymentTerminalId || '',
			filters,
		};
		const instance = create();
		const response = await instance.post(
			`${siteConfigConstants().REACT_APP_POS_STORE}/analytics/products`,
			payload
		);
		return response.data;
	} catch (error) {
		return error;
	}
};

export const GetOrdersAnalytics = async (filters) => {
	try {
		const payload = {
			Organization_Id: getOrganisationIdFirebase() || '',
			Payment_Terminal_Id: getUserFromRedux()?.paymentTerminalId || '',
			filters,
		};
		const instance = create();
		const response = await instance.post(
			`${siteConfigConstants().REACT_APP_POS_STORE}/analytics/orders`,
			payload
		);
		return response.data;
	} catch (error) {
		return error;
	}
};
