import {
	getClientDB,
	getOrganisationIdFirebase,
	getUserFromRedux,
} from 'api/AxiosManager';
import { doc, getDoc } from 'firebase/firestore/lite';

export const GetUserInfoById = async () => {
	try {
		const organizationId = getOrganisationIdFirebase();
		const result = await getDoc(
			doc(
				getClientDB(),
				`${organizationId}/Users/Users/${getUserFromRedux().userId}`
			)
		);

		return result.data();
	} catch (error) {
		return error;
	}
};
