import MUIGridGloabl from 'components/MuiGrid/MUIGlobalGrid';
import MUIGridPagination from 'components/MUIGridPagination';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { SearchContacts } from 'firebaseApis/contacts';
import { ChangeCustomerInOrder } from 'firebaseApis/orders';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { SetCoupon, SetCustomer, SetAffiliate } from 'reducers/POS/PosActions';
import Button from 'storybook-mui/build/components/Button';
import InputTextField from 'storybook-mui/build/components/TextField';

function SearchExisting({
	setModelView,
	setShowCustomerSelectionModal,
}: ISearchExisting) {
	const [pageNum, setPageNum] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [totalCount, setTotalCount] = useState<number>(0);
	const [contactsData, setContactsData] = useState<any>([]);
	const dispatch: any = useDispatch();
	const { orderId } = useSelector((state: any) => state.posReducer);
	const customerForm = useFormik({
		initialValues: {
			First_Name: '',
			Last_Name: '',
			Email_Id: '',
			Phone_Number: '',
		},
		onSubmit: async () => {
			try {
				dispatch(showLoading());
				const response = await SearchContacts({
					values: customerForm.values,
					pageNum,
					pageSize,
				});
				const tempContactsData = response.data;
				setContactsData(response?.data ?? []);
				setTotalPages(Math.ceil(response.pagination.totalCount / pageSize));
				setTotalCount(response?.pagination?.totalCount);
				if (tempContactsData.length === 0) {
					Toast({
						title:
							'No Contacts Matches Your Search Results , Please add a New Customer',
						type: ToastTypes.ERROR,
					});
					setContactsData([]);
				} else {
					setContactsData(tempContactsData);
				}
				dispatch(hideLoading());
			} catch (error) {
				dispatch(hideLoading());
				Toast({ title: 'Error Searching Contacts', type: ToastTypes.ERROR });
			}
		},
	});

	const columns = [
		{
			headerName: 'First Name',
			field: 'First_Name',
			flex: 1,
		},
		{
			headerName: 'Last Name',
			field: 'Last_Name',
			flex: 1,
		},
		{
			headerName: 'Email Id',
			field: 'Email_Id',
			flex: 1,
		},
		{
			headerName: 'Phone Number',
			field: 'Phone_Number',
			flex: 1,
		},
	];

	const onRowClick = async (customer) => {
		try {
			dispatch(showLoading());
			if (orderId.length !== 0) {
				dispatch(showLoading());
				await ChangeCustomerInOrder(orderId, customer);
				dispatch(hideLoading());
			}
			dispatch(SetCustomer(customer));
			dispatch(SetCoupon(null));
			dispatch(SetAffiliate(null));
			Toast({ type: ToastTypes.SUCCESS, title: 'Customer selected successfully' });
			setShowCustomerSelectionModal(false);
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			Toast({ type: ToastTypes.ERROR, title: 'Something went wrong' });
		}
	};
	return (
		<div className='flex flex-col space-y-5'>
			<form
				className='gap-5 items-start mt-5 grid grid-cols-2'
				onSubmit={customerForm.handleSubmit}
			>
				<InputTextField
					name='First_Name'
					onChange={customerForm.handleChange}
					value={customerForm.values.First_Name}
					labelName='First Name'
					fieldId=''
				/>
				<InputTextField
					name='Last_Name'
					onChange={customerForm.handleChange}
					value={customerForm.values.Last_Name}
					labelName='Last_Name'
					fieldId='Last_Name'
				/>
				<InputTextField
					name='Email_Id'
					onChange={customerForm.handleChange}
					value={customerForm.values.Email_Id}
					labelName='Email Id'
					fieldId='Email_Id'
				/>
				<InputTextField
					name='Phone_Number'
					onChange={customerForm.handleChange}
					value={customerForm.values.Phone_Number}
					labelName='Phone Number'
					fieldId='Phone_Number'
				/>
				<div className='flex justify-end w-full space-x-5 col-span-2'>
					<Button
						title='Cancel'
						variant='outlined'
						color='inherit'
						onClick={() => setModelView('SELECTION')}
					/>
					<Button title='search' type='submit' variant='contained' color='info' />
				</div>
				<div className='col-span-2'>
					{contactsData?.length > 0 && (
						<>
							<MUIGridGloabl
								columns={columns}
								dataSource={contactsData ?? []}
								rowCount={contactsData.length}
								initialState={{
									pinnedColumns: { right: ['Actions'] },
								}}
								onRowClick={(params) => onRowClick(params.row)}
							/>
							<MUIGridPagination
								onPageChange={(p) => {
									setPageNum(p === 'next' ? pageNum + 1 : pageNum - 1);
								}}
								onRowsPerPageChange={(r) => {
									setPageSize(r);
								}}
								totalRows={totalCount}
								totalPages={totalPages}
								pageSize={pageSize}
								pageNum={pageNum}
							/>
						</>
					)}
				</div>
			</form>
		</div>
	);
}

export default SearchExisting;

export interface ISearchExisting {
	setModelView: any;
	setShowCustomerSelectionModal: any;
}
