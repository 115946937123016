import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootStore } from 'reducers';
import { IPos } from 'reducers/POS';
import Button from 'storybook-mui/build/components/Button';
import Modal from 'storybook-mui/build/components/Modal';

function CashModal({
	selectedCashPayment,
	setSelectedCashPayment,
	initiatePayment,
	showCashPaymentModal,
	setShowCashPaymentModal,
}: ICashModal) {
	const { currency }: IPos = useSelector((store: RootStore) => store.posReducer);
	const [customerGivenCash = 0, setCustomerGivenCash] = useState(null);

	useEffect(() => {
		if (selectedCashPayment) {
			const options = [
				{
					label: '0-1',
					value: 1,
				},
				{
					label: '1-2',
					value: 2,
				},
				{
					label: '2-5',
					value: 5,
				},
				{
					label: '5-10',
					value: 10,
				},
				{
					label: '10-20',
					value: 20,
				},
				{
					label: '20-50',
					value: 50,
				},
				{
					label: '50-100',
					value: 100,
				},
			];

			const cash = options.find(
				(option) =>
					selectedCashPayment.amount > Number(option.label.split('-')[0]) &&
					selectedCashPayment.amount < Number(option.label.split('-')[1])
			);

			if (cash) {
				setCustomerGivenCash(cash.value);
			} else {
				setCustomerGivenCash(selectedCashPayment.amount);
			}
		}
	}, []);

	return (
		<Modal
			title='Cash Payment'
			content={
				<>
					<div className='flex flex-col gap-5'>
						<div className='flex flex-col gap-2'>
							<h1>Amount Given By Customer</h1>
							<input
								type='number'
								value={customerGivenCash}
								onChange={(e) => {
									setCustomerGivenCash(e.target.value);
								}}
								min={0}
								className='border-2 h-10 w-full px-5 outline-none border-primary-500'
							/>
							{customerGivenCash < selectedCashPayment.amount &&
								selectedCashPayment.amount && (
									<h1 className='text-red-500'>
										Amount must be greater than {currency}
										{selectedCashPayment.amount}
									</h1>
								)}
							{customerGivenCash >= selectedCashPayment.amount && (
								<h1>
									Return Amount : ${' '}
									{(customerGivenCash - selectedCashPayment.amount).toFixed(2)}
								</h1>
							)}
						</div>
						<div className='flex justify-end gap-5'>
							<Button
								title='Cancel'
								onClick={() => {
									setSelectedCashPayment(null);
									setShowCashPaymentModal(false);
									setCustomerGivenCash(0);
								}}
								variant='outlined'
								color='primary'
							/>
							<Button
								title='Pay'
								onClick={() =>
									initiatePayment(selectedCashPayment, selectedCashPayment.index)
								}
								variant='contained'
								color='primary'
								disabled={
									customerGivenCash < selectedCashPayment.amount || !customerGivenCash
								}
							/>
						</div>
					</div>
				</>
			}
			modalId='cashPaymentModal'
			open={showCashPaymentModal}
			setOpen={setShowCashPaymentModal}
			size='sm'
		/>
	);
}

export default CashModal;

export interface ICashModal {
	selectedCashPayment: any;
	setSelectedCashPayment: any;
	initiatePayment: any;
	showCashPaymentModal: any;
	setShowCashPaymentModal: any;
}
