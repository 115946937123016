import React, { useState } from 'react';
import CashImage from 'assets/images/pos-cash.png';
import VirtualCardImage from 'assets/images/pos-virtualcard.png';
import PaypalImage from 'assets/images/pos-paypal.png';
import PhysicalCardImage from 'assets/images/pos-physicalcard.png';

import RepordCard from './RepordCard';
import Filters from './Filters';

function Analytics() {
	const [data = [], setData] = useState<any>(null);
	return (
		<div className='bg-white mt-5 p-5 rounded'>
			{/* date filters */}
			<Filters setData={setData} />
			<hr />
			{data && (
				<div className='grid lg:grid-cols-4 xl:grid-cols-4 mt-5 gap-5 grid-cols-1 md:grid-cols-3 sm:grid-cols-2'>
					<RepordCard
						title='Products Sold'
						icon={CashImage}
						value={data?.products?.reduce(
							(acc: number, curr: any) => acc + curr.total,
							0
						)}
					/>
					<RepordCard
						title='Total Sales'
						icon={VirtualCardImage}
						value={data.orders.total?.toFixed(2) || 0}
						isCurrency
					/>
					<RepordCard
						title='Sales By Cash'
						icon={CashImage}
						value={data.orders.cash?.toFixed(2) || 0}
						isCurrency
					/>
					<RepordCard
						title='Sales By Card'
						icon={VirtualCardImage}
						value={data.orders.virtual_card?.toFixed(2) || 0}
						isCurrency
					/>
					<RepordCard
						title='Sales By InStore Card'
						icon={PhysicalCardImage}
						value={data.orders.physical_card || 0}
					/>
					<RepordCard
						title='Sales By Paypal'
						icon={PaypalImage}
						value={data.orders.paypal?.toFixed(2) || 0}
						isCurrency
					/>
				</div>
			)}
		</div>
	);
}

export default Analytics;
