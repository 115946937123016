import {
	create,
	getClientDB,
	getOrganisationIdFirebase,
	getUserFromRedux,
	siteConfigConstants,
} from 'api/AxiosManager';
import { where } from 'firebase/firestore';
import { collection, getDocs, query } from 'firebase/firestore/lite';
import { IProductInterface } from 'interfaces';
import store from 'reducers';
import { IPos } from 'reducers/POS';

export const GetPosProducts = async () => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`${getOrganisationIdFirebase()}/POS/Catalog_Category_Product`
			),
			where('catalog', '==', getUserFromRedux().catalog)
		);

		const response = await getDocs(qry);
		const resultSnapshots = response.docs.map((d: any) => ({
			id: d.id,
			...d.data(),
		}));
		return resultSnapshots;
	} catch (error) {
		return error;
	}
};

export const GetPosTaxConfigs = async () => {
	try {
		const qry = query(
			collection(getClientDB(), `${getOrganisationIdFirebase()}/POS/TaxConfigs`)
		);
		const response = await getDocs(qry);
		const resultSnapshots = response.docs.map((d: any) => ({
			id: d.id,
			...d.data(),
		}));
		return resultSnapshots;
	} catch (error) {
		return error;
	}
};

export const AddItemsToLane = async ({
	laneItems,
	subTotal,
	total,
	customer,
	orderId,
}: any) => {
	try {
		const { workStation, catalog, location, taxAmount }: IPos =
			store.getState().posReducer;
		const items = laneItems.map((item: IProductInterface) => ({
			ItemID: 45,
			ProductCode: item.product_id,
			EanUpc: null,
			Quantity: item.quantity,
			UnitPrice: item.product_price,
			ItemAmount: (item.product_price * item.quantity).toFixed(2),
			ProductLabel: item.name,
			Description: item.name,
			// Category: item?.category || '',
		}));

		const payload = {
			Organization_Id: getOrganisationIdFirebase(),
			Payment_Terminal_Id: getUserFromRedux()?.paymentTerminalId || '',
			customer_id: customer.id,
			customer_info: customer,
			location_id: location.id,
			location_name: location.Location_Name,
			work_station_name: workStation.Work_Station_Name,
			catalog_id: catalog?.catalog_id,
			catalog_name: catalog?.catalog,
			work_station_id: workStation.Work_Station_Id,
			orderid: orderId,
			add_to_pos: false,
			amount: {
				Subtotal: Number(subTotal).toFixed(2),
				Tax: taxAmount,
				Total: Number(total).toFixed(2),
			},
			item: items,
		};
		const instance = create();

		const result = await instance.post(
			`${
				siteConfigConstants().REACT_APP_POS_STORE
			}/item/add?Organization_Id=${getOrganisationIdFirebase()}`,
			payload
		);

		return result.data;
	} catch (error) {
		return error;
	}
};

export const AddTipInDevice = async ({ orderId, outputText }: any) => {
	const instance = create();
	const result = await instance.post(
		`${
			siteConfigConstants().REACT_APP_POS_STORE
		}/item/addtip?Organization_Id=${getOrganisationIdFirebase()}`,
		{
			Organization_Id: getOrganisationIdFirebase(),
			Payment_Terminal_Id: getUserFromRedux().paymentTerminalId,
			add_to_pos: true,
			OutputText: outputText,
			orderid: orderId,
		}
	);

	return result.data;
};

export const RemoveLineItems = async ({
	customerId,
	orderId,
	items,
	subTotal,
	tax,
	total,
}) => {
	const itemsToRemove = items.map((item: IProductInterface) => ({
		ItemID: 45,
		ProductCode: item.product_id,
		EanUpc: null,
		Quantity: item.quantity,
		UnitPrice: item.product_price,
		ItemAmount: (item.product_price * item.quantity).toFixed(2),
		ProductLabel: item.name,
		Description: item.name,
	}));
	const payload = {
		organizationid: getOrganisationIdFirebase(),
		customer_id: customerId,
		Payment_Terminal_Id: getUserFromRedux().paymentTerminalId,
		orderid: orderId,
		add_to_pos: true,
		amount: {
			Subtotal: subTotal,
			Tax: tax,
			Total: total,
		},
		item: itemsToRemove,
	};

	const instance = create();

	const result = await instance.post(
		`${
			siteConfigConstants().REACT_APP_POS_STORE
		}/item/remove?Organization_Id=${getOrganisationIdFirebase()}`,
		payload
	);

	if (result.data.status === 'success') {
		return true;
	}
	return false;
};

export const RemoveAllLineItems = async ({ customerId, orderId }) => {
	const payload = {
		organizationid: getOrganisationIdFirebase(),
		customer_id: customerId,
		orderid: orderId,
		Payment_Terminal_Id: getUserFromRedux().paymentTerminalId,
		add_to_pos: true,
		amount: {
			Subtotal: 0,
			Tax: 0,
			Total: 0,
		},
		item: [],
	};

	const instance = create();

	const result = await instance.post(
		`${
			siteConfigConstants().REACT_APP_POS_STORE
		}/item/remove?Organization_Id=${getOrganisationIdFirebase()}`,
		payload
	);

	if (result.data.status === 'success') {
		return true;
	}
	return false;
};
