import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from 'layouts/components/ScrollToTop';
import Private from 'layouts/Private';
import Home from 'views/Home';
import Reports from 'views/Reports';
import OrderDetails from 'views/Reports/Orders/index';
import Settings from 'views/Settings';
import Customers from 'views/Customers';

const Main = () => (
	<Router>
		<ScrollToTop>
			<Switch>
				<Route
					exact
					path='/'
					render={(props) => <Private component={Home} {...props} />}
				/>
				<Route
					exact
					path='/reports'
					render={(props) => <Private component={Reports} {...props} />}
				/>
				<Route
					exact
					path='/customers'
					render={(props) => <Private component={Customers} {...props} />}
				/>
				<Route
					exact
					path='/reports/order/:id'
					render={(props) => <Private component={OrderDetails} {...props} />}
				/>
				<Route
					exact
					path='/settings'
					render={(props) => <Private component={Settings} {...props} />}
				/>
			</Switch>
		</ScrollToTop>
	</Router>
);

export default Main;
