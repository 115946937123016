import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IPos } from 'reducers/POS';
import Button from 'storybook-mui/build/components/Button';
import { IPaymentMode } from '.';

function AmountColumn({
	paymentMode,
	setSelectedPaymentModes,
	selectedPaymentModes,
	index,
	totalAmountDue,
	setShowCashPaymentModal,
	setSelectedVirtualCard,
	setShowVirtualCardModal,
	setSelectedCashPayment,
	setShowGiftCardModal,
	setSelectedGiftCardPayment,
	initiatePayment,
}: IAmountColumnProps) {
	const { currency, laneItems, customer }: IPos = useSelector(
		(state: any) => state.posReducer
	);
	const [isValidAmount, setIsValidAmount] = useState(false);
	const changePaymentModeAmount = (amount: number) => {
		const newPaymentModes = [...selectedPaymentModes];
		newPaymentModes[index].amount = Number(amount.toFixed(2));
		setSelectedPaymentModes(newPaymentModes);
	};

	useEffect(() => {
		if (paymentMode.status !== 'SUCCESS') {
			changePaymentModeAmount(totalAmountDue);
		}
	}, [totalAmountDue]);

	useEffect(() => {
		const enteredAmount = Number(paymentMode.amount.toFixed(2));
		const balanceAmount = Number(totalAmountDue.toFixed(2));
		if (enteredAmount <= balanceAmount) {
			setIsValidAmount(true);
		} else {
			setIsValidAmount(false);
		}
	}, [paymentMode.amount]);

	const nonGeneralTypeLineItems = laneItems.some(
		(item: any) => (item.product_type && item.product_type !== 'general' && item.product_type !=='')
	);

	return (
		<div className='flex gap-5 items-center'>
			{paymentMode.status === 'SUCCESS' && (
				<h1>
					{currency} {paymentMode.amount}
				</h1>
			)}
			{paymentMode.status !== 'SUCCESS' && (
				<div className='flex items-center gap-5'>
					<div>
						<input
							type='number'
							className='border h-10 focus:outline-none px-5 border-gray-500 w-40'
							value={paymentMode.amount || ''}
							onChange={(e) => changePaymentModeAmount(Number(e.target.value))}
							min={0}
							max={totalAmountDue}
						/>
						<h1 className='text-red-500'>
							{!isValidAmount &&
								`Amount must be less than ${currency} ${totalAmountDue}`}
						</h1>
					</div>
					<Button
						title='Pay'
						onClick={() => {
							if (paymentMode.value === 'cash') {
								// physical card , cash will be directly triggered
								setShowCashPaymentModal(true);
								setSelectedCashPayment({
									...paymentMode,
									index,
								});
							} else if (paymentMode.value === 'virtualCards') {
								// virtual card
								setSelectedVirtualCard({
									...paymentMode,
									amount: paymentMode.amount,
									index,
								});
								setShowVirtualCardModal(true);
							} else if (paymentMode.value === 'paypal') {
								initiatePayment(paymentMode, index);
							} 
							else if (paymentMode.value === 'giftcards') {
								setSelectedGiftCardPayment({
									...paymentMode,
									amount: paymentMode.amount,
									index,
								});
								setShowGiftCardModal(true);
							}
							else {
								initiatePayment(paymentMode, index);
							}
						}}
						variant='contained'
						color='secondary'
						disabled={
							!isValidAmount || (nonGeneralTypeLineItems && customer.id === 'GUEST')
						}
						type='submit'
					/>
				</div>
			)}
		</div>
	);
}

export default AmountColumn;

export interface IAmountColumnProps {
	paymentMode: IPaymentMode;
	setSelectedPaymentModes: any;
	selectedPaymentModes: IPaymentMode[];
	index: number;
	totalAmountDue: number;
	setShowCashPaymentModal: any;
	setSelectedVirtualCard: any;
	setShowVirtualCardModal: any;
	setSelectedCashPayment: any;
	setSelectedGiftCardPayment: any;
	setShowGiftCardModal: any;
	initiatePayment: any;
}
