import { IProductInterface } from 'interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { IPos } from 'reducers/POS';
import { AddProductToLane, SetProductQuantity } from 'reducers/POS/PosActions';
import React from 'react';
import moment from 'moment';
import AddGiftCardModal from './GiftCard';
import AddPlayModal from './Play';
import AddPunchCardModal from './PunchCard';

function Product({
	product,
	index,
	hoveredItemId,
	setHoveredItemId,
}: IProductProps) {
	const [openGiftCardModal, setOpenGiftCardModal] = React.useState(false);
	const [openPunchCardModal, setOpenPunchCardModal] = React.useState(false);
	const [openPlayModal, setOpenPlayModal] = React.useState(false);
	const dispatch = useDispatch();
	const { laneItems, currency, catalog, taxConfigs, location }: IPos =
		useSelector((state: any) => state.posReducer);
	const isAlreadyinlaneItems: any = laneItems.find(
		(item: IProductInterface) => item.product_id === product.product_id
	);
	let imagePath: string;
	if (product?.image?.length) {
		imagePath = product?.image[0]?.file_path;
	} else {
		imagePath = '';
	}

	const productInCatalog = product.category.find(
		(cat) => cat.catalog_id === catalog.catalog_id
	);
	let priceToDisplay = productInCatalog?.price;

	let isDicountedPriceValid = false;
	if (productInCatalog?.is_discounted) {
		if (
			moment(productInCatalog.discount_start_date).isSameOrBefore(
				moment().format('YYYY-MM-DD')
			) &&
			moment(productInCatalog.discount_end_date).isSameOrAfter(
				moment().format('YYYY-MM-DD')
			)
		) {
			isDicountedPriceValid = true;
			priceToDisplay = productInCatalog?.discounted_price;
		}
	}

	const taxPercentages = [];
	productInCatalog?.tax_configs?.forEach((taxConfig) => {
		const configData: any = taxConfigs?.find(
			(item: any) => item.id === taxConfig
		);
		const configDataInLocation = configData?.percentages?.find(
			(item: any) => item?.locationId === location?.id
		);
		taxPercentages.push({
			name: configData?.name,
			percentage: configDataInLocation?.percentage,
		});
	});

	const onProductClick = () => {
		// if product type general then add to lane
		if (product.product_type === 'general' || !product.product_type) {
			if (!isAlreadyinlaneItems) {
				dispatch(
					AddProductToLane({
						...product,
						product_price: priceToDisplay,
						tax_configs: taxPercentages,
						is_taxable: productInCatalog?.is_taxable,
						quantity: 1,
					})
				);
			} else {
				dispatch(
					SetProductQuantity({
						...product,
						product_price: priceToDisplay,
						tax_configs: taxPercentages,
						is_taxable: productInCatalog?.is_taxable,
						quantity: isAlreadyinlaneItems.quantity + 1,
					})
				);
			}
		} else if (product.product_type === 'giftcard') {
			setOpenGiftCardModal(true);
		} else if (product.product_type === 'punchcard') {
			setOpenPunchCardModal(true);
		} else if (product.product_type === 'play') {
			setOpenPlayModal(true);
		}
	};

	return (
		<>
			<div
				key={index}
				className={`bg-white cursor-pointer rounded-lg flex flex-col justify-between cursor-pointer${
					isAlreadyinlaneItems
						? ' border-info-500 border-2'
						: 'custom-card-shadow hover:drop-shadow-2xl'
				}`}
				onClick={onProductClick}
				onMouseEnter={() => setHoveredItemId(product.product_id)}
				onMouseLeave={() => setHoveredItemId('')}
			>
				<div className='p-4'>
					<img
						src={imagePath}
						alt=''
						className='h-56 w-full rounded-xl object-contain aspect-auto'
					/>
				</div>

				<div className='p-4 flex flex-col gap-1'>
					<h1 className='text-md w-full  text-leapbrown-500 font-semibold uppercase'>
						{product.name}
					</h1>

					<p
						className={`text-gray-500 overflow-auto transform transition-all duration-500 text-md ${
							hoveredItemId === product.product_id ? 'h-32' : 'h-0'
						}`}
					>
						{product.description}
					</p>
					<div className='flex'>
						{!isDicountedPriceValid ? (
							<h1 className='font-semibold text-xl text-info-500'>
								{currency} {productInCatalog?.price?.toFixed(2)}
							</h1>
						) : (
							<div className='flex gap-5'>
								<h1 className='font-semibold text-xl text-info-500'>
									{currency} {Number(productInCatalog.discounted_price).toFixed(2)}
								</h1>
								<h1 className='font-semibold text-xl text-gray-500 line-through'>
									{currency} {productInCatalog.price.toFixed(2)}
								</h1>
							</div>
						)}
					</div>
				</div>
			</div>

			{openGiftCardModal && (
				<AddGiftCardModal
					open={openGiftCardModal}
					setOpen={setOpenGiftCardModal}
					product={product}
					priceToDisplay={priceToDisplay}
					taxPercentages={taxPercentages}
					productInCatalog={productInCatalog}
				/>
			)}

			{openPunchCardModal && (
				<AddPunchCardModal
					open={openPunchCardModal}
					setOpen={setOpenPunchCardModal}
					product={product}
				/>
			)}

			{openPlayModal && (
				<AddPlayModal
					open={openPlayModal}
					setOpen={setOpenPlayModal}
					product={product}
				/>
			)}
		</>
	);
}

export default Product;

export interface IProductProps {
	product: IProductInterface;
	index: number;
	hoveredItemId: string;
	setHoveredItemId: any;
	setShowProductInfoModal: any;
	setSelectedProduct: any;
}
