import { RewardAffiliate } from 'firebaseApis/affiliates';
import {
	AddGiftCardToCustomer,
	RewardLoyaltyPointsToCustomer,
	UpdateContactInBigQuery,
} from 'firebaseApis/contacts';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from 'reducers';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { IPos } from 'reducers/POS';
import { ClearPosData, SetPosView } from 'reducers/POS/PosActions';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import Modal from 'storybook-mui/build/components/Modal';
import Receipt from '../OrderReceipt/Receipt';

function PaymentSuccess() {
	const { view, orderId, affiliate, customer }: IPos = useSelector(
		(store: RootStore) => store.posReducer
	);
	const dispatch: any = useDispatch();

	const handleAffiliateandCouponCodeRewards = async () => {
		// reward points to affiliates
		if (affiliate) {
			await RewardAffiliate();
		}
	};

	const handleLoyaltyPoints = async () => {
		// loyalty points to customers
		try {
			dispatch(showLoading());
			if (
				customer &&
				customer.id !== 'GUEST' &&
				customer?.Pos_Loyalty_Program_Opt_In
			) {
				await RewardLoyaltyPointsToCustomer();
				await UpdateContactInBigQuery();
			}
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
		}
	};

	const handleAddGiftCard = async () => {
		await AddGiftCardToCustomer();
	};

	useEffect(() => {
		handleAffiliateandCouponCodeRewards();
		handleLoyaltyPoints();
		handleAddGiftCard();
	}, []);

	return (
		<Modal
			size='sm'
			title=''
			content={
				<div className='flex flex-col  gap-5'>
					<div className='flex justify-end'>
						<Icon
							icon={IconTypes.Cancel}
							className='cursor-pointer'
							onClick={() => {
								dispatch(ClearPosData());
								dispatch(SetPosView(''));
							}}
						/>
					</div>
					<Receipt
						orderId={orderId}
						onClose={() => {
							dispatch(ClearPosData());
							dispatch(SetPosView('HOME'));
						}}
					/>
				</div>
			}
			modalId=''
			open={view === 'PAYMENT_SUCCESSFULL'}
			setOpen={() => {}}
		/>
	);
}

export default PaymentSuccess;
