import ToggleButtonGroups from 'components/ToggleButtonGroups';
import MUiDatePicker from 'storybook-mui/build/components/MUiDatePicker';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import SelectField from 'storybook-mui/build/components/Select';
import Button from 'storybook-mui/build/components/Button';
import {
	GetOrdersAnalytics,
	GetProductsAnalytics,
} from 'firebaseApis/analytics';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { GetAllLocations, GetAllWorkStations } from 'firebaseApis/locations';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';

function Filters({ setData }: IFilters) {
	const [workStations = [], setWorkStations] = useState([]);
	const [locations = [], setLocations] = useState([]);
	const [selectedFrequency, setSelectedFrequency] = useState(
		moment().format('YYYY-MM-DD')
	);
	const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
	const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
	const [workStation = '', setWorkStation] = useState('all');
	const [location = '', setLocation] = useState('all');
	const dispatch: any = useDispatch();
	const dateFrequencies = [
		{
			content: 'Today',
			value: moment().format('YYYY-MM-DD'),
		},

		{
			content: 'Last 7 days',
			value: moment().subtract(7, 'days').format('YYYY-MM-DD'),
		},
		{
			content: 'Last 30 days',
			value: moment().subtract(30, 'days').format('YYYY-MM-DD'),
		},

		{
			content: 'This year',
			value: moment().subtract(1, 'years').format('YYYY-MM-DD'),
		},
		{
			content: 'Custom',
			value: 'custom',
		},
	];

	const getData = async () => {
		try {
			dispatch(showLoading());

			const productsResponse = await GetProductsAnalytics({
				startdate: moment(startDate).format('YYYY-MM-DD'),
				enddate: moment(endDate).format('YYYY-MM-DD'),
				work_station_id: workStation === 'all' ? '' : workStation,
				location_id: location === 'all' ? '' : location,
			});
			const ordersResponse = await GetOrdersAnalytics({
				startdate: moment(startDate).format('YYYY-MM-DD'),
				enddate: moment(endDate).format('YYYY-MM-DD'),
				work_station_id: workStation === 'all' ? '' : workStation,
				location_id: location === 'all' ? '' : location,
			});
			dispatch(hideLoading());
			const mappedProducts = productsResponse.data.map(
				(item: any, index: number) => ({
					...item,
					id: index,
				})
			);

			setData({
				products: mappedProducts,
				orders: ordersResponse.data,
			});
		} catch (error) {
			dispatch(hideLoading());
			Toast({ type: ToastTypes.ERROR, title: 'Something went wrong' });
		}
	};

	const getWorkStationsAndLocations = async () => {
		try {
			const locationsResponse = await GetAllLocations();
			const workStationsResponse = await GetAllWorkStations();
			setLocations([
				{
					Location_Name: 'All',
					id: 'all',
				},
				...locationsResponse,
			]);
			const filteredWorkStations = workStationsResponse.filter(
				(item: any) => item.is_deleted === false
			);
			setWorkStations([
				{
					id: 'all',
					Work_Station_Name: 'All',
				},
				...filteredWorkStations,
			]);
		} catch (error) {
			Toast({ type: ToastTypes.ERROR, title: 'Something went wrong' });
		}
	};

	useEffect(() => {
		getData();
		getWorkStationsAndLocations();
	}, []);

	useEffect(() => {
		getData();
	}, [selectedFrequency]);
	return (
		<div>
			<div className='flex flex-col gap-2 overflow-x-auto'>
				<h1 className='text-xl font-semibold text-info-500'>
					Select date frquency
				</h1>
				<ToggleButtonGroups
					onChange={(event, value) => {
						if (value) {
							setSelectedFrequency(value);
							if (value !== 'custom') {
								setStartDate(moment(value).format('YYYY-MM-DD'));
								setEndDate(moment().format('YYYY-MM-DD'));
							}
						}
					}}
					value={selectedFrequency}
					buttons={dateFrequencies}
					size='medium'
					color='info'
				/>

				{selectedFrequency === 'custom' && (
					<>
						<div className='flex space-x-5 w-1/2'>
							<MUiDatePicker
								labelName='Start Date'
								name='startDate'
								fieldId='startDate'
								value={startDate}
								selected={startDate}
								onChange={(date) => {
									setStartDate(date);
								}}
							/>
							<MUiDatePicker
								labelName='End Date'
								name='endDate'
								fieldId='endDate'
								value={endDate}
								selected={endDate}
								onChange={(date) => {
									setEndDate(date);
								}}
								minDate={startDate}
							/>
						</div>
					</>
				)}
			</div>

			{/* work station and location filters */}
			<div className='flex flex-col gap-2 my-5'>
				<h1 className='text-xl font-semibold text-info-600'>Filter by</h1>
				<div className='flex gap-5 flex-col lg:flex-row xs:flex-row lg:w-1/2'>
					<SelectField
						name='workStation'
						fieldId='workStation'
						onChange={(e) => {
							setWorkStation(e.target.value);
						}}
						options={workStations}
						displayField='Work_Station_Name'
						valueField='id'
						placeholder='Select Work Station'
						value={workStation}
					/>

					<SelectField
						name='location'
						fieldId='location'
						onChange={(e) => {
							setLocation(e.target.value);
						}}
						options={locations}
						displayField='Location_Name'
						valueField='id'
						placeholder='Select Location'
						value={location}
					/>
					<div className='flex justify-center gap-5'>
						<Button
							title='Clear'
							variant='outlined'
							color='info'
							onClick={() => {
								setWorkStation('all');
								setLocation('all');
								getData();
							}}
						/>
						<Button
							title='Filter'
							variant='contained'
							color='info'
							onClick={() => getData()}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Filters;

export interface IFilters {
	setData: (data: any) => void;
}
