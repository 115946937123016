import Toast, { ToastTypes } from 'components/ToastNotify';
import { RedeemGiftCard, ValidateGiftCard } from 'firebaseApis/contacts';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from 'reducers';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { IPos } from 'reducers/POS';
import Button from 'storybook-mui/build/components/Button';
import Modal from 'storybook-mui/build/components/Modal';
import InputTextField from 'storybook-mui/build/components/TextField';

function GiftCardModal({
	selectedGiftCardPayment,
	setSelectedGiftCardPayment,
	initiatePayment,
	showGiftCardPaymentModal,
	setShowGiftCardPaymentModal,
}: ICashModal) {
	const { currency, customer }: IPos = useSelector(
		(store: RootStore) => store.posReducer
	);
	const [isGiftCardValid, setIsGiftCardValid] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [giftCard, setGiftCard] = useState(null);
	const [giftCardNumber, setGiftCardNumber] = useState('');
	const dispatch: any = useDispatch();

	const validateGiftCard = async () => {
		try {
			dispatch(showLoading());
			const response: any = await ValidateGiftCard(giftCardNumber);
			if (response.success) {
				if (response.data.balance < selectedGiftCardPayment.amount) {
					setErrorMessage(
						`This gift card has a balance of ${currency}${response.data.balance} and cannot be used for this payment`
					);
				} else if (response.data.customerId !== customer.id) {
					setErrorMessage(
						'This gift card cannot be applied to the selected customer'
					);
				} else {
					setGiftCard(response.data);
					setIsGiftCardValid(true);
				}
			} else {
				Toast({ type: ToastTypes.ERROR, title: 'Invalid Card' });
			}
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			Toast({ type: ToastTypes.ERROR, title: 'Invalid Card' });
		}
	};

	const onPayHandler = async () => {
		try {
			dispatch(showLoading());
			// reduce the balance of the gift card
			const giftCardBalance = giftCard.balance - selectedGiftCardPayment.amount;
			const response: any = await RedeemGiftCard(giftCard.id, {
				...giftCard,
				balance: giftCardBalance,
			});
			if (response.success) {
				initiatePayment(selectedGiftCardPayment, selectedGiftCardPayment.index);
			} else {
				Toast({ type: ToastTypes.ERROR, title: 'Gift Card Redeem Failed' });
			}
			setShowGiftCardPaymentModal(false);
			setSelectedGiftCardPayment(null);
		} catch (error) {
			dispatch(hideLoading());
			Toast({ type: ToastTypes.ERROR, title: 'Gift Card Redeem Failed' });
		}
	};

	return (
		<Modal
			title='Gift Card Payment'
			content={
				<>
					<h1 className='text-xl'>
						Amount to Redeem: {currency} {selectedGiftCardPayment.amount}
					</h1>
					<div className='flex flex-col gap-5 mt-5'>
						<InputTextField
							labelName='Gift Card Number'
							value={giftCardNumber}
							onChange={(e) => setGiftCardNumber(e.target.value)}
							fieldId='giftCardNumber'
							name=''
						/>
						{isGiftCardValid && (
							<div className='p-2 bg-teal-700 rounded text-white flex flex-col uppercase'>
								<h1>
									Card Worth: {currency} {giftCard.redeemValue}
								</h1>
								<h1>
									Redeemed : {currency} {giftCard.redeemValue - giftCard.balance}
								</h1>
								<h1>
									Balance: {currency} {giftCard.balance}
								</h1>
								<h1>Purchased On: {giftCard.purchasedOn}</h1>
							</div>
						)}

						{errorMessage && (
							<div className='p-2 bg-error rounded text-white flex flex-col'>
								<h1>{errorMessage}</h1>
							</div>
						)}

						<div className='flex justify-end gap-5'>
							<Button
								title='Cancel'
								onClick={() => {
									setShowGiftCardPaymentModal(false);
									setSelectedGiftCardPayment(null);
								}}
								variant='outlined'
								color='info'
							/>
							{!giftCard && (
								<Button
									title='Validate'
									onClick={validateGiftCard}
									variant='contained'
									color='info'
								/>
							)}

							{giftCard && (
								<>
									<Button
										title='Clear'
										onClick={() => {
											setGiftCardNumber('');
											setGiftCard(null);
											setIsGiftCardValid(false);
										}}
										variant='outlined'
										color='info'
									/>
									<Button
										title='Pay'
										onClick={onPayHandler}
										variant='contained'
										color='info'
										disabled={!isGiftCardValid}
									/>
								</>
							)}
						</div>
					</div>
				</>
			}
			modalId='cashPaymentModal'
			open={showGiftCardPaymentModal}
			setOpen={setShowGiftCardPaymentModal}
			size='sm'
		/>
	);
}

export default GiftCardModal;

export interface ICashModal {
	selectedGiftCardPayment: any;
	setSelectedGiftCardPayment: any;
	initiatePayment: any;
	showGiftCardPaymentModal: any;
	setShowGiftCardPaymentModal: any;
}
