import React, { useEffect, useState } from 'react';
import { IProductInterface } from 'interfaces';
import { useSelector } from 'react-redux';

import { IPos } from 'reducers/POS';

import ProductInfoModal from './ProductInfoModal';
import Product from './Product';

function Categories() {
	const [searchKey, setSearchKey] = useState('');

	const { sidebarStatus, catalog }: IPos = useSelector(
		(state: any) => state.posReducer
	);
	const [hoveredItemId, setHoveredItemId] = useState<any>('');
	const [data, setData] = useState<any>([]);
	const [selectedLevelIndex, setSelectedLevelIndex] = useState(0);
	const [showProductInfoModal, setShowProductInfoModal] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState<any>();

	const getProducts: any = (products: Array<IProductInterface>) =>
		products.map(
			(product: IProductInterface, index: number) =>
				product.category.length > 0 && (
					<Product
						key={index}
						product={product}
						index={index}
						hoveredItemId={hoveredItemId}
						setHoveredItemId={setHoveredItemId}
						setSelectedProduct={setSelectedProduct}
						setShowProductInfoModal={setShowProductInfoModal}
					/>
				)
		);

	const getSubLevels: any = (level: any) => {
		const xlClasses =
			sidebarStatus === 'opened' ? 'xl:grid-cols-3' : 'xl:grid-cols-4';
		const lgClasses =
			sidebarStatus === 'opened' ? 'lg:grid-cols-2' : 'lg:grid-cols-3';
		const mdClasses =
			sidebarStatus === 'opened' ? 'md:grid-cols-1' : 'md:grid-cols-2';
		const sortedProductsInThisLevel = level.products
			.sort((a: IProductInterface, b: IProductInterface) =>
				a.name.localeCompare(b.name)
			)
			.filter((product: IProductInterface) => {
				if (searchKey === '') return true;
				return product.name.toLowerCase().includes(searchKey.toLowerCase());
			});
		return (
			<>
				<div
					className={`grid w-full items-start gap-4 ${lgClasses} ${mdClasses} ${xlClasses}`}
				>
					{getProducts(sortedProductsInThisLevel)}
				</div>
				{level.levels.map((sublevel, index: number) => (
					<div key={index} className='mb-5'>
						{(sublevel.products.length > 0 || sublevel.levels.length > 0) && (
							<div className='flex'>
								<div className='w-full flex items-center gap-2'>
									<h1 className='text-2xl text-gray-darkest font-semibold uppercase my-5'>
										{sublevel.label.name}
									</h1>
									<span className='divide-gradient-gray w-1/2 h-2' />
								</div>

								<div className='bg-gray-darkest h-5'>{}</div>
							</div>
						)}
						<div className=''>{getSubLevels(sublevel)}</div>
					</div>
				))}
			</>
		);
	};

	const getData = async () => {
		const allCategories = catalog.values[0].levels;
        
		
		const filteredCategories = allCategories.filter(
			(item: any) => item.levels.length > 0 || item.products.length > 0
		);
		const finalProducts = [];
		filteredCategories.forEach((item: any) => {
			const rootLevelProducts = item.products.length > 0;
			const nestedLevelsHavingProducts = item.levels.filter(
				(i: any) => i.products.length > 0
			);
			if (rootLevelProducts || nestedLevelsHavingProducts.length > 0) {
				finalProducts.push(item);
			}
		});
		setData(finalProducts);
	};

	useEffect(() => {
		if (catalog) {
			getData();
		}
	}, [catalog]);

	return (
		data?.length > 0 && (
			<div>
				<div className='flex flex-col gap-2 mt-4 w-full'>
					<div className='w-full flex items-center overflow-auto gap-4 py-2 flex-wrap'>
						{data.map((rootLevel, index: any) => (
							<div
								className={`px-4 py-2 rounded shadow-md w-36 uppercase text-center ${
									selectedLevelIndex === index
										? 'bg-primary-500 text-white'
										: 'bg-gray-50 text-gray-dark cursor-pointer hover:shadow-lg hover:shadow-gray-500/20 hover:bg-gray-100'
								}`}
								onClick={() => setSelectedLevelIndex(index)}
								key={index}
							>
								{rootLevel.label.name}
							</div>
						))}
					</div>
					<div className='flex gap-5 items-center'>
						<input
							type='text'
							className='h-12 rounded my-2 px-5 focus:outline-none focus:border-2 border-gray-500 w-96'
							placeholder='Search Items'
							value={searchKey}
							onChange={(e) => setSearchKey(e.target.value)}
						/>
						<h1 className='underline cursor-pointer' onClick={() => setSearchKey('')}>
							Clear search
						</h1>
					</div>
					<div className='w-full overflow-auto pb-16 min-h-screen'>
						{getSubLevels(data[selectedLevelIndex])}
					</div>
				</div>

				{showProductInfoModal && (
					<ProductInfoModal
						showProductInfoModal={showProductInfoModal}
						setShowProductInfoModal={setShowProductInfoModal}
						product={selectedProduct}
					/>
				)}
			</div>
		)
	);
}

export default Categories;
