import Toast, { ToastTypes } from 'components/ToastNotify';
import { AddItemsToLane } from 'firebaseApis/lineitems';
import { CancelOrder } from 'firebaseApis/orders';
import { IProductInterface } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from 'reducers';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { IPos } from 'reducers/POS';
import {
	ClearPosData,
	SetFinalAmountAfterAllDiscounts,
	SetOrderId,
	SetPosView,
	SetSubTotal,
	SetTaxAmount,
	SetTotalAmountWithTaxWithoutGratuity,
} from 'reducers/POS/PosActions';
import Button from 'storybook-mui/build/components/Button';
import Modal from 'storybook-mui/build/components/Modal';
import TaxDetails from './TaxDetails';

function Footer() {
	const {
		laneItems,
		customer,
		subTotal,
		taxAmount,
		totalAmountWithTaxWithoutGratuity,
		currency,
		orderId,
	}: IPos = useSelector((store: RootStore) => store.posReducer);
	const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
	const [showTaxDetails, setShowTaxDetails] = React.useState(false);
	const dispatch: any = useDispatch();

	const checkout = async () => {
		try {
			dispatch(showLoading());
			const response = await AddItemsToLane({
				laneItems,
				subTotal,
				tax: 0,
				total: subTotal,
				customer,
				orderId,
			});
			if (response.status === 'success') {
				dispatch(SetOrderId(response.data.orderid));
				dispatch(
					SetFinalAmountAfterAllDiscounts(totalAmountWithTaxWithoutGratuity)
				);
				dispatch(SetPosView('GRATUITY'));
			} else {
				Toast({ title: 'Add line item failed', type: ToastTypes.ERROR });
			}
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: 'Adding line items failed', type: ToastTypes.ERROR });
		}
	};

	useEffect(() => {
		const tempSubTotalAmount = laneItems.reduce(
			(acc: number, product: any) =>
				acc + product.product_price * product.quantity,
			0
		);
		let tempTaxAmount = 0;
		laneItems.forEach((product: IProductInterface) => {
			if (product.is_taxable) {
				product.tax_configs.forEach((taxConfig: any) => {
					tempTaxAmount +=
						(product.product_price * product.quantity * taxConfig.percentage || 0) /
						100;
				});
			}
		});
		dispatch(SetSubTotal(tempSubTotalAmount));
		dispatch(SetTaxAmount(tempTaxAmount));
		dispatch(
			SetTotalAmountWithTaxWithoutGratuity(tempSubTotalAmount + tempTaxAmount)
		);
		dispatch(SetFinalAmountAfterAllDiscounts(tempSubTotalAmount + tempTaxAmount));
	}, [dispatch, laneItems]);

	const cancelOrder = async () => {
		try {
			dispatch(showLoading());
			const response: any = await CancelOrder(orderId.toString());
			dispatch(hideLoading());
			if (response.success) {
				dispatch(ClearPosData());
				Toast({ title: response.message, type: ToastTypes.SUCCESS });
				setShowCancelOrderModal(false);
			} else {
				Toast({ title: response.message, type: ToastTypes.ERROR });
			}
		} catch (error) {
			Toast({ title: error?.message, type: ToastTypes.ERROR });
			setShowCancelOrderModal(false);
		}
	};

	return (
		<div className='p-5'>
			<div className='flex flex-col gap-2 rounded-md bg-gray-100 w-full px-4 py-2 font-semibold text-info-500'>
				<div className='flex justify-between w-full items-center'>
					<h1>Sub Total</h1>
					<h1>
						{currency} {subTotal}
					</h1>
				</div>
				<div className='flex justify-between w-full items-center'>
					<h1>Tax</h1>
					<h1
						className='underline cursor-pointer'
						onClick={() => {
							setShowTaxDetails(true);
						}}
					>
						{currency} {taxAmount}
					</h1>
				</div>
				<hr />
				<div className='flex justify-between w-full items-center text-leapbrown-500'>
					<h1>Total</h1>
					<h1>
						{currency} {totalAmountWithTaxWithoutGratuity}
					</h1>
				</div>
			</div>

			<div className='w-full flex flex-col gap-2 mt-4'>
				<div className='grid grid-cols-2 w-full items-center gap-5 '>
					<Button
						title='Cancel Order'
						variant='outlined'
						size='large'
						onClick={() => {
							setShowCancelOrderModal(true);
						}}
						disabled={!orderId}
					/>

					<Button
						title='Checkout'
						variant='contained'
						size='large'
						disabled={laneItems.length === 0}
						onClick={() => {
							checkout();
						}}
					/>
				</div>
			</div>

			{showTaxDetails && (
				<TaxDetails
					showTaxDetailsModal={showTaxDetails}
					setShowTaxDetailsModal={setShowTaxDetails}
				/>
			)}
			{showCancelOrderModal && (
				<Modal
					title='Cancel Order'
					content={
						<>
							<h1>Are you sure you want to cancel this order ?</h1>
							<div className='flex justify-end gap-5'>
								<Button
									title='No'
									onClick={() => setShowCancelOrderModal(false)}
									variant='outlined'
									color='primary'
								/>
								<Button
									title='Yes'
									onClick={() => cancelOrder()}
									variant='contained'
									color='primary'
								/>
							</div>
						</>
					}
					modalId='Cancel Order'
					open={showCancelOrderModal}
					setOpen={setShowCancelOrderModal}
					size='sm'
				/>
			)}
		</div>
	);
}

export default Footer;
