import React from 'react';
import ReactDOM from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-data-grid-pro';

// Toast
import { ToastContainer } from 'react-toastify';

// Redux
import { Provider } from 'react-redux';
import store from 'reducers';
import { I18nContextProvider } from './i18n';

import './styles/index.scss';

import Main from './main';

LicenseInfo.setLicenseKey(
	'249b04169d630d47e87bbf0c9eaa51cfT1JERVI6Mzk0MDEsRVhQSVJZPTE2Nzg1MTI0NTQwMDAsS0VZVkVSU0lPTj0x'
);

const theme = createTheme({
	typography: {
		fontFamily: `"Noto Sans","Roboto", "Helvetica", "Arial", sans-serif`,
	},
	palette: {
		primary: {
			main: '#DF8B0C',
		},
		secondary: {
			main: '#004C93',
		},
		info: {
			main: '#004C93',
		},
		warning: {
			main: '#713D00',
		},
		error: {
			main: '#D08360',
		},
	},
});

const container = document.getElementById('root');

// Create a root.
const root = ReactDOM.createRoot(container);

// Initial render: Render an element to the root.
root.render(
	<>
		<I18nContextProvider>
			<Provider store={store as any}>
				<ThemeProvider theme={theme}>
					<Main />
				</ThemeProvider>
			</Provider>
		</I18nContextProvider>
		<ToastContainer />
	</>
);
