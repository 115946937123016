import Toast, { ToastTypes } from 'components/ToastNotify';
import { CheckCouponValidity } from 'firebaseApis/coupons';
import ErrorMessage from 'components/ErrorMessage';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import {
	SetCoupon,
	SetCouponDiscountAmount,
	SetFinalAmountAfterAllDiscounts,
} from 'reducers/POS/PosActions';
import Button from 'storybook-mui/build/components/Button';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import Modal from 'storybook-mui/build/components/Modal';
import InputTextField from 'storybook-mui/build/components/TextField';
import { ICoupon } from 'interfaces';
import { IPos } from 'reducers/POS';

function AddCoupon({
	showAddCouponModal,
	setShowAddCouponModal,
	setTotalAmountDue,
}: IAddCouponProps) {
	const [couponCode, setCouponCode] = React.useState('');
	const [couponInfo, setCouponInfo] = React.useState<ICoupon>();
	const dispatch: any = useDispatch();
	const [errorMessage, setErrorMesssage] = useState<string>('');
	const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
	const {
		coupon,
		subTotal,
		gratuityAmount,
		taxAmount,
		totalAmountPaid,
		totalAmountWithTaxAndGratuity,
	}: IPos = useSelector((state: any) => state.posReducer);
	const getCouponInfo = async () => {
		try {
			dispatch(showLoading());
			const response: any = await CheckCouponValidity(couponCode);
			if (response.success) {
				setCouponInfo(response.data);
				Toast({ type: ToastTypes.SUCCESS, title: response.message });
			} else {
				setErrorMesssage(response.message);
				setShowErrorMessage(true);
			}
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			Toast({ type: ToastTypes.ERROR, title: error.message });
		}
	};

	const selectCoupon = async () => {
		try {
			dispatch(SetCoupon(couponInfo));
			setShowAddCouponModal(false);
		} catch (error) {
			Toast({ type: ToastTypes.ERROR, title: error.message });
		}
	};

	useEffect(() => {
		if (coupon) {
			// adding coupon
			const couponDiscountAmountTemp = subTotal * (coupon.discountValue / 100);
			const subTotalAterCouponDiscount = subTotal - couponDiscountAmountTemp;
			const finalAmount = subTotalAterCouponDiscount + taxAmount + gratuityAmount;
			dispatch(SetCouponDiscountAmount(couponDiscountAmountTemp));
			dispatch(SetFinalAmountAfterAllDiscounts(finalAmount));
			setTotalAmountDue(finalAmount - totalAmountPaid);
		} else {
			// removing coupon
			dispatch(SetCouponDiscountAmount(0));
			dispatch(SetFinalAmountAfterAllDiscounts(totalAmountWithTaxAndGratuity));
			setTotalAmountDue(totalAmountWithTaxAndGratuity - totalAmountPaid);
		}
	}, [coupon, gratuityAmount]);

	return (
		<Modal
			title='Add Coupon'
			size='sm'
			content={
				<>
					<h1>
						<div className='flex flex-col gap-5 mt-2'>
							<InputTextField
								name='couponCode'
								fieldId='couponCode'
								onChange={(e) => setCouponCode(e.target.value)}
								value={couponCode}
								labelName='Enter Or Scan Coupon Code'
							/>
							{couponInfo && (
								<div className='flex justify-between items-center text-white bg-teal-700 shadow border p-2'>
									<div>
										<h1>Coupon Name : {couponInfo?.name?.toUpperCase()} </h1>
										<h1>Discount Type : {couponInfo?.discountType?.toUpperCase()}</h1>
										<h1>Customer Discount Value : {couponInfo?.discountValue}</h1>
									</div>
									<div>
										<Icon
											className='text-4xl cursor-pointer'
											onClick={() => {
												setCouponInfo(null);
												setCouponCode('');
											}}
											icon={IconTypes.Delete}
										/>
									</div>
								</div>
							)}

							{showErrorMessage && (
								<ErrorMessage
									setShowErrorMessage={setShowErrorMessage}
									message={errorMessage}
								/>
							)}

							<div className='flex justify-end w-full gap-5'>
								<Button
									variant='outlined'
									color='info'
									title='Cancel'
									onClick={() => setShowAddCouponModal(false)}
								/>
								{couponInfo ? (
									<Button
										variant='contained'
										color='info'
										title='Select'
										onClick={() => selectCoupon()}
									/>
								) : (
									<Button
										variant='contained'
										color='info'
										title='Search'
										onClick={() => getCouponInfo()}
									/>
								)}
							</div>
						</div>
					</h1>
				</>
			}
			modalId='addCouponModal'
			open={showAddCouponModal}
			setOpen={setShowAddCouponModal}
		/>
	);
}

export default AddCoupon;

export interface IAddCouponProps {
	showAddCouponModal: boolean;
	setShowAddCouponModal: (value: boolean) => void;
	setTotalAmountDue: (value: number) => void;
}
