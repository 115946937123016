import MUIGridGloabl from 'components/MuiGrid/MUIGlobalGrid';
import { IOrderData } from 'interfaces';
import React from 'react';

function Refunds({ orderData }: IRefunds) {
	const dataSource = orderData.refund_details?.map((item: any) => ({
			...item,
			id: item.transactionid,
		}));
	const columns = [
		{
			headerName: 'Transaction Type',
			field: 'transactionType',
			flex: 1,
			renderCell: (data: any) => {
				let type = data.row.transactionType;

				if (type === 'virtualCard') {
					type = 'Virtual Card';
				} else if (type === 'cash') {
					type = 'Cash';
				} else if (type === 'physicalCard') {
					type = 'Physical Card';
				} else {
					type = 'Coupons';
				}
				return <>{type}</>;
			},
		},
		{
			headerName: 'Transaction ID',
			field: 'transactionid',
			flex: 1,
		},
		{
			headerName: 'Amount',
			field: 'authorized_amount',
			flex: 1,
		},
	];
	return (
		<div className='mt-5'>
			<MUIGridGloabl columns={columns} dataSource={dataSource ?? []} rowCount={dataSource?.length} />
		</div>
	);
}

export default Refunds;

export interface IRefunds {
	orderData: IOrderData;
}
