import React from 'react';
import { IOrderData } from 'interfaces';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
	AddProductToLane,
	SetCustomer,
	SetOrderId,
	SetPosView,
} from 'reducers/POS/PosActions';
import Button from 'storybook-mui/build/components/Button';
import { useHistory } from 'react-router-dom';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import Modal from 'storybook-mui/build/components/Modal';
import Divider from 'components/Divider';

function Basic({ orderData, workStationData }: IBasic) {
	const [showDiscountsApplied = false, setShowDiscountsApplied] =
		React.useState<boolean>(false);
	const { currency } = useSelector((state: any) => state.posReducer);
	const dispatch: any = useDispatch();
	const history = useHistory();
	const continueOrder = () => {
		orderData.items.forEach((item) => {
			dispatch(
				AddProductToLane({
					name: item.ProductLabel,
					product_price: item.UnitPrice,
					quantity: item.Quantity,
					product_id: item.ProductCode,
				})
			);
		});
		dispatch(SetOrderId(orderData.id));
		if (orderData.customer_info.id !== 'GUEST') {
			dispatch(SetCustomer(orderData.customer_info));
		}
		history.push('/');
		dispatch(SetPosView('GRATUITY'));
	};

	return (
		<div className='min-h-screen'>
			<div className='grid mt-5 gap-10 pl-3 xs:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 grid-cols-1 '>
				<div className='flex flex-col'>
					<div className='flex justify-between'>
						<h1 className='font-semibold'>Order Id</h1>
						<h1>{orderData.id}</h1>
					</div>
					<div className='flex justify-between'>
						<h1 className='font-semibold'>Order Date</h1>
						<h1>{moment(orderData.created_at).format('DD/MM/YYYY')}</h1>
					</div>

					{orderData?.customer_info.id !== 'GUEST' ? (
						<div>
							<div className='flex justify-between'>
								<h1 className='font-semibold'>Customer</h1>
								<h1>
									{orderData?.customer_info?.First_Name}
									{orderData?.customer_info?.Last_Name}
								</h1>
							</div>
							<div className='flex justify-between'>
								<h1 className='font-semibold'>Phone Number</h1>
								<h1>{orderData?.customer_info?.ContactInfo[0].Phone_Number}</h1>
							</div>
							<div className='flex justify-between'>
								<h1 className='font-semibold'>Email</h1>
								<h1>{orderData?.customer_info?.ContactInfo[0].Email_Id}</h1>
							</div>
							<div className='flex justify-between'>
								<h1 className='font-semibold'>Loyalty Points Rewarded</h1>
								<h1>{orderData?.CustomerLoyalty?.LoyaltyPointsEarnedByCustomer}</h1>
							</div>
						</div>
					) : (
						<div className='flex justify-between'>
							<h1 className='Customer'>
								<b>Customer</b>
							</h1>
							<h1>Guest</h1>
						</div>
					)}

					<div className='flex justify-between'>
						<h1 className='font-semibold'>Work Station</h1>
						<h1>{workStationData?.Work_Station_Name}</h1>
					</div>

					<div className='flex justify-between'>
						<h1 className='font-semibold'>Payment Status</h1>
						<h1>{orderData?.payment_status}</h1>
					</div>
					<div className='flex justify-between'>
						<h1 className='font-semibold'>Refund Status</h1>
						<h1>{orderData?.refund_status || 'N/A'}</h1>
					</div>

					<div className='flex justify-between'>
						<h1 className='font-semibold'>Order Status</h1>
						<h1>{orderData?.order_status}</h1>
					</div>
					{orderData.order_status !== 'Completed' && (
						<div className='w-max mt-5'>
							<Button
								title='Contine order'
								onClick={() => continueOrder()}
								variant='contained'
								color='info'
							/>
						</div>
					)}
				</div>

				<div className='flex'>
					<div className='mr-10 lg:flex xl:flex sm:hidden'>
						<Divider type='vertical' variant='dashed' />
					</div>

					<div className='flex flex-col gap-2 items-start justify-between'>
						<div className='w-64'>
							<div className='flex justify-between gap-20'>
								<h1>
									<b>SubTotal</b>
								</h1>
								<h1>
									{currency} {orderData?.amount?.Subtotal}
								</h1>
							</div>
							{orderData.amount.AffiliateCustomerDiscount > 0 && (
								<div className='flex justify-between'>
									<div className='flex gap-2 cursor-pointer'>
										<h1>
											<b>Discount</b>
										</h1>
										<Icon
											icon={IconTypes.Eye}
											onClick={() => setShowDiscountsApplied(true)}
										/>
									</div>
									<h1>
										{currency} {orderData?.amount?.AffiliateCustomerDiscount}
									</h1>
								</div>
							)}
							{orderData.amount.CouponDiscount > 0 && (
								<div className='flex justify-between'>
									<div className='flex gap-2 cursor-pointer'>
										<h1>
											<b>Discount</b>
										</h1>
										<Icon
											icon={IconTypes.Eye}
											onClick={() => setShowDiscountsApplied(true)}
										/>
									</div>
									<h1>
										{currency} {orderData?.amount?.CouponDiscount}
									</h1>
								</div>
							)}
							<div className='flex justify-between'>
								<h1>
									<b>Gratuity</b>
								</h1>
								<h1>
									{currency} {orderData?.amount?.Tip}
								</h1>
							</div>
							<div className='flex justify-between'>
								<h1>
									<b>Tax</b> :
								</h1>
								<h1>{currency} 0</h1>
							</div>
						</div>

						<hr />
						<h1 className='text-4xl font-semibold text-white bg-leapbrown-500 p-5 uppercase'>
							Total : {currency} {Number(orderData?.amount?.Total).toFixed(2)}
						</h1>
					</div>
				</div>
			</div>

			{showDiscountsApplied && (
				<Modal
					title='Discounts Applied'
					modalId='discountsApplied'
					setOpen={() => setShowDiscountsApplied(false)}
					open={showDiscountsApplied}
					size='sm'
					closeIcon
					content={
						<>
							{orderData.amount.AffiliateCustomerDiscount > 0 && (
								<div className='shadow border p-3'>
									<h1 className='text-info-500 font-semibold my-2'>
										Discount Amount: {currency} {orderData.amount.AffiliateCustomerDiscount}
									</h1>
									<hr />
									<h1 className='mt-2'>
										Affiliate Name:{' '}
										{orderData.affiliate.AffiliateData?.First_Name?.toUpperCase()}{' '}
										{orderData.affiliate.AffiliateData?.Last_Name?.toUpperCase()}
									</h1>

									<h1>
										Customer Discount Percentage:{' '}
										{orderData.affiliate.AffiliateConfigData.discountPercentageForCustomer}
										{' %'}
									</h1>
									<h1>
										Discount Applied On :{' '}
										{orderData.affiliate.AffiliateConfigData.discountApplicableOn.toUpperCase()}
									</h1>
								</div>
							)}

							{orderData.amount.CouponDiscount > 0 && (
								<div className='shadow border p-3'>
									<h1 className='text-info-500 font-semibold my-2'>
										Discount Amount: {currency} {orderData.amount.CouponDiscount}
									</h1>
									<hr />
									<h1 className='mt-2'>
										Coupon Code: {orderData.coupon.couponCode.toUpperCase()}
									</h1>

									<h1>Discount Type: {orderData.coupon.discountType.toUpperCase()}</h1>

									<h1>
										Customer Discount Value: {orderData.coupon.discountValue}
										{' %'}
									</h1>
									<h1>
										Discount Applied On : {orderData.coupon.appliedOn.toUpperCase()}
									</h1>
								</div>
							)}
						</>
					}
				/>
			)}
		</div>
	);
}

export default Basic;

export interface IBasic {
	orderData: IOrderData;
	workStationData: any;
}
