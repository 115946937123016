import { ICoupon, IAffiliateConfig } from 'interfaces';
import {
	// user , customer , affiliate
	SET_USER,
	SET_CUSTOMER,
	SET_PARTNER,

	// device and catalog
	SET_DEVICE_STATUS,
	SET_ORDER_ID,
	SET_POS_VIEW,
	SET_CATALOG,
	SET_WORK_STATION,
	SET_LOCATION,
	SET_SIDE_BAR_STATUS,

	// line items
	ADD_PRODUCT_TO_LANE,
	REMOVE_PRODUCT_FROM_LANE,
	SET_PRODUCT_QUANTITY,
	REMOVE_ALL_PRODUCTS_FROM_LANE,
	SET_TAX_CONFIGS,

	// orders and payments
	SET_PAYMENT_MODES_WITH_AMOUNT,
	SET_COUPON,
	SET_PARTNER_DISCOUNT_AMOUNT,
	SET_SUB_TOTAL,
	SET_TAX_AMOUNT,
	SET_TOTAL_AMOUNT_WITH_TAX_WITHOUT_GRATUITY,
	SET_GRATUITY_PERCENTAGE,
	SET_GRATUITY_AMOUNT,
	SET_COUPON_DISCOUNT_AMOUNT,
	SET_TOTAL_AMOUNT_WITH_TAX_AND_GRATUITY,
	SET_FINAL_AMOUNT_AFTER_ALL_DISCOUNTS,
	SET_TOTAL_AMOUNT_PAID,
	CLEAR_POS_DATA,
} from './PosActionTypes';

export interface IPos {
	// user , customer , affiliate
	user: any;
	customer: any;
	affiliate: {
		AffiliateData: any;
		AffiliateConfigData: IAffiliateConfig;
	};

	// device and catalog
	view: 'HOME' | 'GRATUITY' | 'PAYMENT' | 'PAYMENT_SUCCESSFULL';
	laneItems: [];
	deviceStatus: 'CONNECTED' | 'DISCONNECTED';
	catalog: any;
	location: any;
	workStation: any;
	sidebarStatus: string;
	currency: string;
	taxConfigs: [];

	// lamounts and orders
	orderId: string;
	subTotal: number;
	totalAmountWithTaxWithoutGratuity: number;
	totalAmountWithTaxAndGratuity: number;
	finalAmountAfterAllDiscounts: number;
	gratuityPercentage: number;
	gratuityAmount: number;
	taxAmount: number;
	totalAmountPaid: number;
	customerDiscountAmountFromAffiliateCode: number;
	couponDiscountAmount: number;
	coupon: ICoupon;
}

const initialState: IPos = {
	// user , customer , affiliate
	user: null,
	customer: {
		id: 'GUEST',
	},
	affiliate: null,
	coupon: null,

	// device and catalog
	deviceStatus: 'DISCONNECTED',
	view: 'HOME',
	laneItems: [],
	sidebarStatus: 'opened',
	currency: '$',
	workStation: null,
	location: null,
	catalog: null,
	taxConfigs: [],

	// amounts and orders
	orderId: '',
	subTotal: 0,
	totalAmountWithTaxWithoutGratuity: 0,
	gratuityPercentage: 0,

	gratuityAmount: 0,
	taxAmount: 0,
	couponDiscountAmount: 0,
	customerDiscountAmountFromAffiliateCode: 0,
	totalAmountWithTaxAndGratuity: 0,
	finalAmountAfterAllDiscounts: 0,
	totalAmountPaid: 0,
};

const posReducer = (state: any = initialState, action: any): IPos => {

	switch (action.type) {
		
		// user , customer , affiliate
		case SET_USER:
			return {
				...state,
				user: action.payload,
			};
		case SET_CUSTOMER:
			return {
				...state,
				customer: action.payload,
			};
		case SET_PARTNER:
			return {
				...state,
				affiliate: action.payload,
			};

		// device and catalog
		case SET_DEVICE_STATUS:
			return {
				...state,
				deviceStatus: action.payload,
			};
		case SET_ORDER_ID:
			return {
				...state,
				orderId: action.payload,
			};
		case SET_POS_VIEW:
			return {
				...state,
				view: action.payload,
			};
		case SET_CATALOG:
			return {
				...state,
				catalog: action.payload,
			};
		case SET_WORK_STATION:
			return {
				...state,
				workStation: action.payload,
			};
		case SET_LOCATION:
			return {
				...state,
				location: action.payload,
			};
		case SET_SIDE_BAR_STATUS:
			return {
				...state,
				sidebarStatus: action.payload,
			};

		// line items
		case ADD_PRODUCT_TO_LANE:
			return {
				...state,
				laneItems: [...state.laneItems, action.payload],
			};
		case REMOVE_PRODUCT_FROM_LANE:
			return {
				...state,
				laneItems: state.laneItems.filter(
					(item: any) => item.product_id !== action.payload.product_id
				),
			};
		case SET_PRODUCT_QUANTITY:
			return {
				...state,
				laneItems: state.laneItems.map((item: any) => {
					if (item.product_id === action.payload.product_id) {
						return {
							...item,
							quantity: action.payload.quantity,
						};
					}
					return item;
				}),
			};
		case REMOVE_ALL_PRODUCTS_FROM_LANE:
			return {
				...state,
				laneItems: [],
			};
		case SET_TAX_CONFIGS:
			return {
				...state,
				taxConfigs: action.payload,
			};
		
		
		// orders and payments
		case SET_PAYMENT_MODES_WITH_AMOUNT:
			return {
				...state,
				paymentModesWithAmount: action.payload,
			};
		case SET_COUPON:
			return {
				...state,
				coupon: action.payload,
			};
		case SET_SUB_TOTAL:
			return {
				...state,
				subTotal: action.payload,
			};
		case SET_TAX_AMOUNT:
			return {
				...state,
				taxAmount: action.payload,
			};
		case SET_TOTAL_AMOUNT_WITH_TAX_WITHOUT_GRATUITY:
			return {
				...state,
				totalAmountWithTaxWithoutGratuity: action.payload,
			};
		case SET_GRATUITY_PERCENTAGE:
			return {
				...state,
				gratuityPercentage: action.payload,
				gratuityAmount: Number(
					((action.payload * state.totalAmountWithTaxWithoutGratuity) / 100).toFixed(
						2
					)
				),
			};

		case SET_GRATUITY_AMOUNT:
			return {
				...state,
				gratuityAmount: action.payload,
				gratuityPercentage: Number(
					((action.payload * 100) / state.totalAmountWithTaxWithoutGratuity).toFixed(
						2
					)
				),
			};
		case SET_PARTNER_DISCOUNT_AMOUNT:
			return {
				...state,
				customerDiscountAmountFromAffiliateCode: Number(
					Number(action.payload).toFixed(2)
				),
			};
		case SET_COUPON_DISCOUNT_AMOUNT:
			return {
				...state,
				couponDiscountAmount: action.payload,
			};

		case SET_TOTAL_AMOUNT_WITH_TAX_AND_GRATUITY:
			return {
				...state,
				totalAmountWithTaxAndGratuity: action.payload,
			};
		case SET_FINAL_AMOUNT_AFTER_ALL_DISCOUNTS:
			return {
				...state,
				finalAmountAfterAllDiscounts: action.payload,
			};

		case SET_TOTAL_AMOUNT_PAID:
			return {
				...state,
				totalAmountPaid: action.payload,
			};
		case CLEAR_POS_DATA:
			return {
				...initialState,
				user: state.user,
				catalog: state.catalog,
				workStation: state.workStation,
				location: state.location,
				taxConfigs: state.taxConfigs,
			};
		default:
			return state;
	}
};

export default posReducer;
