import React, { useEffect } from 'react';
import MUIGridGloabl from 'components/MuiGrid/MUIGlobalGrid';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { GridColDef } from '@mui/x-data-grid-pro';
import { GetPosContacts } from 'firebaseApis/contacts';
import Button from 'storybook-mui/build/components/Button';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import CustomerOrders from './CustomerOrders';
import RedeemPointsModal from './RedeemPointsModal';

function Customers() {
	const [contactsData, setcontactsData] = React.useState<[]>([]);
	const [showCustomerOrders, setShowCustomerOrdersModal] = React.useState(false);
	const [selectedCustomer, setSelectedCustomer] = React.useState<any>(null);
	const [showRedeemPointsModal, setShowRedeemPointsModal] =
		React.useState(false);
	const dispatch: any = useDispatch();
	const columns: GridColDef[] = [
		{
			headerName: 'Name / Id',
			field: 'Name',
			minWidth: 250,
			renderCell: (params) => (
				<div className='flex flex-col gap-1'>
					<h1>
						<b>
							{params.row.First_Name} {params.row.Last_Name}
						</b>
					</h1>
					<h1>
						<b>Id</b> : {params.row.id}
					</h1>
				</div>
			),
		},
		{
			headerName: 'Total Loyal Points',
			field: 'Total_Loyal_Points',
			minWidth: 200,
			renderCell: (params) => <>{params?.row?.PosLoyaltyPoints?.Total || 0}</>,
		},
		{
			headerName: 'Available Points',
			field: 'Available_Loyal_Points',
			minWidth: 170,
			renderCell: (params) => <>{params?.row?.PosLoyaltyPoints?.Available || 0}</>,
		},
		{
			headerName: 'Redeemed Points',
			field: 'Redeemed_Loyal_Points',
			minWidth: 170,
			renderCell: (params) => <>{params?.row?.PosLoyaltyPoints?.Used || 0}</>,
		},
		{
			headerName: 'Contact Information',
			field: 'Email',
			minWidth: 300,
			renderCell: (params) => (
				<div className='flex flex-col gap-1 p-2'>
					<h1>
						<b>Email</b> : {params.row.Email_Id}
					</h1>
					<h1>
						<b>Phone Number </b>: {params.row.Phone_Number}
					</h1>
				</div>
			),
		},
		{
			headerName: 'Actions',
			field: 'actions',
			minWidth: 150,
			renderCell: (params) => (
				<div className='flex gap-5'>
					{params.row?.PosLoyaltyPoints?.Total > 0 && (
						<Button
							variant='outlined'
							color='info'
							onClick={() => {
								setSelectedCustomer(params.row);
								setShowCustomerOrdersModal(true);
							}}
							title='Orders'
							size='small'
						/>
					)}

					{params?.row?.PosLoyaltyPoints?.Available > 0 && (
						<Button
							variant='outlined'
							onClick={() => {
								setSelectedCustomer(params.row);
								setShowRedeemPointsModal(true);
							}}
							color='success'
							title='Redeem'
							size='small'
						/>
					)}
				</div>
			),
		},
	];

	const getData = async () => {
		try {
			dispatch(showLoading());
			const response = await GetPosContacts();
			setcontactsData(response);
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
	};

	useEffect(() => {
		getData();
	}, []);
	return (
		<div>
			<div className='flex justify-between items-start my-5'>
				<h1 className='text-3xl'>Customers</h1>
				<Button
					title='Add Customer'
					onClick={() => {
						window.open(
							`${localStorage.getItem('redirectionLink')}/Dynamic/Contacts/New`,
							'_blank'
						);
					}}
					variant='contained'
					color='primary'
				/>
			</div>
			<MUIGridGloabl
				columns={columns}
				dataSource={contactsData ?? []}
				rowCount={contactsData.length}
				dynamicRowHeight
			/>

			{showCustomerOrders && (
				<CustomerOrders
					selectedCustomer={selectedCustomer}
					showCustomersOrdersModal={showCustomerOrders}
					setShowCustomersOrdersModal={setShowCustomerOrdersModal}
				/>
			)}

			{showRedeemPointsModal && (
				<RedeemPointsModal
					customer={selectedCustomer}
					showRedeemPointsModal={showRedeemPointsModal}
					setShowRedeemPointsModal={setShowRedeemPointsModal}
					getData={getData}
				/>
			)}
		</div>
	);
}

export default Customers;
