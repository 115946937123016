// basic
export const SET_USER = 'SET_USER';
export const SET_CUSTOMER = "SET_CUSTOMER";
export const SET_PARTNER = "SET_PARTNER";
export const SET_COUPON = "SET_COUPON";


// device and catalog
export const SET_DEVICE_STATUS = 'SET_DEVICE_STATUS';
export const SET_ORDER_ID = "SET_ORDER_ID";
export const SET_POS_VIEW = "SET_POS_VIEW";
export const SET_CATALOG = "SET_CATALOG";
export const SET_WORK_STATION = "SET_WORK_STATION";
export const SET_LOCATION = "SET_LOCATION";
export const SET_SIDE_BAR_STATUS = "SET_SIDE_BAR_STATUS";

// line items
export const ADD_PRODUCT_TO_LANE = "ADD_PRODUCT_TO_LANE";
export const REMOVE_PRODUCT_FROM_LANE = "REMOVE_PRODUCT_FROM_LANE";
export const SET_PRODUCT_QUANTITY = "SET_PRODUCT_QUANTITY";
export const REMOVE_ALL_PRODUCTS_FROM_LANE = "REMOVE_ALL_PRODUCTS_FROM_LANE";
export const SET_TAX_CONFIGS = "SET_TAX_CONFIGS";

// orders and payments



export const CLEAR_POS_DATA = "CLEAR_POS_DATA";
export const SET_SUB_TOTAL = "SET_SUB_TOTAL";
export const SET_TAX_AMOUNT = "SET_TAX_AMOUNT";
export const SET_TOTAL_AMOUNT_WITH_TAX_WITHOUT_GRATUITY = "SET_TOTAL_AMOUNT_WITH_TAX";
export const SET_GRATUITY_PERCENTAGE = "SET_GRATUITY_PERCENTAGE";
export const SET_GRATUITY_AMOUNT = "SET_GRATUITY_AMOUNT";
export const SET_TOTAL_AMOUNT_WITH_TAX_AND_GRATUITY = "SET_TOTAL_AMOUNT_WITH_TAX_AND_GRATUITY";

export const SET_PARTNER_DISCOUNT_AMOUNT = "SET_PARTNER_DISCOUNT_AMOUNT";
export const SET_COUPON_DISCOUNT_AMOUNT = "SET_COUPON_DISCOUNT_AMOUNT";

export const SET_TOTAL_AMOUNT_PAID = "SET_TOTAL_AMOUNT_PAID";
export const SET_PAYMENT_MODES_WITH_AMOUNT = "SET_PAYMENT_MODES_WITH_AMOUNT";
export const SET_FINAL_AMOUNT_AFTER_ALL_DISCOUNTS = "SET_TOTAL_AMOUNT_AFTER_ALL_DISCOUNTS";

