import MUIGridGloabl from 'components/MuiGrid/MUIGlobalGrid';
import React, { useState } from 'react';
import Filters from '../Analytics/Filters';

function TopSelling() {
	const [data = [], setData] = useState<any>(null);
	const columns = [
		{
			title: 'Product',
			field: 'product',
			flex: 1,
		},
		{
			title: 'Total Sold',
			field: 'total',
			flex: 1,
		},
		{
			title: 'Work Station',
			field: 'work_station',
			flex: 1,
		},
		{
			title: 'Location',
			field: 'location',
			flex: 1,
		},
	];
	return (
		<div className='bg-white p-5 rounded mt-5'>
			<Filters setData={setData} />

			{data && (
				<MUIGridGloabl
					columns={columns}
					dataSource={data?.products ?? []}
					rowCount={5}
				/>
			)}
		</div>
	);
}

export default TopSelling;
