import React, { useEffect } from 'react';
import { GetOrganizationInfoById } from 'firebaseApis/locations';
import UserInfo from '../../views/Home/Components/Items/UserInfo';


function Header() {
	const [orgData, setOrgData] = React.useState(null);
	const getOrgData = async () => {
		GetOrganizationInfoById()
			.then((response) => {
				setOrgData(response);
			})
			.catch(() => {});
	};
	useEffect(() => {
		getOrgData();
	}, []);

	return (
		<div className='relative bg-white p-3 border border-gray-300 shadow rounded-md flex justify-between w-full items-center'>
			<div className='flex justify-center flex-col items-start'>
				{orgData && (
					<div>
						<h1 className='text-2xl font-bold text-gray-500'>
							{orgData.Org_Name?.toUpperCase()}
						</h1>
					</div>
				)}
			</div>
			<UserInfo />
		</div>
	);
}

export default Header;
