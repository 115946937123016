import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function ToggleButtonGroups({
	buttons,
	color = 'primary',
	exclusive = true,
	onChange,
	value,
	sx,
	size = 'medium',
	orientation = 'horizontal',
}: IToggleGroup) {
	return (
		<ToggleButtonGroup
			size={size}
			sx={sx}
			color={color}
			value={value}
			exclusive={exclusive}
			onChange={onChange}
			orientation={orientation}
		>
			{buttons.map((button) => (
				<ToggleButton value={button.value}>{button.content}</ToggleButton>
			))}
		</ToggleButtonGroup>
	);
}

export interface IToggleGroup {
	sx?: any;
	size?: 'small' | 'medium' | 'large';
	color?:
		| 'primary'
		| 'secondary'
		| 'success'
		| 'error'
		| 'info'
		| 'warning'
		| 'standard';
	exclusive?: boolean;
	onChange: any;
	value: any;
	buttons: any;
	orientation?: 'horizontal' | 'vertical';
}
