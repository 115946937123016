import {
	getClientDB,
	getOrganisationIdFirebase,
	getUserFromRedux,
} from 'api/AxiosManager';
import {
	collection,
	doc,
	getDoc,
	getDocs,
	query,
	where,
} from 'firebase/firestore';
import { addDoc, orderBy, updateDoc } from 'firebase/firestore/lite';
import moment from 'moment';
import store from 'reducers';
import { IPos } from 'reducers/POS';

export const CheckAffiliateCodeValidity = async (affiliateCode) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Affiliate/Affiliate`
			),
			where('Affiliate_Code', '==', affiliateCode)
		);
		const result = await getDocs(qry);
		if (result.size > 0) {
			const affiliateData: any = {
				...result.docs[0].data(),
				id : result.docs[0].id
			};

			if (!affiliateData?.Is_Active) {
				return { success: false, message: 'Affiliate is not active' };
			}

			const affiliateConfigId = affiliateData.Affiliate_Type;
			const affiliateConfig = await getDoc(
				doc(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/POS/AffiliateConfigs`,
					affiliateConfigId
				)
			);

			return {
				data: {
					Affiliate: affiliateData,
					AffiliateConfig: affiliateConfig.data(),
				},
				success: true,
			};
		}

		return {
			success: false,
			message: 'Affiliate not found',
		};
	} catch (error) {
		return {
			success: false,
			message: error.message,
		};
	}
};

export const RewardAffiliate = async () => {
	try {
		const { affiliate, subTotal }: IPos = store.getState().posReducer;

		const loyaltyPointsEarned =
			affiliate.AffiliateConfigData.referalPointsForAffiliate * subTotal;

		// checking if affiliate has loyalty points data
		let tempLoyaltyPoints = affiliate?.AffiliateData?.LoyaltyPoints;
		if (!tempLoyaltyPoints) {
			tempLoyaltyPoints = {
				Total: loyaltyPointsEarned,
				Used: 0,
				Available: loyaltyPointsEarned,
			};
		} else {
			tempLoyaltyPoints.Total += loyaltyPointsEarned;
			tempLoyaltyPoints.Available += loyaltyPointsEarned;
		}
		const affiliateRef = doc(
			getClientDB(),
			`/${getOrganisationIdFirebase()}/Affiliate/Affiliate`,
			affiliate.AffiliateData.id
		);

		await updateDoc(affiliateRef, {
			LoyaltyPoints: tempLoyaltyPoints,
		});

		return {
			success: false,
			message: 'Affiliate not found',
		};
	} catch (error) {
		return {
			success: false,
			message: error.message,
		};
	}
};

export const GetPosAffiliates = async () => {
	const qry = query(
		collection(
			getClientDB(),
			`${getOrganisationIdFirebase()}/Affiliate/Affiliate`
		)
	);
	const docResult: any = await getDocs(qry);
	const resultArray = docResult.docs
		.filter((dc) => dc?.data().Display !== false)
		.map((document: any) => ({
			id: document.id,
			...document.data(),
			...document.data().GroupedFieldsValues,
		}));

	return resultArray;
};

export const GetAffiliateOrders = async (affiliateId) => {
	try {
		const qry = query(
			collection(getClientDB(), `${getOrganisationIdFirebase()}/POS/orders`),
			where('affiliate.AffiliateData.id', '==', affiliateId),
			orderBy('created_at', 'desc')
		);
		const docResult: any = await getDocs(qry);
		const resultArray = docResult.docs
			.filter((dc) => dc?.data().Display !== false)
			.map((document: any) => ({
				id: document.id,
				...document.data(),
			}));

		return resultArray;
	} catch (error) {
		return [];
	}
};

export const RedeemAffiliateReferalPoints = async (
	affiliate,
	pointsToRedeem,
	notes
) => {
	try {
		const affiliateRef = doc(
			getClientDB(),
			`/${getOrganisationIdFirebase()}/Affiliate/Affiliate`,
			affiliate.id
		);
		const tempLoyaltyPoints = affiliate?.LoyaltyPoints;
		tempLoyaltyPoints.Available -= Number(pointsToRedeem);
		tempLoyaltyPoints.Used += Number(pointsToRedeem);
		// adding affiliate loyalty points history
		await addDoc(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/POS/Affiliate_Points_Redemptions`
			),
			{
				affiliateId: affiliate.id,
				pointsRedeemed: pointsToRedeem,
				date: moment().toString(),
				notes,
				staffId: getUserFromRedux().userId,
			}
		);

		// updating affiliate loyalty points
		await updateDoc(affiliateRef, {
			...affiliate,
			LoyaltyPoints: tempLoyaltyPoints,
		});

		return {
			success: true,
			message: 'Points redeemed successfully',
		};
	} catch (error) {
		return {
			success: false,
		};
	}
};

export const GetAffiliatePointsRedemtions = async (affiliateId) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`${getOrganisationIdFirebase()}/POS/Affiliate_Points_Redemptions`
			),
			where('affiliateId', '==', affiliateId),
			orderBy('date', 'desc')
		);
		const docResult: any = await getDocs(qry);
		const resultArray = docResult.docs
			.filter((dc) => dc?.data().Display !== false)
			.map((document: any) => ({
				id: document.id,
				...document.data(),
			}));

		return resultArray;
	} catch (error) {
		return [];
	}
};
