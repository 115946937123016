import MUIGridGloabl from 'components/MuiGrid/MUIGlobalGrid';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { GetAllOrdersOfCoupon } from 'firebaseApis/coupons';
import { ICoupon, IOrderData } from 'interfaces';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import Modal from 'storybook-mui/build/components/Modal';
import { useHistory } from 'react-router-dom';
import { IPos } from 'reducers/POS';

function CouponsRedemptions({
	showCouponRedemptionsModal,
	setShowCouponRedemptionsModal,
	selectedCoupon,
}: ICouponsRedemptions) {
	const dispatch: any = useDispatch();
	const [couponsRedemptionsData, setCouponsRedemptionsData] =
		React.useState<any>([]);
	const history = useHistory();
	const {currency} : IPos= useSelector((state: any) => state.posReducer);
	const columns = [
		{
			headerName: 'Order Id',
			field: 'orderid',
			flex: 1,
			renderCell: (params: any) => (
				<h1
					className='underline cursor-pointer'
					onClick={() => history.push(`/reports/order/${params.row.orderid}`)}
				>
					{params.value}
				</h1>
			),
		},
		{
			headerName: 'Order Date',
			field: 'orderDate',
			flex: 1,
			renderCell: (params) => (
				<h1>{moment(params?.row?.created_at).format('MM-DD-YYYY hh:mm A')}</h1>
			),
		},
		{
			headerName: 'Customer',
			field: 'customer',
			flex: 1,
			renderCell: (data: any) => {
				let customerName = 'GUEST';
				if (data?.row?.customer_info?.id !== 'GUEST') {
					customerName = `${data.row.customer_info.First_Name} ${data.row.customer_info.Last_Name}`;
				}
				return <div>{customerName}</div>;
			},
		},
		{
			field: 'Items Total',
			headerName: 'Items Total',
			minWidth: 150,
			renderCell: (params) => {
				const orderData: IOrderData = params?.row;
				const itemsTotal = orderData.items.reduce(
					(acc: number, item: any) => acc + item.UnitPrice * item.Quantity,
					0
				);
				return (
					<h1>
						{currency}
						{itemsTotal}
					</h1>
				);
			},
		},
		{
			field: 'Gratuity',
			headerName: 'Gratuity',
			minWidth: 100,
			renderCell: (params) => (
				<>
					{' '}
					{currency} {params.row.amount.Tip}
				</>
			),
		},
		{
			field: 'Tax',
			headerName: 'Tax',
			minWidth: 100,
			renderCell: (params) => (
				<>
					{' '}
					{currency} {params.row.amount.Tax}
				</>
			),
		},
		{
			field: 'Discounts',
			headerName: 'Discounts',
			minWidth: 250,
			renderCell: (params) => (
				<div className='flex flex-col p-2'>
					<h1>
						Affiliate Code Discount: {currency}{' '}
						{params.row.amount.AffiliateCustomerDiscount}
					</h1>
					<h1>
						Coupon Discount: {currency} {params.row.amount.CouponDiscount}
					</h1>
				</div>
			),
		},
		{
			field: 'Total',
			headerName: 'Total',
			minWidth: 100,
			renderCell: (params) => (
				<>
					{currency} {params.row.amount.Total}
				</>
			),
		},
	];

	const getData = async () => {
		try {
			dispatch(showLoading())
			const response = await GetAllOrdersOfCoupon(selectedCoupon.id);
			setCouponsRedemptionsData(response.data);
			dispatch(hideLoading())
		} catch (error) {
			setCouponsRedemptionsData([]);
			Toast({
				type: ToastTypes.ERROR,
				title: 'Error while fetching coupon redemptions',
			});
			dispatch(hideLoading());
		}
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<Modal
			title='Coupon Redemptions'
			content={
				<>
					<MUIGridGloabl
						columns={columns}
						dataSource={couponsRedemptionsData || []}
						rowCount={couponsRedemptionsData?.length || 0}
					/>
				</>
			}
			modalId='couponRedemptionsModal'
			open={showCouponRedemptionsModal}
			setOpen={() => setShowCouponRedemptionsModal(false)}
			closeIcon
		/>
	);
}

export default CouponsRedemptions;

export interface ICouponsRedemptions {
	showCouponRedemptionsModal: boolean;
	setShowCouponRedemptionsModal: (showCouponRedemptionsModal: boolean) => void;
	selectedCoupon: ICoupon;
}
