import {
	// user , customer , affiliate
	SET_USER,
	SET_CUSTOMER,
	SET_PARTNER,
	SET_COUPON,

	// device and catalog
	SET_DEVICE_STATUS,
	SET_ORDER_ID,
	SET_POS_VIEW,
	SET_CATALOG,
	SET_WORK_STATION,
	SET_LOCATION,
	SET_SIDE_BAR_STATUS,

	// line items
	ADD_PRODUCT_TO_LANE,
	REMOVE_PRODUCT_FROM_LANE,
	SET_PRODUCT_QUANTITY,
	REMOVE_ALL_PRODUCTS_FROM_LANE,

	// orders and payments
	SET_PAYMENT_MODES_WITH_AMOUNT,
	SET_PARTNER_DISCOUNT_AMOUNT,
	SET_COUPON_DISCOUNT_AMOUNT,
	SET_SUB_TOTAL,
	SET_TAX_AMOUNT,
	SET_TOTAL_AMOUNT_WITH_TAX_WITHOUT_GRATUITY,
	SET_GRATUITY_PERCENTAGE,
	SET_GRATUITY_AMOUNT,
	SET_TOTAL_AMOUNT_WITH_TAX_AND_GRATUITY,
	SET_FINAL_AMOUNT_AFTER_ALL_DISCOUNTS,
	SET_TOTAL_AMOUNT_PAID,
	CLEAR_POS_DATA,
	SET_TAX_CONFIGS,
} from './PosActionTypes';

// basic
export const SetUser = (user: any) => ({
	type: SET_USER,
	payload: user,
});

export const SetCustomer = (customer: any) => ({
	type: SET_CUSTOMER,
	payload: customer,
});

export const SetAffiliate = (affiliate: any) => ({
	type: SET_PARTNER,
	payload: affiliate,
});

// device and catalog and home page

export const SetDeviceStatus = (deviceStatus: any) => ({
	type: SET_DEVICE_STATUS,
	payload: deviceStatus,
});

export const SetOrderId = (orderId: any) => ({
	type: SET_ORDER_ID,
	payload: orderId,
});

export const SetPosView = (posView: any) => ({
	type: SET_POS_VIEW,
	payload: posView,
});

export const SetCatalog = (catalog: any) => ({
	type: SET_CATALOG,
	payload: catalog,
});

export const SetLocation = (location: any) => ({
	type: SET_LOCATION,
	payload: location,
});

export const SetWorkStation = (workStation: any) => ({
	type: SET_WORK_STATION,
	payload: workStation,
});

export const SetSideBarStatus = (sideBarStatus: any) => ({
	type: SET_SIDE_BAR_STATUS,
	payload: sideBarStatus,
});
// line items

export const AddProductToLane = (product: any) => ({
	type: ADD_PRODUCT_TO_LANE,
	payload: product,
});

export const RemoveProductFromLane = (product: any) => ({
	type: REMOVE_PRODUCT_FROM_LANE,
	payload: product,
});

export const SetProductQuantity = (product: any) => ({
	type: SET_PRODUCT_QUANTITY,
	payload: product,
});

export const RemoveAllProductsFromLane = () => ({
	type: REMOVE_ALL_PRODUCTS_FROM_LANE,
});

export const SetTaxConfigs = (taxConfigs: any) => ({
	type: SET_TAX_CONFIGS,
	payload: taxConfigs,
});

// orders and payments

export const SetPaymentModesWithAmount = (paymentModesWithAmount: any) => ({
	type: SET_PAYMENT_MODES_WITH_AMOUNT,
	payload: paymentModesWithAmount,
});

export const SetSubTotal = (subTotal: any) => ({
	type: SET_SUB_TOTAL,
	payload: subTotal,
});

export const SetTaxAmount = (taxAmount: any) => ({
	type: SET_TAX_AMOUNT,
	payload: taxAmount,
});

export const SetTotalAmountWithTaxWithoutGratuity = (
	totalAmountWithTaxWithoutGratuity: any
) => ({
	type: SET_TOTAL_AMOUNT_WITH_TAX_WITHOUT_GRATUITY,
	payload: totalAmountWithTaxWithoutGratuity,
});

export const SetGratuityPercentage = (gratuityPercentage: any) => ({
	type: SET_GRATUITY_PERCENTAGE,
	payload: gratuityPercentage,
});

export const SetGratuityAmount = (gratuityAmount: any) => ({
	type: SET_GRATUITY_AMOUNT,
	payload: gratuityAmount,
});

export const SetcustomerDiscountAmountFromAffiliateCode = (
	customerDiscountAmountFromAffiliateCode: any
) => ({
	type: SET_PARTNER_DISCOUNT_AMOUNT,
	payload: customerDiscountAmountFromAffiliateCode,
});

export const SetCouponDiscountAmount = (couponDiscountAmount: any) => ({
	type: SET_COUPON_DISCOUNT_AMOUNT,
	payload: couponDiscountAmount,
});

export const SetTotalAmountWithTaxAndGratuity = (
	totalAmountWithTaxAndGratuity: any
) => ({
	type: SET_TOTAL_AMOUNT_WITH_TAX_AND_GRATUITY,
	payload: totalAmountWithTaxAndGratuity,
});

export const SetFinalAmountAfterAllDiscounts = (
	finalAmountAfterAllDiscounts: any
) => ({
	type: SET_FINAL_AMOUNT_AFTER_ALL_DISCOUNTS,
	payload: finalAmountAfterAllDiscounts,
});

export const SetTotalAmountPaid = (totalAmountPaid: any) => ({
	type: SET_TOTAL_AMOUNT_PAID,
	payload: totalAmountPaid,
});

export const SetCoupon = (coupon: any) => ({
	type: SET_COUPON,
	payload: coupon,
});

export const ClearPosData = () => ({
	type: CLEAR_POS_DATA,
});
