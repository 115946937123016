import React from 'react';
import SideBar from '../Lane';
import Categories from './Categories';
import Header from '../../../../layouts/components/Header';

function Items() {
	return (
		<div className='flex'>
			<div className='h-full w-full relative'>
				<Header />
				<Categories />
			</div>
			<SideBar />
		</div>
	);
}

export default Items;
