import React, { useEffect } from 'react';
import MUIGridGloabl from 'components/MuiGrid/MUIGlobalGrid';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { GridColDef } from '@mui/x-data-grid-pro';
import Button from 'storybook-mui/build/components/Button';
import { GetAllCoupons } from 'firebaseApis/coupons';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import CouponsRedemptions from './CouponsRedemptions';

function Coupons() {
	const [couponsData, setCouponsData] = React.useState<[]>([]);
	const [showCouponRedemptionsModal, setShowCouponRedemptionsModal] =
		React.useState<boolean>(false);
	const [selectedCoupon, setSelectedCoupon] = React.useState<any>(null);
	const dispatch: any = useDispatch();
	const columns: GridColDef[] = [
		{
			headerName: 'Coupon Name',
			field: 'name',
			flex: 1,
		},
		{
			headerName: 'Coupon Code',
			field: 'couponCode',
			flex: 1,
		},
		{
			headerName: 'Applicable For',
			field: 'couponApplicableFor',
			flex: 1,
			renderCell: (params) =>
				params.row.couponApplicableFor === 'customers'
					? 'Only Customers'
					: 'Customers and Guests',
		},
		{
			headerName: 'Discount Type',
			field: 'discountType',
			flex: 1,
		},
		{
			headerName: 'Discount Value',
			field: 'discountValue',
			flex: 1,
		},
		{
			headerName: 'Actions',
			field: 'actions',
			flex: 1,
			renderCell: (params) => (
				<div className='flex gap-2'>
					<Button
						variant='outlined'
						color='info'
						onClick={() => {
							setSelectedCoupon(params.row);
							setShowCouponRedemptionsModal(true);
						}}
						title='Redemptions'
					/>
				</div>
			),
		},
	];

	const getData = async () => {
		try {
			dispatch(showLoading());
			const response: any = await GetAllCoupons();
			setCouponsData(response.data);
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<div>
			<div className='flex justify-end items-start my-5'>
				<Button
					title='Add Coupon'
					onClick={() => {
						window.open(
							`${localStorage.getItem('redirectionLink')}/POS/Coupons`,
							'_blank'
						);
					}}
					variant='contained'
					color='primary'
				/>
			</div>
			<MUIGridGloabl
				columns={columns}
				dataSource={couponsData ?? []}
				rowCount={couponsData.length}
			/>
			{showCouponRedemptionsModal && (
				<CouponsRedemptions
					showCouponRedemptionsModal={showCouponRedemptionsModal}
					setShowCouponRedemptionsModal={setShowCouponRedemptionsModal}
					selectedCoupon={selectedCoupon}
				/>
			)}
		</div>
	);
}

export default Coupons;
