import React from 'react';



function RepordCard({ title, value , icon , isCurrency}: RepordCardProps) {
	return (
		<div className='bg-white p-5 flex gap-5 shadow-sm border border-gray-300 justify-between rounded'>
			<div className='flex flex-col gap-2'>
				<h1 className='text-leapbrown-500 font-semibold uppercase'>{title}</h1>
				<h1 className='text-3xl font-semibold text-gray-700'>
                     {isCurrency ? ' $ ' : ''} 
                    {value}</h1>
			</div>
			<div>
				<img src={icon} alt='' className='h-12 w-14' />
			</div>
		</div>
	);
}

export default RepordCard;

export interface RepordCardProps {
	title: string;
	value: string;
	icon: any;
    isCurrency?: boolean;
}
