import { IProductInterface } from 'interfaces';
import React from 'react';
import { useDispatch } from 'react-redux';
import { AddProductToLane } from 'reducers/POS/PosActions';
import Button from 'storybook-mui/build/components/Button';
import Modal from 'storybook-mui/build/components/Modal';
import NumberField from 'storybook-mui/build/components/NumberField';
import InputTextField from 'storybook-mui/build/components/TextField';

function AddGiftCardModal({
	open,
	setOpen,
	product,
	priceToDisplay,
	taxPercentages,
	productInCatalog,
}: AddGiftCardModalProps) {
	const [price, setPrice] = React.useState(priceToDisplay);
	const [redeemValue, setRedeemValue] = React.useState(
		productInCatalog?.redeem_value
	);
	const [quantity, setQuantity] = React.useState(1);
	const dispatch = useDispatch();
	const onAddToCart = () => {
		dispatch(
			AddProductToLane({
				...product,
				product_price: price,
				redeem_value: redeemValue,
				tax_configs: taxPercentages,
				is_taxable: productInCatalog?.is_taxable,
				quantity: 1,
			})
		);
		setOpen(false);
	};

	return (
		<Modal
			open={open}
			setOpen={() => setOpen(false)}
			title=''
			content={
				<div className='p-5'>
					<h1 className='text-leapbrown-500 text-xl font-semibold uppercase text-center'>
						Add Gift Card to Cart
					</h1>
					<div className='flex gap-5 mt-5'>
						<div>
							<img
								className='w-96 h-40 object-cover'
								src={product.image[0].file_path}
								alt={product.name}
							/>
						</div>
						<div className='flex flex-col gap-5 w-full'>
							<InputTextField
								labelName='Gift Card Price'
								value={price}
								onChange={(e) => setPrice(e.target.value)}
								type='number'
								name='price'
								fieldId='price'
							/>
							<InputTextField
								labelName='Gift Card Redeem Value'
								value={redeemValue}
								onChange={(e) => setRedeemValue(e.target.value)}
								type='number'
								name='redeemValue'
								fieldId='redeemValue'
							/>
							<NumberField
								labelName='Quantity'
								value={quantity}
								onChange={(e) => setQuantity(e.target.value)}
								name='quantity'
								fieldId='quantity'
							/>

							<div className='flex justify-end gap-5'>
								<Button
									variant='outlined'
									color='info'
									title='Cancel'
									onClick={() => setOpen(false)}
								/>
								<Button
									variant='contained'
									color='info'
									title='Add to Cart'
									onClick={onAddToCart}
								/>
							</div>
						</div>
					</div>
				</div>
			}
			modalId='add-gift-card-modal'
			closeIcon
			size='sm'
			contentSx={{
				p: 0,
			}}
		/>
	);
}

export default AddGiftCardModal;

export interface AddGiftCardModalProps {
	open: boolean;
	setOpen: any;
	product: IProductInterface;
	priceToDisplay: number;
	taxPercentages: any;
	productInCatalog: any;
}
