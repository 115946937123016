import Button from 'storybook-mui/build/components/Button';
import { useParams, useHistory } from 'react-router-dom';
import { GetOrderById } from 'firebaseApis/orders';
import React, { useEffect, useState } from 'react';
import Toast, { ToastTypes } from 'components/ToastNotify';

import { GetWorkStationById } from 'firebaseApis/locations';
import { IOrderData } from 'interfaces';
import Tabs from 'storybook-mui/build/components/Tabs';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { useDispatch } from 'react-redux';
import LineItems from './LineItems';
import Transactions from './Transactions';
import Basic from './Basic';
import Refunds from './Refunds';
import Voids from './Voids';
import TransactionsLogs from './TransactionsLogs';

function OrderDetails() {
	const [workStationData, setWorkStationData] = useState(null);
	const [orderData, setOrderData] = useState<IOrderData>(null);
	const [itemsData, setItemsData] = useState<[]>([]);
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const history = useHistory();
	const params = useParams();
    const dispatch:any = useDispatch();
	const getData = async () => {
		try {
			dispatch(showLoading());
			const response = await GetOrderById(params.id);
			setOrderData(response);
			const itemsDataWithUniqueId = response.items.map((item: any, index) => ({
				...item,
				id: index,
			}));
			setItemsData(itemsDataWithUniqueId);
			dispatch(hideLoading())
		} catch (error) {
			dispatch(hideLoading())
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
	};

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		if (orderData) {
			GetWorkStationById().then((response) => {
				setWorkStationData(response);
			});
		}
	}, [orderData]);

	return (
		orderData && (
			<div className='flex flex-col space-y-5 bg-white my-5'>
				<div className='flex flex-col my-5'>
					<div className='flex justify-between space-x-5 mr-5 py-2'>
						<h1 className='text-xl font-semibold pl-5 text-info-500'>
							Order Information - {orderData.id}
						</h1>
						<Button
							title='Back'
							variant='outlined'
							color='secondary'
							onClick={() => history.goBack()}
						/>
					</div>
					<hr />
					<div className='p-2'>
						<Tabs
							TabsTiles={[
								'General',
								'Line Items',
								'Payments',
								'Refunds',
								'Voided Transactions',
								'Transactions Logs',
							]}
							TabsContent={[
								<Basic orderData={orderData} workStationData={workStationData} />,
								<LineItems itemsData={itemsData} />,
								<Transactions
									orderData={orderData}
									refreshOrder={getData}
									setSelectedIndex={setSelectedTabIndex}
								/>,
								<Refunds orderData={orderData} />,
								<Voids orderData={orderData} />,
								<TransactionsLogs orderData={orderData} />,
							]}
							selectedIndex={selectedTabIndex}
							setSelectedIndex={setSelectedTabIndex}
						/>
					</div>
				</div>
			</div>
		)
	);
}

export default OrderDetails;
