import Toast, { ToastTypes } from 'components/ToastNotify';
import { GetEcommerceVerifoneConfig, MakePayment } from 'firebaseApis/payments';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from 'reducers';
import { IPos } from 'reducers/POS';
import AutocompleteField from 'storybook-mui/build/components/Autocomplete';
import Button from 'storybook-mui/build/components/Button';
import Modal from 'storybook-mui/build/components/Modal';
import InputTextField from 'storybook-mui/build/components/TextField';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import NumberField from 'storybook-mui/build/components/NumberField';
import SelectField from 'storybook-mui/build/components/Select';
import { GetAllCountries } from 'firebaseApis/contacts';
import { SetPosView, SetTotalAmountPaid } from 'reducers/POS/PosActions';
import moment from 'moment';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { creditCardSchema } from '../validations';

const monthOptions = [];
const num = 12;
Array.from(Array(num).keys()).forEach((i) => {
	if (i < 9) {
		monthOptions.push({
			value: `0${i + 1}`,
			label: `0${i + 1}`,
		});
	} else {
		monthOptions.push({
			value: `${i + 1}`,
			label: `${i + 1}`,
		});
	}
});
const yearOptions = [];
const yearStart = new Date().getFullYear();
const yearEnd = yearStart + 40;
const num2 = yearEnd - yearStart;
Array.from(Array(num2).keys()).forEach((i) => {
	yearOptions.push({
		value: `${yearStart + i}`,
		label: `${yearStart + i}`,
	});
});

function CreditCardModal({
	showVirtualCardModal,
	setShowVirtualCardModal,
	amount,
	selectedPaymentModes,
	setSelectedPaymentModes,
	selectedVirtualCard,
	amountPayedThroughAllPaymentModes,
	setAmountPayedThroughAllPaymentModes,
	totalAmountPaid,
	totalAmountDue,
	setTotalAmountDue,
}: VirtualCardModal) {
	const dispatch: any = useDispatch();
	const {
		currency,
		orderId,
		customer,
		totalAmountWithTaxAndGratuity,
		gratuityAmount,
		workStation,
	}: IPos = useSelector((store: RootStore) => store.posReducer);
	const [verifoneConfig = {}, setVerifoneConfig] = React.useState<any>(null);
	const [validateOnChange, setValidateOnChange] = React.useState(false);
	const [formView = 'billing', setFormView] = React.useState<
		'billing' | 'shipping'
	>('shipping');
	const [countries, setCountries] = React.useState([]);
	const getCountries = async () => {
		try {
			const result = await GetAllCountries();
			const dataSource = result.map((item: any) => ({
				label: item.country_name,
				value: item.country_id,
				id: item.country_id,
				states: item.states,
				renderLabel: (
					<div className='flex'>
						<img src={item.flag_svg_url} className='h-6 w-6 mx-2' alt='' />
						{item.country_name}
					</div>
				),
			}));

			setCountries(dataSource);
		} catch (error) {
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
	};

	const cardForm = useFormik({
		initialValues: {
			cardNumber: '',
			expiryYear: '',
			expiryMonth: '',
			cardBrand: '',
			cvv: '',
			zip: '',
			country: 'US',
			city: '',
			state: '',
			address: '',
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			encryptedCard: '',
		},
		validationSchema: creditCardSchema,
		validateOnChange,
		onSubmit: async (values) => {
			try {
				dispatch(showLoading());

				// if payment provider is verifone handle using encrypted card
				if (workStation.Payment_Provider === 'verifone') {
					const newWindowObject = window as any;
					const { verifone } = newWindowObject;
					const encryptionKey = verifoneConfig['public-encryption-key'];
					const card = {
						cardNumber: values.cardNumber,
						expiryMonth: values.expiryMonth,
						expiryYear: values.expiryYear,
						name: `${values.firstName} ${values.lastName}`,
						cvc: values.cvv,
					};
					values.encryptedCard = await verifone.encryptCard(card, encryptionKey);
				}

				const response = await MakePayment({
					values,
					orderId,
					orderAmount: totalAmountWithTaxAndGratuity,
					customerId: customer?.id || '',
					paymentModes: {
						virtualCards: amount,
						physicalCards: 0,
						cash: 0,
						coupons: 0,
						paypal: 0,
					},
					transactionType: 'virtualCard',
					tip: gratuityAmount.toFixed(2),
					successURL: '',
					cancelURL: '',
					refId: moment().valueOf(),
					serviceProvider: workStation.Payment_Provider,
				});

				if (response.data.payment_status === 'success') {
					Toast({ title: 'Payment Success', type: ToastTypes.SUCCESS });
					setShowVirtualCardModal(false);
					const newPaymentModes = [...selectedPaymentModes];
					newPaymentModes[selectedVirtualCard.index].status = 'SUCCESS';
					setSelectedPaymentModes(newPaymentModes);

					// updating total amount payed for virtual card
					setAmountPayedThroughAllPaymentModes({
						...amountPayedThroughAllPaymentModes,
						virtualCards:
							amountPayedThroughAllPaymentModes.virtualCards +
							selectedVirtualCard.amount,
					});

					// updating total amount payed
					dispatch(SetTotalAmountPaid(totalAmountPaid + selectedVirtualCard.amount));
					setTotalAmountDue(totalAmountDue - selectedVirtualCard.amount);

					if (totalAmountDue - selectedVirtualCard.amount < 0.1) {
						dispatch(SetPosView('PAYMENT_SUCCESSFULL'));
					}
				} else {
					Toast({
						title: response?.data?.errorText ?? 'Payment Failed',
						type: ToastTypes.ERROR,
					});
				}
				dispatch(hideLoading());
			} catch (error) {
				dispatch(hideLoading());
				Toast({ title: 'Payment Failed', type: ToastTypes.ERROR });
			}
		},
	});

	const getStates = () => {
		const country = countries.find((c) => c.value === cardForm.values.country);
		const states = [];
		country?.states?.forEach((state) => {
			states.push({
				value: state.state_code,
				label: state.name,
			});
		});
		return states ?? [];
	};

	const getVerifoneConfig = async () => {
		try {
			dispatch(showLoading());
			const response = await GetEcommerceVerifoneConfig();
			dispatch(hideLoading());
			if (response.success) {
				setVerifoneConfig(response.data);
			} else {
				Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
			}
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
	};

	const handleCallback = (data: any) => {
		cardForm.setFieldValue('cardBrand', data.issuer);
	};

	useEffect(() => {
		getCountries();
		if (customer.id !== 'GUEST') {
			cardForm.setValues({
				...cardForm.values,
				firstName: customer.First_Name,
				lastName: customer.Last_Name,
			});
		}
		if (workStation?.Payment_Provider?.toLowerCase() === 'verifone') {
			getVerifoneConfig();
		}
	}, []);

	return (
		<Modal
			title='Enter Credit Card Details'
			content={
				<div>
					<div className='flex gap-5'>
						<div className='flex flex-col gap-5'>
							<h1 className='text-info-500 text-3xl font-semibold'>
								Amount : {currency} {amount}
							</h1>
							<Cards
								cvc={cardForm.values.cvv}
								expiry={`${
									cardForm.values.expiryMonth
								}/${cardForm.values.expiryYear.substring(2, 4)}`}
								focused={cardForm.values.cardNumber ? 'number' : ''}
								name={`${cardForm.values.firstName} ${cardForm.values.lastName}`}
								number={cardForm.values.cardNumber}
								callback={handleCallback}
							/>
						</div>
						<form onSubmit={cardForm.handleSubmit} className='w-full'>
							<div className='flex flex-col gap-5 mt-5'>
								{formView === 'billing' && (
									<>
										<div>
											<NumberField
												labelName='Card Number'
												name='cardNumber'
												fieldId='cardNumber'
												onChange={cardForm.handleChange}
												value={cardForm.values.cardNumber}
												placeholder='Card Number'
												valid={cardForm.errors.cardNumber === undefined}
												errorMessage={cardForm.errors.cardNumber}
												max={999999999999999999}
											/>
										</div>
										<div className='flex w-full gap-5'>
											<SelectField
												name='expiryMonth'
												fieldId='expiryMonth'
												onChange={cardForm.handleChange}
												value={cardForm.values.expiryMonth}
												placeholder='Exp Month (MM)'
												options={monthOptions}
												valid={cardForm.errors.expiryMonth === undefined}
												valueField='value'
												errorMessage={cardForm.errors.expiryMonth}
												displayField='label'
											/>
											<SelectField
												name='expiryYear'
												fieldId='expiryYear'
												onChange={cardForm.handleChange}
												value={cardForm.values.expiryYear}
												options={yearOptions}
												placeholder='Expiry Year (YYYY)'
												valueField='value'
												displayField='label'
												valid={cardForm.errors.expiryYear === undefined}
												errorMessage={cardForm.errors.expiryYear}
											/>
											<InputTextField
												labelName='CVV'
												name='cvv'
												fieldId='cvv'
												onChange={cardForm.handleChange}
												value={cardForm.values.cvv}
												placeholder='CVV'
												valid={cardForm.errors.cvv === undefined}
												errorMessage={cardForm.errors.cvv}
											/>
										</div>
									</>
								)}
								{formView === 'shipping' && (
									<>
										<div className='flex w-full gap-5'>
											<InputTextField
												labelName='First Name'
												name='firstName'
												fieldId='firstName'
												onChange={cardForm.handleChange}
												value={cardForm.values.firstName}
												placeholder='First Name'
												valid={cardForm.errors.firstName}
												errorMessage={cardForm.errors.firstName}
											/>
											<InputTextField
												labelName='Last Name'
												name='lastName'
												fieldId='lastName'
												onChange={cardForm.handleChange}
												value={cardForm.values.lastName}
												placeholder=''
												valid={cardForm.errors.lastName === undefined}
												errorMessage={cardForm.errors.lastName}
											/>
										</div>
										<div className='flex w-full gap-5'>
											<InputTextField
												labelName='Email'
												name='email'
												fieldId='email'
												onChange={cardForm.handleChange}
												value={cardForm.values.email}
												placeholder='Email'
												valid={cardForm.errors.email === undefined}
												errorMessage={cardForm.errors.email}
											/>
											<InputTextField
												labelName='Phone'
												name='phone'
												fieldId='phone'
												onChange={cardForm.handleChange}
												value={cardForm.values.phone}
												placeholder='Phone'
												valid={cardForm.errors.phone === undefined}
												errorMessage={cardForm.errors.phone}
											/>
										</div>
										<div className='flex gap-5'>
											<InputTextField
												labelName='Address'
												name='address'
												fieldId='address'
												onChange={cardForm.handleChange}
												value={cardForm.values.address}
												placeholder='Address'
												valid={cardForm.errors.address === undefined}
												errorMessage={cardForm.errors.address}
											/>
											<InputTextField
												labelName='City'
												name='city'
												fieldId='city'
												onChange={cardForm.handleChange}
												value={cardForm.values.city}
												placeholder='City'
												valid={cardForm.errors.city === undefined}
												errorMessage={cardForm.errors.city}
											/>
										</div>
										<div className='flex w-full gap-5'>
											<AutocompleteField
												fieldId='country'
												onChange={cardForm.handleChange}
												value={cardForm.values.country}
												placeholder='Country'
												valid={cardForm.errors.country === undefined}
												errorMessage={cardForm.errors.country}
												options={countries}
											/>
											<AutocompleteField
												placeholder='State'
												value={cardForm.values.state}
												onChange={(event, option) => {
													cardForm.setFieldValue('state', option?.value);
												}}
												valid={cardForm.errors.state === undefined}
												errorMessage={cardForm.errors.state}
												fieldId='state'
												options={getStates()}
											/>
											<InputTextField
												labelName='Zip Code'
												name='zip'
												fieldId='expiryDate'
												onChange={cardForm.handleChange}
												value={cardForm.values.zip}
												placeholder='Card Number'
												valid={cardForm.errors.zip === undefined}
												errorMessage={cardForm.errors.zip}
											/>
										</div>
									</>
								)}

								<div className='flex justify-end gap-5'>
									{formView === 'shipping' && (
										<Button
											variant='outlined'
											color='inherit'
											onClick={() => setShowVirtualCardModal(false)}
											title='Cancel'
										/>
									)}

									{formView === 'shipping' && (
										<Button
											variant='contained'
											color='primary'
											onClick={() => setFormView('billing')}
											title='Next'
										/>
									)}
									{formView === 'billing' && (
										<Button
											title='Back'
											type='submit'
											variant='outlined'
											onClick={() => setFormView('shipping')}
										/>
									)}
									{formView === 'billing' && (
										<Button
											title='PAY'
											type='submit'
											onClick={() => setValidateOnChange(true)}
											disabled={Object.keys(cardForm.errors).length > 0}
										/>
									)}
								</div>
							</div>
						</form>
					</div>
				</div>
			}
			modalId='creditCardModal'
			open={showVirtualCardModal}
			setOpen={setShowVirtualCardModal}
			size='md'
		/>
	);
}

export default CreditCardModal;

export interface VirtualCardModal {
	showVirtualCardModal: boolean;
	setShowVirtualCardModal: (showVirtualCardModal: boolean) => void;
	amount: number;
	selectedPaymentModes: any;
	setSelectedPaymentModes: any;
	selectedVirtualCard: any;
	amountPayedThroughAllPaymentModes: any;
	setAmountPayedThroughAllPaymentModes: any;
	totalAmountPaid: any;
	totalAmountDue: any;
	setTotalAmountDue: any;
}
