import React from 'react';
import { useSelector } from 'react-redux';
import { RootStore } from 'reducers';
import { IPos } from 'reducers/POS';
import Gratuity from './Components/Gratuity';
import Items from './Components/Items';
import Payment from './Components/Payment';

import PaymentSuccess from './Components/PaymentSuccess';

function Home() {
	const { view }: IPos = useSelector((store: RootStore) => store.posReducer);

	return (
		<>
			<Items />
			{view === 'GRATUITY' && <Gratuity />}
			{view === 'PAYMENT' && <Payment />}
			{view === 'PAYMENT_SUCCESSFULL' && <PaymentSuccess />}
		</>
	);
}

export default Home;
