import { Paper } from '@mui/material';
import { getUserFromRedux } from 'api/AxiosManager';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { GetPosProducts, GetPosTaxConfigs } from 'firebaseApis/lineitems';
import {
	GetLocationInfoById,
	GetWorkStationById,
} from 'firebaseApis/locations';
import {
	GetPosPaymentTerminals,
	RefreshPosPaymentTerminals,
} from 'firebaseApis/terminals';
import { GetUserInfoById } from 'firebaseApis/users';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import {
	SetCatalog,
	SetDeviceStatus,
	SetLocation,
	SetTaxConfigs,
	SetWorkStation,
} from 'reducers/POS/PosActions';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';

function UserInfo() {
	const { organizationId } = getUserFromRedux();
	const dispatch: any = useDispatch();
	const [deviceStatus, setDeviceStatus] = React.useState('DISCONNECTED');
	const [locationData, setLocationData] = React.useState(null);
	const [workStationData, setWorkStationData] = React.useState(null);
	const [userData, setUserData] = React.useState(null);
	const [openUserInfo, setOpenUserInfo] = React.useState(false);

	const getData = async () => {
		try {
			dispatch(showLoading());
			const paymentTerminals = await GetPosPaymentTerminals();
			const ptId = getUserFromRedux()?.paymentTerminalId;
			const pt = paymentTerminals?.data?.find(
				(item: any) => item?.Payment_Terminal_Id === ptId
			);
			if (pt) {
				setDeviceStatus(pt?.POIState);
				dispatch(SetDeviceStatus(pt?.POIState));
			}
			const locationInfo = await GetLocationInfoById();
			setLocationData(locationInfo);
			dispatch(SetLocation(locationInfo));
			const workStationInfo = await GetWorkStationById();
			setWorkStationData(workStationInfo);
			dispatch(SetWorkStation(workStationInfo));
			const userInfo = await GetUserInfoById();
			setUserData(userInfo);
			const taxConfigs = await GetPosTaxConfigs();
			dispatch(SetTaxConfigs(taxConfigs));
			const catalog: any = await GetPosProducts();
			dispatch(SetCatalog(catalog[0]));
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			Toast({ type: ToastTypes.ERROR, title: 'Error getting terminals data' });
		}
	};

	const refreshDeviceStatus = async () => {
		try {
			dispatch(showLoading());
			await RefreshPosPaymentTerminals();
			getData();
		} catch (error) {
			dispatch(hideLoading());
			Toast({ type: ToastTypes.ERROR, title: 'Error refreshing status' });
		}
	};

	useEffect(() => {
		getData();
	}, []);
	return (
		<div className='flex justify-end items-center w-max relative h-max'>
			<Icon
				icon={IconTypes.AccountCircle}
				textSize='text-3xl'
				onClick={() => setOpenUserInfo(!openUserInfo)}
				className='text-gray-500 text-semibold cursor-pointer'
			/>
			{openUserInfo && (
				<Paper className='absolute top-10 p-5 z-10'>
					<div className='flex flex-col mr-1 w-max  z-10'>
						<div className='flex flex-col w-full justify-start text-sm text-gray-600 font-semibold'>
							<h1>
								{userData.FirstName} {userData.LastName}
							</h1>
							<hr />
							<h1>Org Id : {organizationId}</h1>
							<h1 className=''>Date : {moment().format('MM-DD-YYYY')}</h1>
							<h1 className=''>Work Station : {workStationData.Work_Station_Name}</h1>
							{locationData && (
								<h1 className=''>Location : {locationData.Location_Name}</h1>
							)}
							<h1>Device Status : {deviceStatus.toUpperCase()}</h1>
							<hr />
							<div className='flex justify-between mt-3'>
								<h1
									className='underline cursor-pointer'
									onClick={() => {
										refreshDeviceStatus();
									}}
								>
									Refresh Device Status
								</h1>
								<Icon
									icon={IconTypes.Clear}
									onClick={() => {
										setOpenUserInfo(false);
									}}
									className='text-gray-700 text-semibold cursor-pointer'
								/>
							</div>
						</div>
					</div>
				</Paper>
			)}
		</div>
	);
}

export default UserInfo;
