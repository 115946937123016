import {
	getOrganisationIdFirebase,
	create,
	getClientDB,
	getUserFromRedux,
	siteConfigConstants,
} from 'api/AxiosManager';
import {
	collection,
	doc,
	getDoc,
	getDocs,
	query,
	setDoc,
	where,
} from 'firebase/firestore';
import store from 'reducers';
import { IPos } from 'reducers/POS';

export const MakePayment = async ({
	values, // credicard values
	paymentModes,
	tip,
	transactionType,
	serviceProvider = '',
	successURL = '',
	cancelURL = '',
	refId = '',
}: any) => {
	try {
		const {
			finalAmountAfterAllDiscounts,
			customerDiscountAmountFromAffiliateCode,
			couponDiscountAmount,
			orderId,
			affiliate,
			coupon,
			customer,
			subTotal,
			workStation,
		}: IPos = store.getState().posReducer;

		// common for all payment modes
		let payload: any = {
			Organization_Id: getOrganisationIdFirebase(),
			customer_id: customer?.id || '',
			Payment_Terminal_Id:
				getUserFromRedux()?.paymentTerminalId ||
				workStation.Connected_Payment_Terminal ||
				'',
			orderid: orderId,
			total: Number(finalAmountAfterAllDiscounts.toFixed(2)),
			tip: Number(Number(tip).toFixed(2)),
			CouponDiscount: Number(couponDiscountAmount.toFixed(2)),
			AffiliateCustomerDiscount: Number(
				customerDiscountAmountFromAffiliateCode.toFixed(2)
			),
			Coupon: coupon,
			Affiliate: {
				...affiliate,
				pointsEarned:
					affiliate?.AffiliateConfigData?.referalPointsForAffiliate * subTotal,
			},
			paymentModes,
			env: getUserFromRedux().env,
			transactionType: transactionType || '',
			serviceProvider: serviceProvider || '',
		};

		// if payment mode is credit card
		if (payload.transactionType === 'virtualCard') {
			// for authorize.net and other payment gateways
			payload = {
				...payload,
				cardNumber: values?.cardNumber || '',
				expirationDate: `${values?.expiryYear}-${values?.expiryMonth}`,
				cardCode: values?.cvv || '',
				currency_code: 'USD',
				card_brand: values?.cardBrand || '',
				encryptedCard: values?.encryptedCard || '',
				customer: {
					firstName: values?.firstName || '',
					lastName: values?.lastName || '',
					zip: values?.zip || '',
					country: values?.country || '',
				},
			};

			// extra fields for verifone
			if (payload?.serviceProvider === 'verifone') {
				payload.customer = {
					...payload.customer,
					city: values?.city || '',
					phone: values?.phone || '',
					country_code: values?.country_code || '+1',
					state: values?.state || '',
					address: values?.address || '',
				};
			}
		}

		// if payment mode is paypal
		if (payload.transactionType === 'paypal') {
			payload = {
				...payload,
				successURL,
				cancelURL,
				refId,
			};
		}

		const instance = create();

		const result = await instance.post(
			`${
				siteConfigConstants().REACT_APP_POS_STORE
			}/item/payment?Organization_Id=${getOrganisationIdFirebase()}`,
			payload
		);

		return result.data;
	} catch (error) {
		return error;
	}
};

export const RefundAmount = async ({
	orderId,
	amount,
	transactionType,
	transactionId,
	cardNumber,
}: any) => {
	const { workStation }: IPos = store.getState().posReducer;
	const instance = create();
	const result = await instance.post(
		`${
			siteConfigConstants().REACT_APP_POS_STORE
		}/item/refund?Organization_Id=${getOrganisationIdFirebase()}`,
		{
			Organization_Id: getOrganisationIdFirebase(),
			Payment_Terminal_Id:
				getUserFromRedux()?.paymentTerminalId ||
				workStation.Connected_Payment_Terminal ||
				'',
			orderid: orderId,
			Currency: '$',
			RequestedAmount: amount,
			ServiceID: '',
			transactionType,
			TransactionID: transactionId,
			cardNumber,
			env: getUserFromRedux().env,
		}
	);

	return result.data;
};

export const VoidTransaction = async ({
	orderId,
	amount,
	transactionType,
	transactionId,
	cardNumber,
}: any) => {
	try {
		const instance = create();
		const result = await instance.post(
			`$${
				siteConfigConstants().REACT_APP_POS_STORE
			}/void?Organization_Id=${getOrganisationIdFirebase()}`,
			{
				Organization_Id: getOrganisationIdFirebase(),
				Payment_Terminal_Id: getUserFromRedux().paymentTerminalId,
				orderid: orderId,
				Currency: '$',
				RequestedAmount: amount,
				ServiceID: '',
				transactionType,
				TransactionID: transactionId,
				cardNumber,
				env: getUserFromRedux().env,
			}
		);

		return result.data;
	} catch (error) {
		return error;
	}
};

export const GetAuthorizePaymentStatus = async (transactionId: string) => {
	const instance = create();
	const result = await instance.post(
		`${
			siteConfigConstants().REACT_APP_POS_STORE
		}/item/txnstatus?Organization_Id=${getOrganisationIdFirebase()}`,
		{
			Organization_Id: getOrganisationIdFirebase(),
			TransactionID: transactionId,
			env: getUserFromRedux().env,
		}
	);

	return result.data;
};

export const GetTransactionLogsByOrderId = async (orderId: string) => {
	try {
		const qry = query(
			collection(getClientDB(), `${getOrganisationIdFirebase()}/POS/pg_logs`),
			where('orderid', '==', Number(orderId)),
			where('action', '!=', 'ADD')
		);
		const resultSnapshots = await getDocs(qry);
		const resultArray = resultSnapshots.docs.map((document) => ({
			...document.data(),
			id: document.id,
		}));

		return resultArray;
	} catch (error) {
		return error;
	}
};

export const ChangePaypalPaymentStatusToAithorized = async ({
	orderId,
	refId,
	payerId,
}) => {
	try {
		const orgId = getOrganisationIdFirebase();
		const orderResponse = await getDoc(
			doc(getClientDB(), `${orgId}/POS/orders`, orderId)
		);
		const orderData = orderResponse.data();

		const updatedOrderData = orderData;
		const paymentToUpdate = updatedOrderData?.payment_details?.find(
			(payment: any) => payment.refId === Number(refId)
		);
		paymentToUpdate.status = 'authorized';
		paymentToUpdate.payerId = payerId;
		await setDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}/POS/orders`, orderId),
			updatedOrderData
		);

		return updatedOrderData;
	} catch (error) {
		return error;
	}
};

export const AuthorizeAndContinuePaypalPayment = async ({
	orderId,
	payerId,
	transactionId,
	refId,
}: any) => {
	try {
		const payload = {
			Organization_Id: getOrganisationIdFirebase(),
			orderid: orderId,
			payerID: payerId,
			refId,
			refTransId: transactionId,
			env: getUserFromRedux().env,
			Payment_Terminal_Id: getUserFromRedux().paymentTerminalId,
		};

		const instance = create();
		const response = await instance.post(
			`$${
				siteConfigConstants().REACT_APP_POS_STORE
			}/item/paypaltxnstatus?Organization_Id=${getOrganisationIdFirebase()}`,
			payload
		);

		return response.data;
	} catch (error) {
		return error;
	}
};

export const GetEcommerceVerifoneConfig = async () => {
	try {
		const response = await getDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}/Ecommerce/Admin`,
				'VerifoneConfig'
			)
		);
		if (response.exists && response.data()) {
			let dataToReturn = null;
			if (getUserFromRedux().env?.toLowerCase() === 'production') {
				dataToReturn = response.data().production;
			} else {
				dataToReturn = response.data().sandbox;
			}
			return {
				data: dataToReturn,
				success: true,
			};
		}
		return {
			data: null,
			success: false,
		};
	} catch (error) {
		return {
			data: null,
			success: false,
		};
	}
};
