import React from 'react';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';

function ErrorMessage({ message, setShowErrorMessage } : IErrorMessage) {
	return (
		<div className='bg-error p-5 rounded flex justify-between'>
			<h1 className=''>{message}</h1>
			<Icon
				icon={IconTypes.Close}
				className='cursor-pointer'
				onClick={() => setShowErrorMessage(false)}
			/>
		</div>
	);
}

export default ErrorMessage;

export interface IErrorMessage {
	setShowErrorMessage: any;
	message: string;
}
