import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { ChangePaypalPaymentStatusToAithorized } from 'firebaseApis/payments';
import { GetOrderStatus } from 'firebaseApis/orders';
import { IOrderData, IPaymentDetailsInOrder } from 'interfaces';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';

function PaypalResultHandler() {
	const [loading, setLoading] = useState(true);
	const { search } = useLocation();
	const { orderId, PayerID, refId }: any = queryString?.parse(search);
	const { currency } = useSelector((state: any) => state.posReducer);
	const [currentTransactionData, setCurrentTransactionData] =
		useState<IPaymentDetailsInOrder>();
	const dispatch:any = useDispatch();
	const changeTransactionStatus = async () => {
		try {
			dispatch(showLoading());
			await ChangePaypalPaymentStatusToAithorized({
				orderId,
				payerId: PayerID,
				refId,
			});
			setLoading(false);
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			setLoading(false);
		}
	};
	useEffect(() => {
		changeTransactionStatus();
	}, []);

	let unSubscribe = () => {};

	const getTransactionStatus = async () => {
		unSubscribe = GetOrderStatus(orderId, (snapshot: any) => {
			const orderData: IOrderData = snapshot.data();
			const paymentDone =
				orderData?.payment_details?.find(
					(payment) => Number(payment.refId) === Number(refId)
				) || null;
			setCurrentTransactionData(paymentDone);
			if (paymentDone?.status === 'completed') {
				setLoading(false);
				unSubscribe();
			}
		});
	};

	useEffect(() => {
		if (
			currentTransactionData?.status !== 'completed' ||
			!currentTransactionData
		) {
			getTransactionStatus();
		}
		return () => unSubscribe();
	}, []);

	useEffect(() => {
		if (currentTransactionData?.status === 'authorized') {
			setLoading(true);
		}
		if (currentTransactionData?.status === 'completed') {
			setLoading(false);
			unSubscribe();
		}
	}, [currentTransactionData]);

	const getReturnContent = () => {
		if (loading) {
			return <div>Loading...</div>;
		}
		if (currentTransactionData) {
			return (
				<div className='flex flex-col'>
					<div className='flex flex-col items-center mb-2'>
						{currentTransactionData?.status === 'completed' && (
							<div className='flex items-center justify-center'>
								<Icon className='text-white-700 text-9xl' icon={IconTypes.Checkmark} />
							</div>
						)}
						{currentTransactionData?.status === 'authorized' && (
							<div className='flex items-center justify-center'>
								<Icon className='text-white-700 text-9xl' icon={IconTypes.Info} />
							</div>
						)}
						<div>
							<h1>Payment Status : {currentTransactionData?.status?.toUpperCase()}</h1>
							<h1>Order Id : {orderId}</h1>
							<h1>
								Amount : {currency} {currentTransactionData?.authorized_amount}
							</h1>
							<h1>Transaction Id: {currentTransactionData.transactionid}</h1>
						</div>
					</div>

					{currentTransactionData.status !== 'completed' && (
						<>
							<hr />
							<ul className='list-disc p-3 mt-2'>
								<li>
									Please do not refresh the page or close the browser until the payment
									is successfull
								</li>
								<li>
									If the payment is not completed within 2 minuts or if the amount is
									already deducted please contact merchant with transaction Id
								</li>
							</ul>
						</>
					)}
				</div>
			);
		}

		return <div>Transaction Failed</div>;
	};

	return (
		<div
			className={`p-5 flex h-screen items-center justify-center text-white
		   ${
						currentTransactionData?.status === 'completed'
							? 'bg-green-600'
							: 'bg-yellow-600'
					}
		   
		 `}
		>
			{!loading && <div>{getReturnContent()}</div>}
		</div>
	);
}

export default PaypalResultHandler;
