import {
	getClientDB,
	getOrganisationIdFirebase,
	getUserFromRedux,
} from 'api/AxiosManager';
import { collection, getDocs, query } from 'firebase/firestore';
import { doc, getDoc } from 'firebase/firestore/lite';

export const GetLocationInfoById = async () => {
	try {
		const result = await getDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Locations/Locations`,
				getUserFromRedux().locationId
			)
		);

		return {
			id: result.id,
			...result.data(),
		};
	} catch (error) {
		return error;
	}
};

export const GetWorkStationById = async () => {
	const result = await getDoc(
		doc(
			getClientDB(),
			`/${getOrganisationIdFirebase()}/POS/Work_Stations`,
			getUserFromRedux().workStationId
		)
	);

	return result.data();
};

export const GetAllLocations = async () => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Locations/Locations`
			)
		);
		const querySnapshot = await getDocs(qry);
		const resultData = querySnapshot.docs.map((d) => ({
			...d.data(),
			id: d.id,
		}));
		return resultData;
	} catch (error) {
		return error;
	}
};

export const GetAllWorkStations = async () => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/POS/Work_Stations`
			)
		);
		const querySnapshot = await getDocs(qry);
		const resultData = querySnapshot.docs.map((d) => ({
			...d.data(),
			id: d.id,
		}));
		return resultData;
	} catch (error) {
		return error;
	}
};

export const GetOrganizationInfoById = async () => {
	try {
		const organizationId = getOrganisationIdFirebase();
		const result = await getDoc(
			doc(
				getClientDB(),
				`${organizationId}/Organization/Organization/${organizationId}`
			)
		);

		return result.data();
	} catch (error) {
		return error;
	}
};
