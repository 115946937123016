import React, { useState } from 'react';
import Modal from 'storybook-mui/build/components/Modal';
import AddNew from './AddNew';
import SearchExisting from './SearchExisting';

function SelectCustomer({
	showCustomerSelectionModal,
	setShowCustomerSelectionModal,
}: ISelectCustomerProps) {
	const [modelView, setModelView] = useState<'SELECTION' | 'ADD' | 'SEARCH'>(
		'SELECTION'
	);
	return (
		<Modal
			size='md'
			title={
				<h1 className=''>
					{modelView === 'SELECTION' || modelView === 'ADD'
						? 'Add Customer'
						: 'Search Existing Customer'}
				</h1>
			}
			modalId=''
			open={showCustomerSelectionModal}
			setOpen={() => setShowCustomerSelectionModal(false)}
			closeIcon
			content={
				<div className='flex flex-col space-y-5'>
					{modelView === 'SELECTION' && (
						<div className='space-x-5 flex text-center'>
							<h1
								className='p-5 text-3xl cursor-pointer w-1/2 rounded bg-teal-700 text-white'
								onClick={() => setModelView('ADD')}
							>
								ADD CUSTOMER
							</h1>
							<h1
								className='p-5 text-3xl   cursor-pointer w-1/2 rounded bg-leapbrown-300 text-white'
								onClick={() => setModelView('SEARCH')}
							>
								SEARCH CUSTOMER
							</h1>
						</div>
					)}
					{modelView === 'ADD' && (
						<AddNew
							setModelView={setModelView}
							setShowCustomerSelectionModal={setShowCustomerSelectionModal}
						/>
					)}
					{modelView === 'SEARCH' && (
						<SearchExisting
							setModelView={setModelView}
							setShowCustomerSelectionModal={setShowCustomerSelectionModal}
						/>
					)}
				</div>
			}
		/>
	);
}

export default SelectCustomer;

export interface ISelectCustomerProps {
	showCustomerSelectionModal: boolean;
	setShowCustomerSelectionModal: (value: boolean) => void;
}
