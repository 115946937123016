import { IProductInterface } from 'interfaces';
import React from 'react';
import Modal from 'storybook-mui/build/components/Modal';

function AddPunchCardModal({ open, setOpen, product }: AddOunchCardModalProps) {
	return (
		<Modal
			open={open}
			setOpen={() => setOpen(false) }
			title='Add Punch Card'
			content={
				<div>
					<p>Gift Card</p>
					{product && product.name}
				</div>
			}
			modalId='add-gift-card-modal'
			closeIcon
		/>
	);
}

export default AddPunchCardModal;

export interface AddOunchCardModalProps {
	open: boolean;
	setOpen: any;
	product: IProductInterface;
}
