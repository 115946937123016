import Toast, { ToastTypes } from 'components/ToastNotify';
import { MakePayment } from 'firebaseApis/payments';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from 'reducers';
import { IPos } from 'reducers/POS';
import {
	SetAffiliate,
	SetPosView,
	SetTotalAmountPaid,
	SetCoupon,
} from 'reducers/POS/PosActions';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import Modal from 'storybook-mui/build/components/Modal';
import Button from 'storybook-mui/build/components/Button';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { getOrganisationIdFirebase, getUserId } from 'api/AxiosManager';
import moment from 'moment';
import Divider from 'components/Divider';
import VirtualCardModal from './PaymentModals/VirtualCardModal';

import CashModal from './PaymentModals/CashModal';
import PaypalModal from './PaymentModals/PaypalModal';
import CancelOrderModal from './CancelOrderModal';
import PaymentMethodSelection from './PaymentMethodSelection';
import AddAffiliate from './AddAffiliate';
import DiscountsAppliedModal from './DiscountsAppliedModal';
// eslint-disable-next-line import/no-cycle
import AmountColumn from './AmountColumn';
import AddCoupon from './AddCoupon';
import SelectCustomer from './SelectCustomer';
import Gratuity from './AddGratuity';
import GiftCardModal from './PaymentModals/GiftCardModal';

function Payment() {
	const dispatch: any = useDispatch();
	const {
		totalAmountWithTaxAndGratuity,
		subTotal,
		finalAmountAfterAllDiscounts,
		gratuityAmount,
		customer,
		orderId,
		view,
		currency,
		totalAmountPaid,
		affiliate,
		coupon,
		customerDiscountAmountFromAffiliateCode,
		couponDiscountAmount,
		taxAmount,
		laneItems,
	}: IPos = useSelector((store: RootStore) => store.posReducer);

	const nonGeneralTypeLineItems = laneItems.some(
		(item: any) => (item.product_type && item.product_type !== 'general' && item.product_type !== '')
	);

	const [showCustomerSelectionModal, setShowCustomerSelectionModal] =
		useState<boolean>(false);

	const [showGratuityModal = false, setShowGratuityModal] = useState<boolean>();

	const [showPaymentMethods, setShowPaymentMethods] = useState(true);
	const [paymentMethodSelectionModalType, setPaymentMethodSelectionModalType] =
		useState<'add' | 'edit'>('add');
	const [selectedPaymentMethodIndex, setSelectedPaymentMethodIndex] =
		useState(0);

	const [showAddAffiliateModal, setShowAddAffiliateModal] = useState(false);
	const [showDiscountSAppliedModel, setShowDiscountSAppliedModel] =
		useState<any>();
	const [showAddCouponModal, setShowAddCouponModal] = useState(false);

	// virtual card
	const [showVirtualCardModal, setShowVirtualCardModal] = useState(false);
	const [selectedVirtualCard, setSelectedVirtualCard] = useState(null);

	// cash
	const [showCashPaymentModal, setShowCashPaymentModal] = useState(false);
	const [selectedCashPayment = 0, setSelectedCashPayment] = useState(null);

	// gift card
	const [showGiftCardPaymentModal = false, setShowGiftCardPaymentModal] =
		useState(false);
	const [selectedGiftCardPayment = 0, setSelectedGiftCardPayment] =
		useState(null);

	// paypal
	const [showPaypalModal, setShowPaypalModal] = useState(false);
	const [selectedPaypalPayment, setSelectedPaypalPayment] = useState(null);

	const [selectedPaymentModes, setSelectedPaymentModes] = useState<
		IPaymentMode[]
	>([]);

	const [totalAmountDue, setTotalAmountDue] = useState(
		finalAmountAfterAllDiscounts
	);
	const [
		amountPayedThroughAllPaymentModes,
		setAmountPayedThroughAllPaymentModes,
	] = useState({
		cash: 0,
		physicalCards: 0,
		virtualCards: 0,
		paypal: 0,
	});
	const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);

	const deletePaymentMode = (index: number) => {
		const newPaymentModes = [...selectedPaymentModes];
		newPaymentModes.splice(index, 1);
		setSelectedPaymentModes(newPaymentModes);
	};

	const initiatePayment = async (paymentMode: IPaymentMode, index: any) => {
		try {
			dispatch(showLoading());
			const refId = moment().valueOf();
			let successURL = `${window.location.origin}/?paypalResultHandler=true&result=success`;
			successURL = `${successURL}&orderId=${orderId}`;
			successURL = `${successURL}&refId=${refId}`;
			successURL = `${successURL}&organizationId=${getOrganisationIdFirebase()}`;
			successURL = `${successURL}&userId=${getUserId()}`;

			const cancelURL = `${window.location.origin}/?paypalResultHandler=true&result=cancel`;
			// making payment
			const response = await MakePayment({
				orderId,
				tip: gratuityAmount,
				customerId: customer.id,
				transactionType: paymentMode.value,
				orderAmount: totalAmountWithTaxAndGratuity,
				coupon_code: paymentMode?.couponCode || '',
				successURL,
				cancelURL,
				refId,
				paymentModes: {
					cash: paymentMode.value === 'cash' ? paymentMode.amount : 0,
					physicalCards:
						paymentMode.value === 'physicalCards' ? paymentMode.amount : 0,
					virtualCards: 0,
					paypal: paymentMode.value === 'paypal' ? Number(paymentMode.amount) : 0,
					giftcards : paymentMode.value === 'giftcards' ? Number(paymentMode.amount) : 0,
				},
			});

			if (response?.data?.payment_status === 'success') {
				// payapl
				if (paymentMode.value === 'paypal') {
					setShowPaypalModal(true);
					setSelectedPaypalPayment({
						...paymentMode,
						paymentURL: response?.data?.SecureAcceptanceUrl,
						refId,
						transactionId: response?.data?.transId,
					});
				} else {
					// updating payment mode status
					const newPaymentModes = [...selectedPaymentModes];
					newPaymentModes[index].status = 'SUCCESS';
					newPaymentModes[index].amount = paymentMode.amount;
					setSelectedPaymentModes(newPaymentModes);

					// updating total amount payed and due
					const tempTotalPaid = Number(totalAmountPaid) + Number(paymentMode.amount);
					dispatch(SetTotalAmountPaid(tempTotalPaid));
					setTotalAmountDue(finalAmountAfterAllDiscounts - tempTotalPaid);

					if (totalAmountDue - paymentMode.amount < 0.1) {
						dispatch(SetPosView('PAYMENT_SUCCESSFULL'));
					}

					// based on the payment mode, update the amount payed through all payment modes
					setAmountPayedThroughAllPaymentModes({
						...amountPayedThroughAllPaymentModes,
						[paymentMode.value]:
							amountPayedThroughAllPaymentModes.cash + paymentMode.amount,
					});
					setShowCashPaymentModal(false);
					Toast({ type: ToastTypes.SUCCESS, title: 'Payment Successfull' });
				}
			} else {
				Toast({
					title: 'Payment Failed',
					type: ToastTypes.ERROR,
				});
			}
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
	};

	return (
		<Modal
			title=''
			closeIcon
			size='xl'
			content={
				<>
					<h1 className='text-2xl text-center text-info-500 font-semibold uppercase'>
						PAYMENT
					</h1>
					<div className='flex gap-5 justify-between flex-col lg:flex-row md:flex-col xl:flex-row my-5'>
						<div className='relative flex flex-col justify-between gap-5'>
							{selectedPaymentModes.length > 0 && (
								<table className='table-auto w-full'>
									<thead className='bg-gray-500 text-white py-5'>
										<tr className='text-left'>
											<th className='pr-10 pl-3 py-5'>Payment Method</th>
											<th className='pr-10'>Amount</th>
											<th className='pr-10'>Status</th>
											<th className='pr-10'>Delete</th>
										</tr>
									</thead>
									<tbody>
										{selectedPaymentModes.map((paymentMode, index) => (
											<tr className='text-start border'>
												<td className='pl-3 pr-10'>
													<div className='flex gap-5 font-semibold items-center py-5'>
														<div className='flex items-center gap-3'>
															<img src={paymentMode.image} alt='' className='w-18 h-12' />
															<h1>{paymentMode.name}</h1>
														</div>

														{paymentMode.status === 'PENDING' && (
															<Icon
																icon={IconTypes.Edit}
																className='cursor-pointer text-3xl text-orange-500'
																onClick={() => {
																	setSelectedPaymentMethodIndex(index);
																	setPaymentMethodSelectionModalType('edit');
																	setShowPaymentMethods(true);
																}}
															/>
														)}
													</div>
												</td>
												<td>
													<div className='flex gap-5 items-center mr-10 my-5'>
														<AmountColumn
															paymentMode={paymentMode}
															setSelectedPaymentModes={setSelectedPaymentModes}
															selectedPaymentModes={selectedPaymentModes}
															index={index}
															totalAmountDue={totalAmountDue}
															setShowCashPaymentModal={setShowCashPaymentModal}
															setSelectedVirtualCard={setSelectedVirtualCard}
															setShowVirtualCardModal={setShowVirtualCardModal}
															setSelectedCashPayment={setSelectedCashPayment}
															setSelectedGiftCardPayment={setSelectedGiftCardPayment}
															setShowGiftCardModal={setShowGiftCardPaymentModal}
															initiatePayment={initiatePayment}
														/>
													</div>
												</td>

												<td>
													<h1 className=''>{paymentMode.status}</h1>
												</td>
												<td>
													{paymentMode.status !== 'SUCCESS' && (
														<Icon
															icon={IconTypes.Delete}
															onClick={() => {
																deletePaymentMode(index);
															}}
															className='cursor-pointer'
														/>
													)}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							)}

							{nonGeneralTypeLineItems && customer.id === 'GUEST' && (
								<div className='bg-error text-black rounded p-5 w-max'>
									<h1>
										This order has non general type line items. Please select a customer
										to proceed.
									</h1>
								</div>
							)}

							<div className='grid grid-cols-5 gap-5 mt-5'>
								<Button
									title='Add Payment Method'
									onClick={() => {
										setPaymentMethodSelectionModalType('add');
										setShowPaymentMethods(true);
									}}
									variant='outlined'
									color='info'
									disabled={
										selectedPaymentModes.filter((sm) => sm.status === 'PENDING').length >
										0
									}
									size='large'
								/>
								{!coupon && !affiliate && (
									<>
										<Button
											title='Add Coupon'
											onClick={() => {
												setShowAddCouponModal(true);
											}}
											variant='outlined'
											color='info'
											size='large'
										/>
										<Button
											title='Add Affiliate'
											onClick={() => setShowAddAffiliateModal(true)}
											variant='outlined'
											color='success'
											size='large'
										/>
									</>
								)}

								<Button
									title={`${
										customer.id === 'GUEST' ? 'Add Customer' : 'Change Customer'
									}`}
									onClick={() => setShowCustomerSelectionModal(true)}
									variant='outlined'
									color='warning'
									size='large'
								/>
							</div>
						</div>

						<div className='shadow border border-gray-300 p-5 flex flex-col gap-2 w-[450px]'>
							<div className='shadow-sm  p-2 flex flex-col gap-1'>
								<h1 className=' font-semibold text-info-500 uppercase text-xl'>
									Order Summary
								</h1>
								<Divider />
								<div className='flex justify-between w-full'>
									<h1>Customer : </h1>
									<h1>
										{customer.id !== 'GUEST'
											? `${customer?.First_Name} ${customer?.Last_Name}`
											: 'Guest'}
									</h1>
								</div>
								<div className='flex justify-between w-full'>
									<h1>Items Total : </h1>
									<h1>
										{currency} {Number(subTotal).toFixed(2)}
									</h1>
								</div>
								{(affiliate || coupon) && (
									<div className='flex justify-between'>
										<div className='flex gap-2 items-center cursor-pointer'>
											<h1 className=''>
												Discount : ({affiliate ? 'Affiliate' : 'Coupon'})
											</h1>
											<Icon
												icon={IconTypes.Eye}
												onClick={() => {
													setShowDiscountSAppliedModel(true);
												}}
											/>
											<Icon
												icon={IconTypes.Delete}
												onClick={() => {
													if (affiliate) {
														dispatch(SetAffiliate(null));
													} else {
														dispatch(SetCoupon(null));
													}
												}}
											/>
										</div>
										<h1>
											- {currency}{' '}
											{Number(
												affiliate
													? customerDiscountAmountFromAffiliateCode
													: couponDiscountAmount
											).toFixed(2)}
										</h1>
									</div>
								)}

								<hr />
								<div className='flex justify-between'>
									<h1>Total</h1>
									<h1>
										{currency}{' '}
										{Number(
											subTotal -
												(affiliate
													? customerDiscountAmountFromAffiliateCode
													: couponDiscountAmount)
										).toFixed(2)}
									</h1>
								</div>

								<div className='flex justify-between'>
									<h1>Gratuity</h1>
									<h1>
										{currency} {Number(gratuityAmount).toFixed(2)}
									</h1>
								</div>
								<div className='flex justify-between'>
									<h1>Tax</h1>
									<h1>
										{currency} {Number(taxAmount).toFixed(2)}
									</h1>
								</div>

								<hr />
								<div className='flex justify-between text-primary-800 font-semibold mt-3 text-xl uppercase'>
									<h1>GRAND TOTAL</h1>
									<h1>
										{currency} {Number(finalAmountAfterAllDiscounts).toFixed(2)}
									</h1>
								</div>
							</div>
							<div>
								{totalAmountPaid > 0 && (
									<>
										<div className='shadow-sm  flex flex-col gap-1 p-2 text-md'>
											<Divider />
											<h1 className=' font-semibold text-info-500 uppercase'>
												Payment Methods
											</h1>

											{Object.keys(amountPayedThroughAllPaymentModes).map(
												(key) =>
													amountPayedThroughAllPaymentModes[key] > 0 && (
														<div key={key} className='flex justify-between'>
															<h1>{key.toUpperCase()}</h1>
															<h1>
																{currency}{' '}
																{Number(amountPayedThroughAllPaymentModes[key]).toFixed(2)}
															</h1>
														</div>
													)
											)}
										</div>

										<div className='p-2 text-leapbrown-500 uppercase text-xl font-semibold'>
											<div className='flex justify-between mt-5'>
												<h1>Total Amount Paid</h1>
												<h1>
													{currency} {Number(totalAmountPaid).toFixed(2)}
												</h1>
											</div>
											<div className='flex justify-between'>
												<h1>Balance Amount</h1>
												<h1>
													{currency} {Number(totalAmountDue).toFixed(2)}
												</h1>
											</div>
										</div>
									</>
								)}
							</div>
						</div>

						{showGratuityModal && (
							<Gratuity
								showGratuityModal={showGratuityModal}
								setShowGratuityModal={setShowGratuityModal}
							/>
						)}

						{showCustomerSelectionModal && (
							<SelectCustomer
								showCustomerSelectionModal={showCustomerSelectionModal}
								setShowCustomerSelectionModal={setShowCustomerSelectionModal}
							/>
						)}

						<AddAffiliate
							showAddAffiliateModal={showAddAffiliateModal}
							setShowAddAffiliateModal={setShowAddAffiliateModal}
							setTotalAmountDue={setTotalAmountDue}
						/>

						<AddCoupon
							showAddCouponModal={showAddCouponModal}
							setShowAddCouponModal={setShowAddCouponModal}
							setTotalAmountDue={setTotalAmountDue}
						/>

						{showDiscountSAppliedModel && (
							<DiscountsAppliedModal
								showDiscountsAppliedModal={showDiscountSAppliedModel}
								setShowDiscountsAppliedModal={setShowDiscountSAppliedModel}
							/>
						)}

						{showPaymentMethods && (
							<PaymentMethodSelection
								showPaymentMethods={showPaymentMethods}
								setShowPaymentMethods={setShowPaymentMethods}
								index={selectedPaymentMethodIndex}
								selectedPaymentModes={selectedPaymentModes}
								setSelectedPaymentModes={setSelectedPaymentModes}
								type={paymentMethodSelectionModalType}
								setType={setPaymentMethodSelectionModalType}
								totalAmountDue={totalAmountDue}
							/>
						)}

						{showVirtualCardModal && (
							<VirtualCardModal
								showVirtualCardModal={showVirtualCardModal}
								setShowVirtualCardModal={setShowVirtualCardModal}
								amount={selectedVirtualCard.amount}
								selectedPaymentModes={selectedPaymentModes}
								setSelectedPaymentModes={setSelectedPaymentModes}
								selectedVirtualCard={selectedVirtualCard}
								amountPayedThroughAllPaymentModes={amountPayedThroughAllPaymentModes}
								setAmountPayedThroughAllPaymentModes={
									setAmountPayedThroughAllPaymentModes
								}
								totalAmountPaid={totalAmountPaid}
								totalAmountDue={totalAmountDue}
								setTotalAmountDue={setTotalAmountDue}
							/>
						)}

						{showCashPaymentModal && (
							<CashModal
								selectedCashPayment={selectedCashPayment}
								setSelectedCashPayment={setSelectedCashPayment}
								initiatePayment={initiatePayment}
								showCashPaymentModal={showCashPaymentModal}
								setShowCashPaymentModal={setShowCashPaymentModal}
							/>
						)}

						{showGiftCardPaymentModal && (
							<GiftCardModal
								showGiftCardPaymentModal={showGiftCardPaymentModal}
								setShowGiftCardPaymentModal={setShowGiftCardPaymentModal}
								selectedGiftCardPayment={selectedGiftCardPayment}
								setSelectedGiftCardPayment={setSelectedGiftCardPayment}
								initiatePayment={initiatePayment}
							/>
						)}

						{showPaypalModal && (
							<PaypalModal
								showPaypalModal={showPaypalModal}
								setShowPaypalModal={setShowPaypalModal}
								selectedPaypalPayment={selectedPaypalPayment}
								setSelectedPaypalPayment={setSelectedPaypalPayment}
								selectedPaymentModes={selectedPaymentModes}
								setSelectedPaymentModes={setSelectedPaymentModes}
								setAmountPayedThroughAllPaymentModes={
									setAmountPayedThroughAllPaymentModes
								}
								amountPayedThroughAllPaymentModes={amountPayedThroughAllPaymentModes}
								totalAmountPaid={totalAmountPaid}
								setTotalAmountDue={setTotalAmountDue}
								totalAmountDue={totalAmountDue}
							/>
						)}

						{showCancelOrderModal && (
							<CancelOrderModal
								showCancelOrderModal={showCancelOrderModal}
								setShowCancelOrderModal={setShowCancelOrderModal}
							/>
						)}
					</div>

					{/* Payment Instructions */}
					<hr />
					<div className='mt-5'>
						<h1 className='uppercase underline'>Payment Instructions</h1>
						<ol className='list-decimal ml-4 mt-3'>
							<li>
								Please make sure that the customer has selected the correct payment.
							</li>
							<li>
								Please select the customer , affiliate and coupon if any before making
								the payment.
							</li>
						</ol>
					</div>
				</>
			}
			modalId=''
			open={view === 'PAYMENT'}
			setOpen={() => {
				dispatch(SetPosView('HOME'));
			}}
		/>
	);
}

export default Payment;

export interface IPaymentMode {
	name: string;
	value: 'cash' | 'physicalCards' | 'virtualCards' | 'paypal' | 'giftcards';
	status: 'SUCCESS' | 'PENDING';
	amount: number;
	couponCode?: string;
	index?: number;
	image?: any;
}
