import React from 'react';

function SessionExpired() {
	return (
		<div>
			<div className='flex items-center justify-center h-screen flex-col gap-10'>
				<h1 className='text-4xl font-semibold text-secondary-600'>
					Your Session has expired
				</h1>
				<h1
					className='text-2xl underline cursor-pointer'
					onClick={() => {
						window.location.href = localStorage.getItem('redirectionLink');
					}}
				>
					{' '}
					Click here to login again
				</h1>
			</div>
		</div>
	);
}

export default SessionExpired;
