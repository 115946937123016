import Toast, { ToastTypes } from 'components/ToastNotify';
import { CancelOrder } from 'firebaseApis/orders';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from 'reducers';

import { IPos } from 'reducers/POS';
import {
	SetGratuityPercentage,
	SetTotalAmountWithTaxAndGratuity,
	ClearPosData,
	SetFinalAmountAfterAllDiscounts,
} from 'reducers/POS/PosActions';
import Button from 'storybook-mui/build/components/Button';
import Modal from 'storybook-mui/build/components/Modal';

function Gratuity({ showGratuityModal, setShowGratuityModal }: GratuityProps) {
	const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
	const {
		subTotal,
		gratuityPercentage,
		orderId,
		currency,
	}: IPos = useSelector((store: RootStore) => store.posReducer);
	const dispatch: any = useDispatch();
	const gratuityPrices = [
		{
			label: '15%',
			percentage: 15,
			text: 'Good Service',
		},
		{
			label: '18%',
			percentage: 18,
			text: 'Better Service',
		},
		{ label: '20%', percentage: 20, text: 'Best Service' },
		{
			label: '25%',
			percentage: 25,
			text: 'Outstanding',
		},
	];

	useEffect(() => {
		dispatch(SetGratuityPercentage(0));
		dispatch(SetTotalAmountWithTaxAndGratuity(subTotal));
	}, []);

	const cancelOrder = async () => {
		try {
			const response: any = await CancelOrder(orderId.toString());
			if (response.success) {
				dispatch(ClearPosData());
				Toast({ title: response.message, type: ToastTypes.SUCCESS });
			} else {
				Toast({ title: response.message, type: ToastTypes.ERROR });
			}
		} catch (error) {
			Toast({ title: error?.message, type: ToastTypes.ERROR });
		}
	};

	return (
		<Modal
			size='md'
			title='Add Gratuity'
			closeIcon
			modalId=''
			open={showGratuityModal}
			setOpen={() => setShowGratuityModal(false)}
			content={
				<div className='flex flex-col space-y-5  text-gray-600'>
					<>
						<h1 className='text-2xl'>Suggested Gratuities</h1>
						<div className='grid justify-between gap-5 grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4'>
							{gratuityPrices.map((item, index) => {
								const gratuity =
									(item.percentage / 100) * subTotal;
								const totalAmount = gratuity + subTotal;

								return (
									<div key={index}>
										<h1 className='text-lg my-1'>{item.text}</h1>
										<div
											className={`w-full cursor-pointer gap-3 flex flex-col bg-white shadow border border-gray-300 p-5 ${
												gratuityPercentage === item.percentage
													? 'bg-info-500 text-white'
													: ''
											}`}
											key={index}
											onClick={() => {
												dispatch(SetGratuityPercentage(Number(item.percentage)));
												dispatch(SetTotalAmountWithTaxAndGratuity(Number(totalAmount)));
												dispatch(SetFinalAmountAfterAllDiscounts(Number(totalAmount)));
												setShowGratuityModal(false);
											}}
										>
											<h1 className='text-3xl font-semibold'>{item.label}</h1>
											<h1>
												Gratuity {currency} {gratuity.toFixed(2)}
											</h1>
											<h1 className='font-semibold'>
												Total {currency} {Number(totalAmount).toFixed(2)}
											</h1>
										</div>
									</div>
								);
							})}
						</div>
					</>

					<div className='flex justify-end'>
						<Button
							title='CONTINUE'
							onClick={() => {
								setShowGratuityModal(false);
							}}
							variant='contained'
							color='primary'
						/>
					</div>

					{showCancelOrderModal && (
						<Modal
							title='Cancel Order'
							content={
								<>
									<h1>Are you sure you want to cancel this order ?</h1>
									<div className='flex justify-end gap-5'>
										<Button
											title='No'
											onClick={() => setShowCancelOrderModal(false)}
											variant='outlined'
											color='primary'
										/>
										<Button
											title='Yes'
											onClick={() => cancelOrder()}
											variant='contained'
											color='primary'
										/>
									</div>
								</>
							}
							modalId='Cancel Order'
							open={showCancelOrderModal}
							setOpen={setShowCancelOrderModal}
							size='sm'
						/>
					)}
				</div>
			}
		/>
	);
}

export default Gratuity;

export interface GratuityProps {
	showGratuityModal: boolean;
	setShowGratuityModal: any;
}
