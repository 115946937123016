import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'components/Spinner';
import queryString from 'query-string';
import LeftStickyBar from 'views/Home/LeftStickyBar';
import PaypalResultHandler from 'views/PaypalResultHandler';
import { getUser, getUserInCustomerScreen } from 'api/AxiosManager';
import { SetUser } from 'reducers/POS/PosActions';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import SessionExpired from './components/SessionExpired';
import Header from './components/Header';
import { RootStore } from '../reducers';

export function PrivateRoute({ component: Component, ...props }: any) {
	const [readyToRender, setReadyToRender] = useState(false);
	const { loading } = useSelector((state: RootStore) => state.alertsReducer);
	const [isUserPresent, setIsUserPresent] = useState(false);
	const { search } = useLocation();
	const { paypalResultHandler, logintoken, organizationId }: any =
		queryString?.parse(search);
	const dispatch: any = useDispatch();
	const checkUser = async () => {
		try {
			dispatch(showLoading());
			if (paypalResultHandler) {
				const user = await getUserInCustomerScreen({ organizationId });
				dispatch(SetUser(user));
				setIsUserPresent(true);
				setReadyToRender(true);
			} else if (logintoken) {
					localStorage.setItem('logintoken', logintoken);
					const user = await getUser();
					dispatch(SetUser(user));
					setIsUserPresent(true);
					setReadyToRender(true);
				} else if (
					localStorage.getItem('logintoken') &&
					localStorage.getItem('logintoken').length !== 0
				) {
					const user = await getUser();
					dispatch(SetUser(user));
					setIsUserPresent(true);
					setReadyToRender(true);
				} else {
					setIsUserPresent(false);
					setReadyToRender(true);
				}
			dispatch(hideLoading());
		} catch (error) {
			console.log(error);
			dispatch(hideLoading());
			setIsUserPresent(false);
			setReadyToRender(true);
		}
	};
	useEffect(() => {
		checkUser();
	}, []);

	return window.navigator.onLine ? (
		<>
			{loading && <Spinner />}
			{readyToRender && (
				<div>
					{isUserPresent ? (
						<main>
							{paypalResultHandler?.length > 0 ? (
								<PaypalResultHandler />
							) : (
								<div className='flex h-screen w-full p-3 gap-3 bg-gray-200'>
									<LeftStickyBar />

									<div className='w-full relative overflow-y-scroll pr-3'>
										{window.location.pathname !== '/' && <Header />}
										<Component {...props} />
									</div>
								</div>
							)}
						</main>
					) : (
						<SessionExpired />
					)}
				</div>
			)}
		</>
	) : (
		<div className='flex items-center justify-center h-screen flex-col gap-10'>
			<h1 className='text-4xl font-semibold '>No Internet Connection</h1>
		</div>
	);
}

export default PrivateRoute;
