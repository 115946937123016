import React, { useEffect } from 'react';
import Modal from 'storybook-mui/build/components/Modal';
import { QRCodeSVG } from 'qrcode.react';
import { GetOrderStatus } from 'firebaseApis/orders';
import { IPos } from 'reducers/POS';
import { useDispatch, useSelector } from 'react-redux';
import { IOrderData } from 'interfaces';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { SetPosView, SetTotalAmountPaid } from 'reducers/POS/PosActions';
import { AuthorizeAndContinuePaypalPayment } from 'firebaseApis/payments';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';

function PaypalModal({
	showPaypalModal,
	setShowPaypalModal,
	selectedPaypalPayment,
	setSelectedPaypalPayment,
	selectedPaymentModes,
	setSelectedPaymentModes,
	setAmountPayedThroughAllPaymentModes,
	amountPayedThroughAllPaymentModes,
	totalAmountPaid,
	totalAmountDue,
	setTotalAmountDue,
}: PaypalModalProps) {
	const { orderId, currency }: IPos = useSelector(
		(state: any) => state.posReducer
	);
	const dispatch: any = useDispatch();
	let unSub = () => {};
	const getOrderStatus = async () => {
		try {
			dispatch(showLoading());
			unSub = GetOrderStatus(orderId, async (snapshot: any) => {
				const orderData: IOrderData = snapshot.data();

				const paymentDone =
					orderData?.payment_details?.find(
						(payment) => payment.refId === selectedPaypalPayment.refId
					) || null;

				if (paymentDone.status === 'authorized') {
					setSelectedPaypalPayment({
						...selectedPaypalPayment,
						status: 'AUTHORIZED',
					});
					dispatch(showLoading())
					const finalResponse = await AuthorizeAndContinuePaypalPayment({
						orderId,
						transactionId: paymentDone.transactionid,
						payerId: paymentDone.payerId,
						refId: paymentDone.refId,
					});
                    dispatch(hideLoading())
					if (finalResponse?.data?.payment_status === 'success') {
						Toast({ title: 'Payment Success', type: ToastTypes.SUCCESS });
						setShowPaypalModal(false);
						const newPaymentModes = [...selectedPaymentModes];
						newPaymentModes[selectedPaypalPayment.index].status = 'SUCCESS';
						setSelectedPaymentModes(newPaymentModes);

						// updating total amount payed for paypal
						setAmountPayedThroughAllPaymentModes({
							...amountPayedThroughAllPaymentModes,
							paypal:
								amountPayedThroughAllPaymentModes.paypal + selectedPaypalPayment.amount,
						});

						// updating total amount payed
						dispatch(
							SetTotalAmountPaid(totalAmountPaid + selectedPaypalPayment.amount)
						);
						setTotalAmountDue(totalAmountDue - selectedPaypalPayment.amount);
						if (totalAmountDue - selectedPaypalPayment.amount < 0.1) {
							dispatch(SetPosView('PAYMENT_SUCCESSFULL'));
						}
					}
				}
			});
			dispatch(hideLoading());
			return unSub;
		} catch (error) {
			dispatch(hideLoading());
			return error;
		}
	};

	useEffect(() => {
		if (orderId !== '') {
			getOrderStatus();
		}
		return () => unSub();
	}, [orderId]);
	return (
		<Modal
			title='Scan and Pay with Paypal'
			content={
				<div className='flex flex-col gap-5 items-center justify-center'>
					<QRCodeSVG
						value={selectedPaypalPayment?.paymentURL}
						height={240}
						width={240}
					/>
					<div className='font-semibold ml-10'>
						<h1>Order Id : {orderId}</h1>
						<h1>
							Amount : {currency} {selectedPaypalPayment?.amount}
						</h1>
						<h1>Payment Status : {selectedPaypalPayment.status}</h1>
						<h1>Reference Id : {selectedPaypalPayment.refId}</h1>
						<h1>Transaction ID : {selectedPaypalPayment?.transactionId || 'NA'}</h1>
						<h1
							className='text-info-500 underline text-xl mt-5 cursor-pointer'
							onClick={() => getOrderStatus()}
						>
							Check Payment Status Manually
						</h1>
					</div>
				</div>
			}
			modalId='Paypal Payment'
			open={showPaypalModal}
			setOpen={() => setShowPaypalModal(false)}
			size='sm'
			closeIcon
		/>
	);
}

export default PaypalModal;

export interface PaypalModalProps {
	showPaypalModal: boolean;
	setShowPaypalModal: React.Dispatch<React.SetStateAction<boolean>>;
	selectedPaypalPayment: any;
	setSelectedPaypalPayment: React.Dispatch<React.SetStateAction<any>>;
	selectedPaymentModes: any;
	setSelectedPaymentModes: React.Dispatch<React.SetStateAction<any>>;
	setAmountPayedThroughAllPaymentModes: any;
	amountPayedThroughAllPaymentModes: any;
	totalAmountPaid: number;
	totalAmountDue: number;
	setTotalAmountDue: React.Dispatch<React.SetStateAction<number>>;
}
