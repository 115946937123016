import Toast, { ToastTypes } from 'components/ToastNotify';
import { RedeemAffiliateReferalPoints } from 'firebaseApis/affiliates';
import React from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import Button from 'storybook-mui/build/components/Button';
import Modal from 'storybook-mui/build/components/Modal';
import NumberField from 'storybook-mui/build/components/NumberField';
import TextAreaField from 'storybook-mui/build/components/MuiTextArea';

function RedeemPointsModal({
	showRedeemPointsModal,
	setShowRedeemPointsModal,
	affiliate,
	getData = () => {},
}: IRedeemPointsModalProps) {
	const [pointsToRedeem, setPointsToRedeem] = React.useState(10);
	const [notes = '', setNotes] = React.useState('');
	const dispatch: any = useDispatch();
	const redeemPoints = async () => {
		try {
			dispatch(showLoading())
			const response = await RedeemAffiliateReferalPoints(affiliate, pointsToRedeem  , notes);
			if (response.success) {
				Toast({ type: ToastTypes.SUCCESS, title: 'Points redeemed successfully' });
				getData();
				setShowRedeemPointsModal(false);
			} else {
				Toast({ type: ToastTypes.ERROR, title: 'Internal Error' });
			}
			dispatch(hideLoading())
		} catch (error) {
			Toast({ type: ToastTypes.ERROR, title: 'Error redeeming points' });
			dispatch(hideLoading());
		}
	};

	return (
		<Modal
			title='Redeem Points'
			content={
				<>
					<div className='flex flex-col gap-3'>
						<div className='font-semibold bg-info-200 p-5 rounded'>
							<h1>
								Affiliate Name : {affiliate?.First_Name} {affiliate?.Last_Name}
							</h1>
							<h1>Avaiable Points : {affiliate?.LoyaltyPoints?.Available}</h1>
						</div>
						<NumberField
							name='points'
							fieldId='points'
							onChange={(e) => setPointsToRedeem(e.target.value)}
							value={pointsToRedeem}
							labelName='Points'
							placeholder='Enter Points'
							max={affiliate?.LoyaltyPoints?.Available}
						/>
						<TextAreaField
							name='Notes'
							fieldId='Notes'
							onChange={(e) => {
								setNotes(e.target.value);
							}}
							value={notes}
							placeholder='Enter Notes'
							labelName='Notes'
						/>
						{affiliate?.LoyaltyPoints?.Available < pointsToRedeem && (
							<p className='text-red-500'>
								Points to redeem should be less than available points
							</p>
						)}
						<div className='flex justify-end gap-5'>
							<Button
								title='Close'
								onClick={() => setShowRedeemPointsModal(!showRedeemPointsModal)}
								variant='outlined'
								color='info'
							/>
							<Button
								title='Redeem'
								onClick={() => redeemPoints()}
								variant='contained'
								color='info'
								disabled={affiliate?.LoyaltyPoints?.Available < pointsToRedeem}
							/>
						</div>
					</div>
				</>
			}
			modalId='affiliateOrders'
			open={showRedeemPointsModal}
			setOpen={() => setShowRedeemPointsModal(false)}
			closeIcon
			size='sm'
		/>
	);
}

export default RedeemPointsModal;

export interface IRedeemPointsModalProps {
	showRedeemPointsModal: boolean;
	setShowRedeemPointsModal: (value: boolean) => void;
	affiliate: any;
	getData: () => void;
}
