import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from 'reducers';

import { IPos } from 'reducers/POS';
import {
	SetGratuityPercentage,
	SetTotalAmountWithTaxAndGratuity,
	SetFinalAmountAfterAllDiscounts,
	SetPosView,
} from 'reducers/POS/PosActions';
import Button from 'storybook-mui/build/components/Button';
import Modal from 'storybook-mui/build/components/Modal';

function Gratuity() {
	const {
		subTotal,
		gratuityPercentage,

		currency,
		totalAmountWithTaxWithoutGratuity,
	}: IPos = useSelector((store: RootStore) => store.posReducer);
	const { view } = useSelector((store: RootStore) => store.posReducer);
	const dispatch: any = useDispatch();
	const gratuityPrices = [
		{
			label: '0%',
			percentage: 0,
			text: 'No Gratuity',
		},
		{
			label: '15%',
			percentage: 15,
			text: 'Good Service',
		},
		{
			label: '18%',
			percentage: 18,
			text: 'Better Service',
		},
		{ label: '20%', percentage: 20, text: 'Best Service' },
		{
			label: '25%',
			percentage: 25,
			text: 'Outstanding',
		},
	];

	useEffect(() => {
		dispatch(SetGratuityPercentage(0));
		dispatch(SetTotalAmountWithTaxAndGratuity(totalAmountWithTaxWithoutGratuity));
	}, []);

	return (
		<Modal
			size='md'
			title=''
			closeIcon
			modalId=''
			open={view === 'GRATUITY'}
			setOpen={() => {
				dispatch(SetPosView('HOME'));
			}}
			content={
				<div className='flex flex-col space-y-5  text-gray-600'>
					<>
						<h1 className='text-2xl text-center text-info-500 font-semibold uppercase'>
							Suggested Gratuities
						</h1>
						<div className='grid justify-between gap-5 grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3'>
							{gratuityPrices.map((item, index) => {
								const gratuity = (item.percentage / 100) * subTotal;
								const totalAmount = gratuity + totalAmountWithTaxWithoutGratuity;

								return (
									<div key={index}>
										<div
											className={`w-full cursor-pointer gap-2 flex flex-col bg-white shadow border border-gray-300 p-5 ${
												gratuityPercentage === item.percentage
													? 'border-info-500 border-4'
													: ''
											}`}
											key={index}
											onClick={() => {
												dispatch(SetGratuityPercentage(Number(item.percentage)));
												dispatch(SetTotalAmountWithTaxAndGratuity(Number(totalAmount)));
												dispatch(SetFinalAmountAfterAllDiscounts(Number(totalAmount)));
											}}
										>
											<h1 className='text-3xl font-semibold text-leapbrown-500'>
												{item.label}
											</h1>
											<h1>
												Gratuity Amount : {currency} {gratuity.toFixed(2)}
											</h1>
											<h1 className='font-semibold'>
												Total Amount : {currency} {Number(totalAmount).toFixed(2)}
											</h1>
											<hr />
											<h1 className='uppercase'>{item.text}</h1>
										</div>
									</div>
								);
							})}
							<div className='flex justify-end items-end gap-5'>
								<Button
									variant='contained'
									color='secondary'
									onClick={() => {
										dispatch(SetPosView('PAYMENT'));
									}}
									title='CONTINUE'
								/>
							</div>
						</div>
					</>
				</div>
			}
		/>
	);
}

export default Gratuity;
