import { GridColDef } from '@mui/x-data-grid-pro';
import MUIGridGloabl from 'components/MuiGrid/MUIGlobalGrid';
import ToggleButtonGroups from 'components/ToggleButtonGroups';
import {
	GetAffiliateOrders,
	GetAffiliatePointsRedemtions,
} from 'firebaseApis/affiliates';
import moment from 'moment';
import React, { useEffect } from 'react';
import Modal from 'storybook-mui/build/components/Modal';
import { useHistory } from 'react-router-dom';
import { IOrderData } from 'interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { IPos } from 'reducers/POS';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';

function PointsHistory({
	showAffiliatePointsHistory,
	setShowAffiliatePointsHistory,
	selectedAffiliate,
}: PointsHistoryProps) {
	const [pointsHistoryView, setPointsHistoryView] = React.useState<
		'earned' | 'redeemed'
	>('earned');
	const [affiliateOrdersData, setAffiliateOrdersData] = React.useState<any>([]);
	const { currency }: IPos = useSelector((state: any) => state.posReducer);
	const [affiliatePointsRedemptionsData, setAffiliatePointsRedemptionsData] =
		React.useState<any>([]);
	const history = useHistory();
	const dispatch: any = useDispatch();
	const affiliateOrdersColumns: GridColDef[] = [
		{
			field: 'orderid',
			headerName: 'Order ID',
			minWidth: 150,
			renderCell: (params: any) => (
				<h1
					className='underline cursor-pointer'
					onClick={() => history.push(`/reports/order/${params.row.orderid}`)}
				>
					{params.value}
				</h1>
			),
		},
		{
			field: 'orderDate',
			headerName: 'Order Date',
			minWidth: 200,
			renderCell: (params) => (
				<h1>{moment(params?.row?.created_at).format('MM-DD-YYYY hh:mm A')}</h1>
			),
		},
		{
			field: 'Items Total',
			headerName: 'Items Total',
			minWidth: 150,
			renderCell: (params) => {
				const orderData: IOrderData = params?.row;
				const itemsTotal = orderData.items.reduce(
					(acc: number, item: any) => acc + item.UnitPrice * item.Quantity,
					0
				);
				return (
					<h1>
						{currency}
						{itemsTotal}
					</h1>
				);
			},
		},
		{
			field: 'Gratuity',
			headerName: 'Gratuity',
			minWidth: 100,
			renderCell: (params) => (
				<>
					{' '}
					{currency} {params.row.amount.Tip}
				</>
			),
		},
		{
			field: 'Tax',
			headerName: 'Tax',
			minWidth: 100,
			renderCell: (params) => (
				<>
					{' '}
					{currency} {params.row.amount.Tax}
				</>
			),
		},
		{
			field: 'Discounts',
			headerName: 'Discounts',
			minWidth: 250,
			renderCell: (params) => (
				<div className='flex flex-col p-2'>
					<h1>
						Affiliate Code Discount: {currency}{' '}
						{params.row.amount.AffiliateCustomerDiscount}
					</h1>
					<h1>
						Coupon Discount: {currency} {params.row.amount.CouponDiscount}
					</h1>
				</div>
			),
		},
		{
			field: 'Total',
			headerName: 'Total',
			minWidth: 100,
			renderCell: (params) => (
				<>
					{currency} {params.row.amount.Total}
				</>
			),
		},
		{
			field: 'pointsEarned',
			headerName: 'Referal Points For Affiliate',
			minWidth: 250,
			renderCell: (params) => <>{params?.row?.affiliate?.pointsEarned} Points</>,
		},
	];

	const affiliatePointsRedeemedColumns: GridColDef[] = [
		{
			field: 'orderId',
			headerName: 'Date',
			flex: 1,
			renderCell: (params) => (
				<h1>{moment(params?.row?.date?.__time__).format('MM-DD-YYYY hh:mm A')}</h1>
			),
		},
		{
			field: 'staffId',
			headerName: 'Staff ID',
			flex: 1,
		},
		{
			field: 'pointsRedeemed',
			headerName: 'Points Redeemed',
			flex: 1,
		},
		{
			field: 'notes',
			headerName: 'Notes',
			flex: 1,
		},
	];
	const getData = async () => {
		try {
			dispatch(showLoading());
			const result = await GetAffiliateOrders(selectedAffiliate.id);
			setAffiliateOrdersData(result);
			const redemptions = await GetAffiliatePointsRedemtions(selectedAffiliate.id);
			setAffiliatePointsRedemptionsData(redemptions);
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			setAffiliateOrdersData([]);
			setAffiliatePointsRedemptionsData([]);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<Modal
			title='Affiliate Points History'
			content={
				<>
					<div className='flex my-2'>
						<ToggleButtonGroups
							onChange={(event, value) => {
								if (value) {
									setPointsHistoryView(value);
								}
							}}
							value={pointsHistoryView}
							buttons={[
								{
									content: 'Earned Points',
									value: 'earned',
								},
								{
									content: 'Redeemed Points',
									value: 'redeemed',
								},
							]}
							size='small'
							color='info'
						/>
					</div>
					{pointsHistoryView === 'earned' && (
						<MUIGridGloabl
							columns={affiliateOrdersColumns}
							dataSource={affiliateOrdersData}
							rowCount={affiliateOrdersData.length}
						/>
					)}

					{pointsHistoryView === 'redeemed' && (
						<MUIGridGloabl
							columns={affiliatePointsRedeemedColumns}
							dataSource={affiliatePointsRedemptionsData || []}
							rowCount={affiliateOrdersData.length}
						/>
					)}
				</>
			}
			modalId='affiliateOrders'
			open={showAffiliatePointsHistory}
			setOpen={() => setShowAffiliatePointsHistory(false)}
			size='lg'
			closeIcon
		/>
	);
}

export default PointsHistory;

export interface PointsHistoryProps {
	selectedAffiliate: any;
	showAffiliatePointsHistory: boolean;
	setShowAffiliatePointsHistory: (value: boolean) => void;
}
