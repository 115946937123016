import { getClientDB, getOrganisationIdFirebase } from 'api/AxiosManager';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import moment from 'moment';
import store from 'reducers';
import { ICoupon } from 'interfaces';

export const GetAllCoupons = async () => {
	try {
		const q = query(
			collection(getClientDB(), `/${getOrganisationIdFirebase()}/POS/Coupons`)
		);
		const querySnapshot = await getDocs(q);
		const coupons: any = [];
		querySnapshot.forEach((d) => {
			coupons.push({ ...d.data(), id: d.id });
		});

		return { success: true, data: coupons };
	} catch (error) {
		return { success: false, message: error.message };
	}
};

export const CheckCouponValidity = async (couponCode: string) => {
	try {
		const qry = query(
			collection(getClientDB(), `${getOrganisationIdFirebase()}/POS/Coupons`),
			where('couponCode', '==', couponCode)
		);
		const resultSnapshots = await getDocs(qry);

		if (resultSnapshots.docs.length > 0) {
			const resultArray: any = resultSnapshots.docs.map((document) => ({
				...document.data(),
				id: document.id,
			}));
			const coupon: ICoupon = resultArray[0];
			// status check
			if (coupon.status !== 'active') {
				return {
					success: false,
					message: 'Coupon is not active',
				};
			}

			// expiry check
			if (coupon.duration === 'limited') {
				if (moment(coupon.endDate).isBefore(moment().format('YYYY-MM-DD'))) {
					return {
						success: false,
						message: 'Coupon has expired',
					};
				}
			}

			// mimimum order amount check
			if (
				coupon.minimumOrderAmount >
				store.getState().posReducer.subTotal
			) {
				return {
					success: false,
					message: `Minimum order amount for this coupon is ${coupon.minimumOrderAmount}`,
				};
			}

			// applied on check
			if (coupon.couponApplicableFor === 'customers') {
				const { customer } = store.getState().posReducer;
				if (customer.id === 'GUEST') {
					return {
						success: false,
						message: 'This coupon is not applicable for the guests',
					};
				}

				// get coupon redemption history
				const q = query(
					collection(getClientDB(), `/${getOrganisationIdFirebase()}/POS/orders`),
					where('customer_id', '==', customer.id),
					where('customer_id', '!=', 'GUEST'),
					where('coupon.id', '==', coupon.id)
				);
				const couponsRedeemed = await getDocs(q);

				// check if customer is associated with coupon check number of times used
				if (couponsRedeemed.size >= coupon.maximumRedemptionsForCustomer) {
					return {
						success: false,
						message: 'Customer has reached maximum redemptions for this coupon',
					};
				}
			}
			return {
				success: true,
				data: coupon,
				message: 'Coupon applied successfully',
			};
		}

		return {
			success: false,
			message: 'Invalid Coupon',
		};
	} catch (error) {
		return {
			success: false,
			message: 'Internal Server Error',
		};
	}
};

export const GetCouponRedemptions = async (couponId) => {
	try {
		const q = query(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/POS/CouponsOrders`
			),
			where('couponId', '==', couponId)
		);
		const querySnapshot = await getDocs(q);
		const coupons: any = [];
		querySnapshot.forEach((d) => {
			coupons.push({ ...d.data(), id: d.id });
		});

		return { success: true, data: coupons };
	} catch (error) {
		return { success: false, message: error.message };
	}
};

export const GetAllOrdersOfCoupon = async (couponId) => {
	try {
		const q = query(
			collection(getClientDB(), `/${getOrganisationIdFirebase()}/POS/orders`),
			where('coupon.id', '==', couponId),
			orderBy('created_at', 'desc')
		);
		const querySnapshot = await getDocs(q);
		const coupons: any = [];
		querySnapshot.forEach((d) => {
			coupons.push({ ...d.data(), id: d.id });
		});

		return { success: true, data: coupons };
	} catch (error) {
		return { success: false, message: error.message };
	}
};
