import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import Tooltip from 'storybook-mui/build/components/Tooltip';
import { GetOrganizationInfoById } from 'firebaseApis/locations';
import Toast, { ToastTypes } from 'components/ToastNotify';
import Leap360Logo from 'assets/images/leap360.png';

const LeftStickyBar = () => {
	const [open, setOpen] = React.useState(false);
	const history = useHistory();
	const [orgData, setOrgData] = React.useState(null);
	const getOrgData = async () => {
		GetOrganizationInfoById()
			.then((response) => {
				setOrgData(response);
			})
			.catch(() => {
				Toast({ type: ToastTypes.ERROR, title: 'Error getting organization data' });
			});
	};
	useEffect(() => {
		getOrgData();
	}, []);
	const menuItems = [
		{
			title: 'Home',
			path: '/',
			icon: IconTypes.Home,
			onClick: () => history.push('/'),
		},
		{
			title: 'Reports',
			path: '/reports',
			icon: IconTypes.Widgets,
			onClick: () => history.push('/reports'),
		},
		{
			title: 'Customers',
			path: '/customers',
			icon: IconTypes.Users,
			onClick: () => history.push('/customers'),
		},

		{
			title: 'Logout',
			path: '/logout',
			icon: IconTypes.Logout,
			onClick: () => {
				history.push('/');
				localStorage.removeItem('logintoken');
				history.go(0);
			},
		},
	];

	return (
		<div className='relative shadow-md border border-gray-300 rounded-md bg-white flex flex-col py-3'>
			<div className='flex flex-col'>
				{orgData && (
					<div>
						{orgData.LogoUrl !== '' && (
							<img src={orgData?.Logo_Url} alt='' className='h-12 w-24' />
						)}
					</div>
				)}
			</div>
			<div className='flex flex-col gap-7 p-5 justify-center mt-20'>
				{menuItems.map((item, index) => (
						<div
							className={`cursor-pointer flex justify-start items-center gap-3 ${
								window.location.pathname === item.path
									? 'text-info-500 border-2 px-2 pt-1 rounded border-info-500'
									: 'text-gray-dark px-2'
							}`}
							key={index}
							onClick={item.onClick}
						>
							<Tooltip content={item.title} direction='right' key={index}>
								<Icon icon={item.icon} />
							</Tooltip>
							{open && <span className='mb-2'>{item.title}</span>}
						</div>
					))}
			</div>
			<Icon
				icon={
					open
						? IconTypes.KeyboardDoubleArrowLeft
						: IconTypes.KeyboardDoubleArrowRight
				}
				onClick={() => setOpen(!open)}
				className='text-white text-xl font-extrabold rounded-r-full cursor-pointer mt-20 z-50 p-2 bg-gray-700 w-max'
			/>

			<div className='absolute bottom-2 flex items-center justify-center flex-col p-2'>
				<h1 className='text-gray-500 text-xs font-semibold'>Powered By</h1>
				<img src={Leap360Logo} alt='' className='h-8 w-16' />
			</div>
		</div>
	);
};

export default LeftStickyBar;
