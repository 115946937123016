import React, { useEffect, useState } from 'react';
import { SetCustomer, SetSideBarStatus } from 'reducers/POS/PosActions';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import { IPos } from 'reducers/POS';
import IconButtonComponent from 'storybook-mui/build/components/IconButton';
import LaneItems from './LaneItems';
import Footer from './Footer';
import SelectCustomer from '../Payment/SelectCustomer';

function Lane() {
	const { orderId, laneItems, customer }: IPos = useSelector(
		(state: any) => state.posReducer
	);
	const [showCustomerSelectionModal, setShowCustomerSelectionModal] =
		useState(false);
	const [openCheckout, setOpenCheckout] = React.useState(false);
	const dispatch: any = useDispatch();

	useEffect(() => {
		if (openCheckout) {
			dispatch(SetSideBarStatus('opened'));
		} else {
			dispatch(SetSideBarStatus('closed'));
		}
	}, [dispatch, openCheckout]);

	useEffect(() => {
		if (laneItems.length) {
			setOpenCheckout(true);
		} else {
			setOpenCheckout(false);
		}
	}, [laneItems]);

	return (
		<div className='relative'>
			<Drawer
				sx={{
					width: openCheckout ? '420px' : '0',
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: openCheckout ? '420px' : '0',
						boxSizing: 'border-box',
					},
				}}
				variant='persistent'
				anchor='right'
				open={openCheckout}
			>
				<div className='bg-white h-screen'>
					<div className='sticky'>
						{openCheckout && (
							<div className='absolute right-10'>
								<IconButtonComponent
									id='drawer-open'
									ariaLabel='drawer-open'
									content={
										<Icon
											icon={IconTypes.KeyboardDoubleArrowRight}
											className='text-gray-darkest '
										/>
									}
									edge='end'
									color='secondary'
									onClick={() => setOpenCheckout(false)}
								/>
							</div>
						)}
						<div className='min-h-screen flex flex-col gap-6 justify-between'>
							<div className='flex flex-col gap-4 pt-10 px-5'>
								{customer.id !== 'GUEST' && (
									<div className='flex justify-between border mx-2 py-2'>
										<h1 className='text-gray-700 mx-5'>
											<b>
												Customer : {customer?.First_Name} {customer?.Last_Name}
											</b>
										</h1>
										<Icon
											onClick={() =>
												dispatch(
													SetCustomer({
														id: 'GUEST',
													})
												)
											}
											icon={IconTypes.Delete}
											className='text-gray-700 mr-5 cursor-pointer'
										/>
									</div>
								)}

								{orderId !== '' && laneItems.length > 0 && (
									<h1 className='text-gray-700 ml-5  text-lg'>Order ID - {orderId}</h1>
								)}
								<LaneItems />
							</div>

							<Footer />
						</div>
					</div>
				</div>
			</Drawer>

			{!openCheckout && (
				<Icon
					icon={IconTypes.KeyboardDoubleArrowLeft}
					onClick={() => setOpenCheckout(true)}
					className='text-white text-xl font-extrabold  rounded-l-full cursor-pointer absolute right-0 top-16 z-50 p-2 bg-gray-700  hover:p-4 transition-all'
				/>
			)}

			{showCustomerSelectionModal && (
				<SelectCustomer
					showCustomerSelectionModal={showCustomerSelectionModal}
					setShowCustomerSelectionModal={setShowCustomerSelectionModal}
				/>
			)}
		</div>
	);
}

export default Lane;

export interface ILaneProps {
	showLane: boolean;
	setShowLane: (showLane: boolean) => void;
}
