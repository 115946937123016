import React from 'react';
import Tabs from 'storybook-mui/build/components/Tabs';
import Analytics from './Analytics';
import Coupons from './Coupons';
import OrdersList from './Orders/OrdersList';
import TopSelling from './TopSelling';
import Affiliate from './Affiliate/index';

function Reports() {
	const [selectedTab, setSelectedTab] = React.useState(0);
	return (
		<div>
			<Tabs
				selectedIndex={selectedTab}
				setSelectedIndex={setSelectedTab}
				TabsTiles={['Orders', 'Analytics', 'Top Selling', 'Affiliate', 'Coupons']}
				TabsContent={[
					<OrdersList />,
					<Analytics />,
					<TopSelling />,
					<Affiliate />,
					<Coupons />,
				]}
			/>
		</div>
	);
}

export default Reports;
