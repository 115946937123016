import * as Yup from 'yup';

export const creditCardSchema = Yup.object().shape({
	cardNumber: Yup.string()
		.required('Card number is required')
		.min(15, 'Card number must be greater than 15 numbers'),
	expiryMonth: Yup.string()
		.required('Expiry month is required')
		.test('expiryYear', 'Expiry Month is invalid', function (value) {
			const currentYear = new Date().getFullYear();
			const enteredYear = parseInt(this.parent.expiryYear, 10);
			const currentMonth = new Date().getMonth() + 1;
			const enteredMonth = parseInt(value, 10);

			if (enteredYear === currentYear && enteredMonth < currentMonth) {
				return false;
			}
			return true;
		})
		.max(2, 'Expiry month must be 2 digits'),
	expiryYear: Yup.string()
		.required('Expiry year is required')
		.test('expiryYear', 'Expiry year is invalid', (value) => {
			const currentYear = new Date().getFullYear();
			const enteredYear = parseInt(value, 10);
			if (enteredYear < currentYear) {
				return false;
			}
			return true;
		})
		.max(4, 'Expiry year must be 4 digits'),
	cvv: Yup.string().required('CVV is required').max(3, 'CVV must be 3 digits').min(3, 'CVV must be 3 digits'),
	firstName: Yup.string().required('First name is required'),
	lastName: Yup.string().required('Last name is required'),
	zip: Yup.string().required('Zip is required'),
});
