import { GetAllCountries, SaveContact } from 'firebaseApis/contacts';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Button from 'storybook-mui/build/components/Button';
import InputTextField from 'storybook-mui/build/components/TextField';
import AutocompleteField from 'storybook-mui/build/components/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { SetCoupon, SetCustomer, SetAffiliate } from 'reducers/POS/PosActions';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { ChangeCustomerInOrder } from 'firebaseApis/orders';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { contactValidationSchema } from './validations';

function AddNew({ setModelView, setShowCustomerSelectionModal }: IAddNew) {
	const dispatch: any = useDispatch();
	const [countryCodes, setCountryCodes] = useState<any>([]);
	const { orderId } = useSelector((state: any) => state.posReducer);
	const customerForm = useFormik({
		initialValues: {
			First_Name: '',
			Last_Name: '',
			Email_Id: '',
			Phone_Number: '',
			Country_Code: '+1',
		},
		onSubmit: async () => {
			try {
				dispatch(showLoading());
				const response = await SaveContact(customerForm.values);
				dispatch(SetCustomer(response.data));
				dispatch(SetCoupon(null))
				dispatch(SetAffiliate(null))
				if (orderId) {
					await ChangeCustomerInOrder(orderId, response.data);
				}
				setShowCustomerSelectionModal(false);
				Toast({ title: 'Contact added successfully', type: ToastTypes.SUCCESS });
				dispatch(hideLoading());
			} catch (error) {
				dispatch(hideLoading());
				Toast({ title: 'Somethinng went wrong', type: ToastTypes.ERROR });
			}
		},
		validationSchema: contactValidationSchema,
		validateOnChange: true,
	});

	const getData = async () => {
		try {
			dispatch(showLoading());
			const response = await GetAllCountries();
			const tempCountryCodes: any = [];

			response.forEach((obj: any) => {
				tempCountryCodes.push({
					value: `${obj.phone_code}`,
					label: `${obj.phone_code}`,
					renderLabel: (
						<div className='flex items-center'>
							<span className='h-8 -mb-2'>
								<img src={obj.flag_svg_url} className='h-6 w-6 mx-2' alt='' />
							</span>
							<span className='ml-1'>{`${obj.country_name}`} </span>
							<span className='ml-1'>{`${obj.phone_code}`} </span>
						</div>
					),
				});
			});
			setCountryCodes(tempCountryCodes);

			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: 'Somethinng went wrong', type: ToastTypes.ERROR });
		}
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<div className='flex flex-col space-y-5'>
			<form
				className='flex flex-col space-y-5 items-start mt-5'
				onSubmit={customerForm.handleSubmit}
			>
				<InputTextField
					name='First_Name'
					onChange={customerForm.handleChange}
					value={customerForm.values.First_Name}
					labelName='First Name'
					fieldId='First_Name'
					valid={customerForm.errors.First_Name === undefined}
					errorMessage={customerForm.errors.First_Name}
				/>
				<InputTextField
					name='Last_Name'
					onChange={customerForm.handleChange}
					value={customerForm.values.Last_Name}
					labelName='Last_Name'
					fieldId='Last_Name'
					valid={customerForm.errors.Last_Name === undefined}
					errorMessage={customerForm.errors.Last_Name}
				/>
				<InputTextField
					name='Email_Id'
					onChange={customerForm.handleChange}
					value={customerForm.values.Email_Id}
					labelName='Email Id'
					fieldId='Email_Id'
				/>
				<div className='flex gap-5 w-full'>
					<div className='w-1/2'>
						{countryCodes.length > 0 && (
							<AutocompleteField
								{...(customerForm.values.Country_Code && {
									value: countryCodes.find(
										(item: any) => item.value === customerForm.values.Country_Code
									),
								})}
								onChange={(event: any, option: any) =>
									customerForm.setFieldValue('Country_Code', option.value)
								}
								options={countryCodes}
								optionsType='render'
								fieldId='countryCode'
								placeholder='Country code'
							/>
						)}
					</div>

					<div className='w-full'>
						<InputTextField
							name='Phone_Number'
							type='number'
							onChange={customerForm.handleChange}
							value={customerForm.values.Phone_Number}
							labelName='Phone Number'
							fieldId='Phone_Number'
						/>
					</div>
				</div>

				<div className='flex justify-end w-full space-x-5'>
					<Button
						title='Cancel'
						variant='outlined'
						color='inherit'
						onClick={() => setModelView('SELECTION')}
					/>
					<Button
						disabled={Object.keys(customerForm.errors).length > 0}
						title='ADD'
						variant='contained'
						color='primary'
						type='submit'
					/>
				</div>
			</form>
		</div>
	);
}

export default AddNew;

export interface IAddNew {
	setModelView: any;
	setShowCustomerSelectionModal: any;
}
