import { CheckAffiliateCodeValidity } from 'firebaseApis/affiliates';
import Toast, { ToastTypes } from 'components/ToastNotify';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	SetcustomerDiscountAmountFromAffiliateCode,
	SetFinalAmountAfterAllDiscounts,
	SetAffiliate,
} from 'reducers/POS/PosActions';
import Button from 'storybook-mui/build/components/Button';
import Modal from 'storybook-mui/build/components/Modal';
import InputTextField from 'storybook-mui/build/components/TextField';
import { IAffiliateConfig } from 'interfaces';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import ErrorMessage from 'components/ErrorMessage';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { IPos } from 'reducers/POS';

function AddAffiliate({
	showAddAffiliateModal,
	setShowAddAffiliateModal,
	setTotalAmountDue,
}: AddAffiliateProps) {
	const [errorMessage, setErrorMesssage] = useState<string>('');
	const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
	const [affiliateInfo, setAffiliateInfo] = useState<any>();
	const [affiliateConfig, setAffiliateConfig] = useState<IAffiliateConfig>(null);
	const [affiliateId, setAffiliateId] = useState('');
	const dispatch: any = useDispatch();
	const {
		affiliate,
		subTotal,
		gratuityAmount,
		taxAmount,
		totalAmountPaid,
		totalAmountWithTaxAndGratuity,
	}: IPos = useSelector((state: any) => state.posReducer);

	const getAffiliateInfo = async () => {
		try {
			dispatch(showLoading());
			const response: any = await CheckAffiliateCodeValidity(affiliateId);
			if (response.success) {
				setAffiliateInfo(response.data.Affiliate);
				setAffiliateConfig(response.data.AffiliateConfig);
				Toast({ type: ToastTypes.SUCCESS, title: response.message });
			} else {
				setErrorMesssage(response.message);
				setShowErrorMessage(true);
			}
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			Toast({
				title: 'No Affiliates Matches Your Search Results , Please add a New Affiliate',
				type: ToastTypes.ERROR,
			});
		}
	};

	const selectAffiliate = () => {
		dispatch(
			SetAffiliate({
				AffiliateData: affiliateInfo,
				AffiliateConfigData: affiliateConfig,
			})
		);
		setShowAddAffiliateModal(false);
	};

	useEffect(() => {
		if (affiliate) {
			const dicountAmountTemp =
				subTotal * (affiliateConfig?.discountPercentageForCustomer / 100);

			const subTotalAterDiscount = subTotal - dicountAmountTemp;

			const finalAmount = subTotalAterDiscount + gratuityAmount + taxAmount;

			dispatch(SetcustomerDiscountAmountFromAffiliateCode(dicountAmountTemp));
			dispatch(SetFinalAmountAfterAllDiscounts(finalAmount));
			setTotalAmountDue(finalAmount - totalAmountPaid);
		} else {
			dispatch(SetcustomerDiscountAmountFromAffiliateCode(0));
			dispatch(SetFinalAmountAfterAllDiscounts(totalAmountWithTaxAndGratuity));
			setTotalAmountDue(totalAmountWithTaxAndGratuity - totalAmountPaid);
		}
	}, [affiliate, gratuityAmount]);

	return (
		<Modal
			title='Select Affiliate'
			content={
				<>
					<div className='flex flex-col gap-5'>
						<InputTextField
							name='affiliateId'
							fieldId='affiliateId'
							onChange={(e) => setAffiliateId(e.target.value)}
							value={affiliateId}
							labelName='Enter or Scan Affiliate ID'
						/>
						{affiliateInfo && (
							<div className='flex justify-between items-center bg-teal-700 p-2 text-white rounded'>
								<div>
									<h1>
										Affiliate Name : {affiliateInfo?.First_Name?.toUpperCase()}{' '}
										{affiliateInfo?.Last_Name?.toUpperCase()}
									</h1>
									<h1>
										Discount Percentage :{affiliateConfig.discountPercentageForCustomer} %
									</h1>
								</div>
								<div>
									<Icon
										className='text-white text-4xl cursor-pointer'
										onClick={() => {
											dispatch(SetAffiliate(null));
											setAffiliateInfo(null);
											setAffiliateConfig(null);
											setAffiliateId('');
										}}
										icon={IconTypes.Delete}
									/>
								</div>
							</div>
						)}

						{showErrorMessage && (
							<ErrorMessage
								setShowErrorMessage={setShowErrorMessage}
								message={errorMessage}
							/>
						)}
						<div className='flex justify-end w-full gap-5'>
							<Button
								variant='outlined'
								color='info'
								title='Cancel'
								onClick={() => setShowAddAffiliateModal(false)}
							/>
							{affiliateInfo ? (
								<Button
									variant='contained'
									color='info'
									title='Select'
									onClick={() => selectAffiliate()}
								/>
							) : (
								<Button
									variant='contained'
									color='info'
									title='Search'
									disabled={!affiliateId}
									onClick={() => getAffiliateInfo()}
								/>
							)}
						</div>
					</div>
				</>
			}
			modalId='affiliates'
			open={showAddAffiliateModal}
			setOpen={setShowAddAffiliateModal}
			size='sm'
			closeIcon
		/>
	);
}

export default AddAffiliate;

export interface AddAffiliateProps {
	showAddAffiliateModal: boolean;
	setShowAddAffiliateModal: (value: boolean) => void;
	setTotalAmountDue: (value: number) => void;
}
