import { IProductInterface } from 'interfaces';
import React from 'react';
import Modal from 'storybook-mui/build/components/Modal';

function AddPlayModal({ open, setOpen, product }: AddPlayModalProps) {
	return (
		<Modal
			open={open}
			setOpen={() => setOpen(false) }
			title='Add Play Event'
			content={
				<div>
					<p>Gift Card</p>
          {product && product.name}
				</div>
			}
			modalId='add-gift-card-modal'
			closeIcon
		/>
	);
}

export default AddPlayModal;

export interface AddPlayModalProps {
	open: boolean;
	setOpen: any;
	product: IProductInterface;
}
