import Toast, { ToastTypes } from 'components/ToastNotify';
import { GetOrganizationInfoById } from 'firebaseApis/locations';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useReactToPrint } from 'react-to-print';
import Button from 'storybook-mui/build/components/Button';
import { IOrderData, IProductInOrder } from 'interfaces';
import { GetOrderById } from 'firebaseApis/orders';
import { IPos } from 'reducers/POS';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';

function Receipt({ orderId, onClose }: IReceipt) {
	const { currency }: IPos = useSelector((state: any) => state.posReducer);
	const [orgData, setOrgData] = useState<any>(null);
	const [orderData, setOrderData] = useState<IOrderData>(null);
	const componentRef = useRef();
	const dispatch: any = useDispatch();
	const getData = async () => {
		try {
			dispatch(showLoading());
			const result = await GetOrganizationInfoById();
			const result2 = await GetOrderById(orderId.toString());
			setOrderData(result2);
			setOrgData(result);
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: 'Error', type: ToastTypes.ERROR });
		}
	};

	useEffect(() => {
		getData();
	}, []);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		orderData &&
		orgData && (
			<div>
				<div className='flex flex-col gap-5 p-5 h-max' ref={componentRef}>
					<div className='flex flex-col'>
						<div className='flex justify-center'>
							{orgData.LogoUrl !== '' && (
								<img src={orgData?.Logo_Url} alt='' className='h-24 w-44 mb-5' />
							)}
						</div>
						<h1>Order Id : {orderData?.id}</h1>
						<h1>Order Status : {orderData.order_status}</h1>
						<h1>Payment Status : {orderData?.payment_status}</h1>
						{orderData?.refund_status && (
							<h1>Refund Status : {orderData?.refund_status}</h1>
						)}
						{orderData?.customer_info.id !== 'GUEST' && (
							<h1>
								Customer Name : {orderData?.customer_info?.First_Name}{' '}
								{orderData?.customer_info?.Last_Name}
							</h1>
						)}
						<h1>Date : {moment().format('MM-DD-YYYY hh:mm A')}</h1>
					</div>

					<div className='h-[1px] border-[1px] border-dashed border-gray-500 w-full my-2' />
					<div>
						<div className='grid grid-cols-3 justify-between w-full text-sm'>
							<h1 className='font-bold'>#Item</h1>
							<h1 className='font-bold mx-5'>#Price</h1>
							<h1 className='font-bold'>#Total</h1>
						</div>
						<div className='h-[1px] border-[1px] border-dashed border-gray-500 w-full my-5' />
						{orderData.items.map((item: IProductInOrder, index) => (
							<div className='grid grid-cols-3 w-full text-[13px]' key={index}>
								<h1>{item.ProductLabel}</h1>
								<h1 className='mx-2'>
									{currency} {item.ItemAmount} ({item.Quantity})
								</h1>

								<h1>
									{currency} {item.UnitPrice * item.Quantity}
								</h1>
							</div>
						))}
					</div>
					<div className='h-[1px] bg-gray-200 w-full' />
					<div>
						<h1 className='font-semibold'>ORDER SUMMARY</h1>
						<h1>
							SubTotal : {currency} {orderData.amount.Subtotal}
						</h1>

						<h1>
							Gratuity : {currency} {orderData.amount.Tip}
						</h1>

						<h1>
							Tax : {currency} {orderData.amount.Tax}
						</h1>
						{orderData.amount.AffiliateCustomerDiscount > 0 && (
							<h1>
								Discount :  {currency} {orderData.amount.AffiliateCustomerDiscount}
							</h1>
						)}
						{orderData.amount.CouponDiscount > 0 && (
							<h1>
								Discount : {currency} {orderData.amount.CouponDiscount}
							</h1>
						)}
					</div>
					<div className='flex flex-col'>
						<div>
							<h1 className='font-semibold'>PAYMENT METHODS </h1>
							{orderData.order_status === 'Completed' && (
								<>
									{Object.keys(orderData?.payment_modes).map(
										(key) =>
											orderData.payment_modes[key] > 0 && (
												<h1>
													{key.toUpperCase()} : {currency} {orderData.payment_modes[key]}
												</h1>
											)
									)}
								</>
							)}
						</div>
					</div>
					<div className='h-[1px] bg-gray-200 w-full' />
					<h1 className='text-xl font-semibold'>
						TOTAL : {currency} {orderData.amount.Total.toFixed(2)}
					</h1>
					<div className='h-[1px] border-[1px] border-dashed border-gray-500 w-full my-1' />
					<div className='flex justify-center items-center flex-col'>
						<h1>{orgData?.Org_Name?.toUpperCase()}</h1>
						<h1>
							{orgData?.City} , {orgData?.State} , {orgData?.Zip}
						</h1>
						<div className='h-[1px] bg-gray-200 w-full my-2' />
						<h1 className='text-center'>Thanks to You , Visit Again</h1>
					</div>
				</div>

				<div className='flex items-center justify-end gap-5'>
					<Button
						title='Close'
						onClick={onClose}
						variant='outlined'
						color='secondary'
					/>
					<Button
						title='Print Receipt'
						onClick={() => handlePrint()}
						variant='contained'
						color='secondary'
					/>
				</div>
			</div>
		)
	);
}

export default Receipt;

export interface IReceipt {
	orderId: string;
	onClose: () => void;
}
