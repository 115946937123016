import React, { useEffect, useState } from 'react';
import MUIGridGloabl from 'components/MuiGrid/MUIGlobalGrid';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { GridColDef } from '@mui/x-data-grid-pro';
import { GetPosAffiliates } from 'firebaseApis/affiliates';
import Button from 'storybook-mui/build/components/Button';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import RedeemPointsModal from './RedeemPointsModal';
import PointsHistory from './PointsHistory';

function Affiliate() {
	const [affiliatesData, setAffiliatesData] = React.useState<[]>([]);
	const [showAffiliatePointsHistory, setShowAffiliatePointsHistory] =
		React.useState<boolean>(false);
	const [showRedeemPointsModal, setShowRedeemPointsModal] = useState(false);
	const [selectedAffiliate, setSelectedAffiliate] = useState<any>(null);
    const dispatch:any = useDispatch();
	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: 'ID',
			minWidth: 200,
		},
		{
			headerName: 'Name',
			field: 'name',
			flex: 1,
			renderCell: (params) => (
				<>
					{params.row?.First_Name} {params.row?.Last_Name}
				</>
			),
		},
		{
			headerName: 'Points Earned',
			field: 'loyaltyPointsEarned',
			flex: 1,
			renderCell: (params) => <>{params.row?.LoyaltyPoints?.Total}</>,
		},
		{
			headerName: 'Points Available',
			field: 'availablePoints',
			flex: 1,
			renderCell: (params) => <>{params.row?.LoyaltyPoints?.Available}</>,
		},
		{
			headerName: 'Points Redeemed',
			field: 'loyaltyPointsRedeemed',
			flex: 1,
			renderCell: (params) => <>{params.row?.LoyaltyPoints?.Used}</>,
		},
		{
			headerName: 'Actions',
			field: 'actions',
			minWidth: 350,
			renderCell: (params) => (
				<div className='flex gap-5'>
					{params.row?.LoyaltyPoints?.Available > 0 && (
						<Button
							variant='outlined'
							color='info'
							onClick={() => {
								setSelectedAffiliate(params.row);
								setShowRedeemPointsModal(true);
							}}
							title='Redeem Points'
						/>
					)}
					{params.row?.LoyaltyPoints?.Total > 0 && (
						<Button
							variant='outlined'
							color='info'
							onClick={() => {
								setSelectedAffiliate(params.row);
								setShowAffiliatePointsHistory(true);
							}}
							title='Points History'
						/>
					)}
				</div>
			),
		},
	];

	const getData = async () => {
		try {
			dispatch(showLoading())
			const response = await GetPosAffiliates();
			setAffiliatesData(response);
			dispatch(hideLoading())
		} catch (error) {
			dispatch(hideLoading())
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<div>
			<div className='flex justify-end items-start my-5'>
				<Button
					title='Add Affiliate'
					onClick={() => {
						window.open(
							`${localStorage.getItem('redirectionLink')}/Dynamic/Affiliates/New`,
							'_blank'
						);
					}}
					variant='contained'
					color='primary'
				/>
			</div>
			<MUIGridGloabl
				columns={columns}
				dataSource={affiliatesData ?? []}
				rowCount={affiliatesData.length}
			/>

			{showAffiliatePointsHistory && (
				<PointsHistory
					showAffiliatePointsHistory={showAffiliatePointsHistory}
					setShowAffiliatePointsHistory={setShowAffiliatePointsHistory}
					selectedAffiliate={selectedAffiliate}
				/>
			)}

			{showRedeemPointsModal && (
				<RedeemPointsModal
					setShowRedeemPointsModal={setShowRedeemPointsModal}
					showRedeemPointsModal={showRedeemPointsModal}
					affiliate={selectedAffiliate}
					getData={getData}
				/>
			)}
		</div>
	);
}

export default Affiliate;
