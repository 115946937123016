import { IAffiliateConfig } from 'interfaces';
import React from 'react';
import { useSelector } from 'react-redux';
import { IPos } from 'reducers/POS';
import Button from 'storybook-mui/build/components/Button';
import Modal from 'storybook-mui/build/components/Modal';

function DiscountsAppliedModal({
	showDiscountsAppliedModal,
	setShowDiscountsAppliedModal,
}: IDiscountsAppliedModalProps) {
	const {
		affiliate,
		customerDiscountAmountFromAffiliateCode,
		currency,
		coupon,
		couponDiscountAmount,
	}: IPos = useSelector((state: any) => state.posReducer);
	const affiliateInfo = affiliate?.AffiliateData;
	const affiliateConfig: IAffiliateConfig = affiliate?.AffiliateConfigData;
	return (
		<Modal
			title='Applied Discount'
			content={
				<>
					{affiliate && (
						<div className='shadow border p-3'>
							<h1 className='text-info-500 font-semibold'>
								Discount Amount : {currency}
								{customerDiscountAmountFromAffiliateCode}
							</h1>
							<hr />
							<h1>
								Affiliate Name : {affiliateInfo?.First_Name?.toUpperCase()}{' '}
								{affiliateInfo?.Last_Name?.toUpperCase()}
							</h1>

							<h1>
								Customer Discount Perntage :{' '}
								{affiliateConfig.discountPercentageForCustomer}{' '}
							</h1>
						</div>
					)}
					{coupon && (
						<div className='shadow border p-3 bg-info'>
							<h1 className='text-info-500 font-semibold'>
								Discount Amount : {currency}
								{couponDiscountAmount.toFixed()}
							</h1>
							<h1>Coupon Code : {coupon?.couponCode}</h1>
							<h1>Discount Type : {coupon?.discountType}</h1>
							<h1>Discount Value : {coupon?.discountValue}</h1>
							<h1>Discount Applicable On : {coupon?.appliedOn.toUpperCase()}</h1>
						</div>
					)}

					<div className='flex justify-end w-full gap-5 mt-5'>
						<Button
							variant='outlined'
							color='info'
							title='Cancel'
							onClick={() => setShowDiscountsAppliedModal(false)}
						/>
					</div>
				</>
			}
			modalId='discountsAppliedModal'
			open={showDiscountsAppliedModal}
			setOpen={setShowDiscountsAppliedModal}
			size='sm'
		/>
	);
}

export default DiscountsAppliedModal;

export interface IDiscountsAppliedModalProps {
	showDiscountsAppliedModal: boolean;
	setShowDiscountsAppliedModal: any;
}
