import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore/lite';
import CryptoJS from 'crypto-js';
import { doc, getDoc } from 'firebase/firestore';
import { masterDb } from 'firebaseConfig';
import store from 'reducers';

export interface ISiteConfigConstants {
	REACT_APP_AUTHORIZATION: string;
	REACT_APP_BASE_URL: string;
	REACT_APP_CLOUD_URL: string;
	REACT_APP_EMAIL_URL: string;
	REACT_APP_ImageEditor: string;
	REACT_APP_MODE: string;
	REACT_APP_SMS_URL: string;
	REACT_APP_SOCIAL_FEEDS_URL: string;
	REACT_APP_SOCIAL_URL: string;
	REACT_APP_TWILIO_APIS_URL: string;
	REACT_APP_VERIFONE_ENCRYPTION_KEY: string;
	REACT_APP_VOICE_URL: string;

	// pos cloud runs
	REACT_APP_POS_CATALOG: string;
	REACT_APP_POS_CATEGORY: string;
	REACT_APP_POS_CATALOG_CATEGORY: string;
	REACT_APP_POS_PRODUCT: string;
	REACT_APP_POS_IMAGE: string;

	REACT_APP_POS_STORE: string;
}

export const getUser: any = async () => {
	const userInRedux = store.getState().posReducer.user;

	if (!userInRedux) {
		const token = localStorage.getItem('logintoken');
		if (token !== undefined && token !== null) {
			try {
				const bytes = CryptoJS.AES.decrypt(token.replaceAll(' ', '+'), 'LEAP360');
				const decreptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
				const organizationId = decreptedToken?.organizationId;
				const orgDataInMasterDb: any = await getDoc(
					doc(
						masterDb,
						`${process.env.REACT_APP_VERTICAL_DOMAIN}/Organizations/Organizations`,
						organizationId
					)
				);
				localStorage.setItem('redirectionLink', decreptedToken.Redirection_Link);
				const userOrgDta = {
					...orgDataInMasterDb.data(),
					...decreptedToken,
					env: decreptedToken?.env || 'SandBox',
				};
				return userOrgDta;
			} catch (error) {
				localStorage.clear();
				return null;
			}
		} else {
			localStorage.clear();
			return {};
		}
	} else {
		return userInRedux;
	}
};

export const getUserInCustomerScreen: any = async ({ organizationId }) => {
	const userInRedux = store.getState().posReducer.user;
	if (!userInRedux) {
		try {
			const orgDataInMasterDb: any = await getDoc(
				doc(
					masterDb,
					`${process.env.REACT_APP_VERTICAL_DOMAIN}/Organizations/Organizations`,
					organizationId
				)
			);
			localStorage.setItem('redirectionLink', 'temp');
			const userOrgDta = {
				...orgDataInMasterDb.data(),
				organizationId,
				paymentTerminalId: '',
				env: 'Sandbox',
			};
			return userOrgDta;
		} catch (error) {
			localStorage.clear();
			return null;
		}
	} else {
		return userInRedux;
	}
};

export const getUserFromRedux: any = () => {
	const userInRedux = store.getState().posReducer.user;
	return userInRedux;
};

export const siteConfigConstants = (): ISiteConfigConstants =>
	getUserFromRedux()?.Constant_Value ?? {};

export const cloudBaseUrl = (): string =>
	siteConfigConstants().REACT_APP_CLOUD_URL;

export const getUserName: any = () => {
	const user = getUserFromRedux();
	if (user) {
		return `${user.fName} ${user.lName}`;
	}
	return '';
};

export const getOrganisationIdFirebase = () =>
	getUserFromRedux()?.organizationId;

export const getUserId = () => getUserFromRedux().userId;

export const getClientDB: any = () => {
	const user = getUserFromRedux();
	const app = initializeApp(user?.Firestore_Config, user.organizationId);
	const db = getFirestore(app);
	return db;
};

export const getXProjKey = () =>
	getUserFromRedux().Firestore_Config['x-proj-key'];

export const clear = () => localStorage.clear();

export const create = () => {
	axios.defaults.headers.common = {
		'x-proj-key': getXProjKey(),
	};
	return axios.create({
		baseURL: siteConfigConstants().REACT_APP_BASE_URL,
	});
};

export const axiosInstance = () => axios;
