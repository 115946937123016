import Toast, { ToastTypes } from 'components/ToastNotify';
import { GetTransactionLogsByOrderId } from 'firebaseApis/payments';
import { IOrderData } from 'interfaces';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';

function TransactionsLogs({ orderData }: ITransactionsLogsProps) {
	const [logs, setLogs] = React.useState<[]>([]);
	const dispatch: any = useDispatch();
	const getLogs = async () => {
		try {
			dispatch(showLoading());
			const response: any = await GetTransactionLogsByOrderId(orderData.id);
			if (response) setLogs(response);
			else setLogs([]);
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			setLogs([]);
			Toast({ title: 'Error getting transactional logs', type: ToastTypes.ERROR });
		}
	};

	useEffect(() => {
		getLogs();
	}, []);
	return (
		<div className='flex flex-col p-5 gap-5'>
			{logs &&
				logs?.map((log: any) => {
					const errors = log?.response?.transactionResponse?.errors || [];
					const messages = log?.response?.transactionResponse?.messages || [];
					return (
						<div className='flex flex-col   gap-2 p-5'>
							<h1 className='font-semibold'>Ref Id : {log?.response?.refId}</h1>
							<h1 className='font-semibold'>
								Transaction Id : {log.response?.transactionResponse?.transId}
							</h1>
							<h1>
								Date & Time : {moment(log.created_at).format('MM-DD-YYYY hh:mm:ss A')}
							</h1>
							<h1>
								Card Number : {log?.response?.transactionResponse?.accountNumber || ''}
							</h1>
							<h1>Card Type : {log?.response?.transactionResponse?.accountType}</h1>
							<h1>
								Response Code : {log?.response?.transactionResponse?.responseCode}
							</h1>

							{messages.length > 0 && (
								<div className='flex flex-col'>
									{messages.map((message: any, index) => (
										<h1 className='text-green-700 font-semibold text-xl'>
											Message {index + 1} : {message?.description}
										</h1>
									))}
								</div>
							)}
							{errors.length > 0 && (
								<div className='flex'>
									{errors.map((error: any, index) => (
										<h1 className='text-red-700 font-semibold text-xl'>
											Message {index + 1} : {error?.errorText}
										</h1>
									))}
								</div>
							)}
						</div>
					);
				})}

			{logs.length === 0 && <h1>No Transactional Logs Found</h1>}
		</div>
	);
}

export default TransactionsLogs;

export interface ITransactionsLogsProps {
	orderData: IOrderData;
}
