import { GridColDef } from '@mui/x-data-grid-pro';
import MUIGridGloabl from 'components/MuiGrid/MUIGlobalGrid';
import {
	GetCustomerPointsRedemtions,
	GetOrderOfCustomer,
} from 'firebaseApis/contacts';
import moment from 'moment';
import React, { useEffect } from 'react';
import Modal from 'storybook-mui/build/components/Modal';
import { useHistory } from 'react-router-dom';
import { IOrderData } from 'interfaces';
import { IPos } from 'reducers/POS';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import ToggleButtonGroups from 'components/ToggleButtonGroups';

function CustomerOrders({
	showCustomersOrdersModal,
	setShowCustomersOrdersModal,
	selectedCustomer,
}: ICustomerOrders) {
	const [pointsHistoryView, setPointsHistoryView] = React.useState<
		'earned' | 'redeemed'
	>('earned');
	const [customerOrdersData, setCustomerOrdersData] = React.useState<any>([]);
	const [redeemedPointsData = [], setRedeemedPointsData] = React.useState<any>(
		[]
	);
	const { currency }: IPos = useSelector((state: any) => state.posReducer);
	React.useState<any>([]);
	const dispatch: any = useDispatch();
	const history = useHistory();
	const customerOrdersColumns: GridColDef[] = [
		{
			field: 'orderid',
			headerName: 'Order ID',
			flex: 1,
			renderCell: (params: any) => (
				<h1
					className='underline cursor-pointer text-md text-info-500'
					onClick={() => history.push(`/reports/order/${params.row.orderid}`)}
				>
					{params.value}
				</h1>
			),
		},
		{
			field: 'orderDate',
			headerName: 'Order Date',
			flex: 1,
			renderCell: (params) => (
				<h1>{moment(params?.row?.created_at).format('MM-DD-YYYY hh:mm A')}</h1>
			),
		},
		{
			field: 'Items Total',
			headerName: 'Items Total',
			minWidth: 150,
			renderCell: (params) => {
				const orderData: IOrderData = params?.row;
				const itemsTotal = orderData.items.reduce(
					(acc: number, item: any) => acc + item.UnitPrice * item.Quantity,
					0
				);
				return (
					<h1>
						{currency}
						{itemsTotal}
					</h1>
				);
			},
		},
		{
			field: 'Gratuity',
			headerName: 'Gratuity',
			minWidth: 100,
			renderCell: (params) => (
				<>
					{' '}
					{currency} {params.row.amount.Tip}
				</>
			),
		},
		{
			field: 'Tax',
			headerName: 'Tax',
			minWidth: 100,
			renderCell: (params) => (
				<>
					{' '}
					{currency} {params.row.amount.Tax}
				</>
			),
		},
		{
			field: 'Discounts',
			headerName: 'Discounts',
			minWidth: 250,
			renderCell: (params) => (
				<div className='flex flex-col p-2'>
					<h1>
						Affiliate Code Discount: {currency}{' '}
						{params.row.amount.AffiliateCustomerDiscount}
					</h1>
					<h1>
						Coupon Discount: {currency} {params.row.amount.CouponDiscount}
					</h1>
				</div>
			),
		},
		{
			field: 'Total',
			headerName: 'Total',
			minWidth: 100,
			renderCell: (params) => (
				<>
					{currency} {params.row.amount.Total}
				</>
			),
		},
		{
			field: 'Points Earned',
			headerName: 'Loaylty Points Earned',
			flex: 1,
			renderCell: (params) => (
				<>{params?.row?.CustomerLoyalty?.LoyaltyPointsEarnedByCustomer}</>
			),
		},
	];
	const redeemedPointsColumns: GridColDef[] = [
		{
			field: 'orderId',
			headerName: 'Date',
			flex: 1,
			renderCell: (params) => (
				<h1>{moment(params?.row?.date).format('MM-DD-YYYY hh:mm A')}</h1>
			),
		},
		{
			field: 'staffId',
			headerName: 'Staff ID',
			flex: 1,
		},
		{
			field: 'pointsRedeemed',
			headerName: 'Points Redeemed',
			flex: 1,
		},
		{
			field: 'notes',
			headerName: 'Notes',
			flex: 1,
		},
	];

	const getData = async () => {
		try {
			dispatch(showLoading());
			const result = await GetOrderOfCustomer(selectedCustomer?.id);
			setCustomerOrdersData(result);
			const redeemedPointsResult = await GetCustomerPointsRedemtions(
				selectedCustomer.id
			);
			setRedeemedPointsData(redeemedPointsResult);
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			setCustomerOrdersData([]);
			setRedeemedPointsData([]);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<Modal
			title='Customer Orders'
			content={
				<>
					<div className='flex my-2'>
						<ToggleButtonGroups
							onChange={(event, value) => {
								if (value) {
									setPointsHistoryView(value);
								}
							}}
							value={pointsHistoryView}
							buttons={[
								{
									content: 'Earned Points',
									value: 'earned',
								},
								{
									content: 'Redeemed Points',
									value: 'redeemed',
								},
							]}
							size='large'
							color='info'
						/>
					</div>
					{pointsHistoryView === 'earned' && (
						<MUIGridGloabl
							columns={customerOrdersColumns}
							dataSource={customerOrdersData}
							rowCount={customerOrdersData?.length || []}
							dynamicRowHeight
						/>
					)}
					{pointsHistoryView === 'redeemed' && (
						<MUIGridGloabl
							columns={redeemedPointsColumns}
							dataSource={redeemedPointsData}
							rowCount={redeemedPointsData?.length || []}
						/>
					)}
				</>
			}
			modalId='customerPointsHistory'
			open={showCustomersOrdersModal}
			setOpen={() => {
				setShowCustomersOrdersModal(false);
			}}
			size='lg'
			closeIcon
		/>
	);
}

export default CustomerOrders;

export interface ICustomerOrders {
	selectedCustomer: any;
	showCustomersOrdersModal: boolean;
	setShowCustomersOrdersModal: (value: boolean) => void;
}
