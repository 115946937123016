import {
	getClientDB,
	getOrganisationIdFirebase,
	create,
	siteConfigConstants,
	getUserFromRedux,
} from 'api/AxiosManager';
import {
	doc,
	getDoc,
	getDocs,
	query,
	updateDoc,
	where,
} from 'firebase/firestore';
import { collection, addDoc, orderBy } from 'firebase/firestore/lite';
import { IProductInterface } from 'interfaces';
import moment from 'moment';
import store from 'reducers';
import { IPos } from 'reducers/POS';

export const GetAllCountries = async () => {
	const qry = query(
		collection(getClientDB(), `${getOrganisationIdFirebase()}/Lookups/Countries`)
	);
	const docResult: any = await getDocs(qry);
	const resultArray = docResult.docs.map((document: any) => ({
		id: document.id,
		...document.data(),
	}));

	return resultArray;
};

export const SearchContacts = async ({ values, pageNum, pageSize }) => {
	const queries = [];
	Object.keys(values).forEach((key, index) => {
		if (key === 'Email_Id' || key === 'Phone_Number') {
			if (values[key] !== '')
				queries.push({
					groupedFieldName: key === 'Email_Id' ? 'Emails' : 'Phones',
					fieldName: '',
					groupedFieldKey: key,
					Operator: 'CONTAINS',
					logical_seperator_with_prev: index === 0 ? 'AND' : 'OR',
					fieldValue: values[key],
					data_type: 'STRING',
					subCollectionName: '',
					dataSetName: 'Person',
				});
		} else if (values[key] !== '') {
			queries.push({
				groupedFieldName: '',
				fieldName: key,
				groupedFieldKey: '',
				Operator: 'CONTAINS',
				logical_seperator_with_prev: index === 0 ? 'AND' : 'OR',
				fieldValue: values[key],
				data_type: 'STRING',
				subCollectionName: '',
				dataSetName: 'Person',
			});
		}
	});
	const payload = {
		organization_id: getOrganisationIdFirebase(),
		query: queries,
		defaultDataSetName: 'Person',
		start_event_date: '2020-01-01',
		numPerPage: pageSize,
		page: pageNum,
	};
	const instance = await create();

	const response = await instance.post(
		`${
			siteConfigConstants().REACT_APP_BASE_URL
		}/dynamicdataset/getDynamicDataSetRelationPagenation?called_from=UI`,
		payload
	);
	const data = response?.data?.results.map((item: any) => ({
		id: item.document_id,
		...item.document,
	}));
	return {
		data,
		success: true,
		pagination: response?.data?.pagination,
	};
};

export const SaveContact = async (payload: any) => {
	const formatedPayload = {
		First_Name: payload.First_Name,
		Last_Name: payload.Last_Name,
		ContactInfo: [
			{
				Email_Id: payload.Email_Id,
				Country_Code: payload.Country_Code,
				Phone_Number: payload.Phone_Number,
			},
		],
		GroupedFieldsValues: {
			Email: payload.Email_Id,
			Phone_Number: payload.Phone_Number,
			Country_Code: payload.Country_Code,
		},
		Verison: '1.0.0',
	};
	const result = await addDoc(
		collection(getClientDB(), `/${getOrganisationIdFirebase()}/Person/Person`),
		{
			...formatedPayload,
			Created_At: moment().toString(),
		}
	);
	const instance = await create();

	await instance.post(
		`${
			siteConfigConstants().REACT_APP_BASE_URL
		}/importexportcontacts/insertContact?organization_id=${getOrganisationIdFirebase()}&docid=${
			result.id
		}`
	);

	return {
		data: { ...formatedPayload, id: result.id },
	};
};

export const GetPosContacts = async () => {
	const qry = query(
		collection(getClientDB(), `${getOrganisationIdFirebase()}/Person/Person`)
	);
	const docResult: any = await getDocs(qry);
	const resultArray = docResult.docs
		.filter((dc) => dc?.data().Display !== false)
		.map((document: any) => ({
			id: document.id,
			...document.data(),
			...document.data().GroupedFieldsValues,
		}));

	return resultArray;
};

export const RewardLoyaltyPointsToCustomer = async () => {
	try {
		const { subTotal, customer, orderId }: IPos = store.getState().posReducer;

		const customerLoyaltyConfig = await getDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}/POS/CustomerLoyaltyConfigs/${
					customer?.Loyalty_Config_Type
				}`
			)
		);
		const customerLoyaltyConfigData = customerLoyaltyConfig.data();
		const pointsEarnedForThisOrder =
			subTotal * customerLoyaltyConfigData.loyaltyPoints;
		let loyaltyPoints = null;
		if (customer?.PosLoyaltyPoints) {
			loyaltyPoints = {
				...customer.PosLoyaltyPoints,
				Total: customer.PosLoyaltyPoints.Total + pointsEarnedForThisOrder,
				Available: customer.PosLoyaltyPoints.Available + pointsEarnedForThisOrder,
			};
		} else {
			loyaltyPoints = {
				Total: pointsEarnedForThisOrder,
				Used: 0,
				Available: pointsEarnedForThisOrder,
			};
		}

		// update customer loyalty points
		await updateDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}/Contacts/Contacts`,
				customer.id
			),
			{
				PosLoyaltyPoints: loyaltyPoints,
			}
		);

		// update layalty points in order document
		await updateDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}/POS/orders/${orderId}`),
			{
				CustomerLoyalty: {
					LoyaltyPointsEarnedByCustomer: pointsEarnedForThisOrder,
					LoyaltyConfig: customerLoyaltyConfigData,
				},
			}
		);

		return {
			success: true,
		};
	} catch (error) {
		return error;
	}
};

export const RedeemCustomerLoyaltyPoints = async (
	customer,
	pointsToRedeem,
	notes
) => {
	try {
		const customerRef = doc(
			getClientDB(),
			`/${getOrganisationIdFirebase()}/Contacts/Contacts`,
			customer.id
		);
		const tempLoyaltyPoints = customer?.PosLoyaltyPoints;
		tempLoyaltyPoints.Available -= Number(pointsToRedeem);
		tempLoyaltyPoints.Used += Number(pointsToRedeem);
		// adding affiliate loyalty points history
		await addDoc(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/POS/Customer_Loyalty_Points_Redemptions`
			),
			{
				customerId: customer.id,
				pointsRedeemed: pointsToRedeem,
				date: moment().toString(),
				notes,
				staffId: getUserFromRedux().userId,
			}
		);

		// updating affiliate loyalty points
		await updateDoc(customerRef, {
			...customer,
			PosLoyaltyPoints: tempLoyaltyPoints,
		});

		return {
			success: true,
			message: 'Points redeemed successfully',
		};
	} catch (error) {
		return {
			success: false,
		};
	}
};

export const UpdateContactInBigQuery = async () => {
	try {
		const { customer }: IPos = store.getState().posReducer;
		const instance = create();
		await instance.post(
			`${
				siteConfigConstants().REACT_APP_BASE_URL
			}/dynamicdataset/updateRow?organization_id=${getOrganisationIdFirebase()}&docid=${
				customer.id
			}&dataset_name=Contacts`
		);
		return {
			success: true,
		};
	} catch (error) {
		return error;
	}
};

export const GetOrderOfCustomer = async (customerId: string) => {
	try {
		const qry = query(
			collection(getClientDB(), `${getOrganisationIdFirebase()}/POS/orders`),
			where('customer_id', '==', customerId),
			orderBy('created_at', 'desc')
		);
		const docResult: any = await getDocs(qry);
		const resultArray = docResult.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));

		return resultArray;
	} catch (error) {
		return error;
	}
};

export const GetCustomerPointsRedemtions = async (customerId) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`${getOrganisationIdFirebase()}/POS/Customer_Loyalty_Points_Redemptions`
			),
			where('customerId', '==', customerId),
			orderBy('date', 'desc')
		);
		const docResult: any = await getDocs(qry);
		const resultArray = docResult.docs
			.filter((dc: any) => dc?.data().Display !== false)
			.map((document: any) => ({
				id: document.id,
				...document.data(),
			}));

		return resultArray;
	} catch (error) {
		return [];
	}
};

export const AddGiftCardToCustomer = async () => {
	try {
		const { customer, orderId, laneItems, catalog }: IPos =
			store.getState().posReducer;
		const giftCardNumber = moment().format('YYYYMMDDHHmmss');

		// check all gift card items in lane and add gift card to customer
		const giftCardItems: any = laneItems.filter(
			(product: IProductInterface) => product?.product_type === 'giftcard'
		);

		if (giftCardItems.length > 0) {
			for (let i = 0; i < giftCardItems.length; i++) {
				const { quantity, product_price } = giftCardItems[i];
				const redeemValue = giftCardItems[i].category?.find(
					(cat: any) => cat.catalog_id === catalog.catalog_id
				).redeem_value;
				for (let j = 0; j < quantity; j++) {
					addDoc(
						collection(
							getClientDB(),
							`/${getOrganisationIdFirebase()}/POS/Gift_Cards`
						),
						{
							customerId: customer.id,
							customer,
							giftCardNumber,
							redeemValue,
							purchacePrice: product_price,
							createdBy: getUserFromRedux().userId,
							purchasedOn: moment().format('YYYY-MM-DD HH:mm:ss'),
							orderId,
							redeemed: false,
							balance: redeemValue,
						}
					);
				}
			}
		}

		return {
			success: true,
			message: 'Gift card added successfully',
		};
	} catch (error) {
		return {
			success: false,
		};
	}
};

export const ValidateGiftCard = async (giftCardNumber: string) => {
	try {
		const qry = query(
			collection(getClientDB(), `${getOrganisationIdFirebase()}/POS/Gift_Cards`),
			where('giftCardNumber', '==', giftCardNumber),
			where('redeemed', '==', false)
		);
		const docResult: any = await getDocs(qry);
		const resultArray = docResult.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));

		if (resultArray.length > 0) {
			return {
				success: true,
				data: resultArray[0],
			};
		}
		return {
			success: false,
			message: 'Gift card is not valid',
		};
	} catch (error) {
		return {
			success: false,
		};
	}
};

export const RedeemGiftCard = async (
	giftCardDocId: string,
	updatedGiftCardData: any
) => {
	try {
		await updateDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}/POS/Gift_Cards`,
				giftCardDocId
			),
			updatedGiftCardData
		);
		return {
			success: true,
			message: 'Gift card redeemed successfully',
		};
	} catch (error) {
		return {
			success: false,
			message: 'Gift card not redeemed',
		};
	}
};
