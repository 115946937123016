import React, { useEffect } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import { IdleTimerProvider } from 'react-idle-timer';

export interface IScroll {
	children: React.ReactNode;
}

const ScrollToTop = ({ children }: IScroll) => {
	const location = useLocation();
	const history = useHistory();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	const onIdle = () => {
		localStorage.removeItem('logintoken');
		history.push('/');
		window.location.reload();
	};

	return (
		<>
			{children}
			{localStorage.getItem('token') &&
				localStorage.getItem('token').length !== 0 && (
					<IdleTimerProvider timeout={1000 * 30000} onIdle={onIdle} />
				)}
		</>
	);
};

export default ScrollToTop;
