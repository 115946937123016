import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from 'reducers';
import {
	RemoveAllProductsFromLane,
	RemoveProductFromLane,
	SetProductQuantity,
} from 'reducers/POS/PosActions';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import IconButton from 'storybook-mui/build/components/IconButton';

import ClearTransaction from 'assets/images/clear-transaction.png';
import { IProductInterface } from 'interfaces';
import { RemoveAllLineItems, RemoveLineItems } from 'firebaseApis/lineitems';
import { IPos } from 'reducers/POS';

import { allowCharacters } from 'helpers/keyboardRestrictions';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import Toast, { ToastTypes } from 'components/ToastNotify';

function CartItems() {
	const {
		laneItems,
		orderId,
		customer,
		gratuityPercentage,
		subTotal,
		currency,
	}: IPos = useSelector((store: RootStore) => store.posReducer);
	const dispatch: any = useDispatch();
	const onDelete = async (item: IProductInterface) => {
		dispatch(RemoveProductFromLane(item));
		const subTotalTemp = subTotal - item.product_price;
		const tax = (subTotalTemp * 10) / 100;
		const gratuityAmount = (subTotalTemp * gratuityPercentage) / 100;
		const totalAmount = subTotalTemp + tax + gratuityAmount;
		if (orderId !== '') {
			await RemoveLineItems({
				orderId,
				customerId: customer.id,
				items: [item],
				subTotal: subTotalTemp,
				tax,
				total: totalAmount,
			});
		}
	};

	const onClearLineitems = async () => {
		try {
			dispatch(RemoveAllProductsFromLane());
			if (orderId !== '') {
				dispatch(showLoading());
				await RemoveAllLineItems({
					orderId,
					customerId: customer.id,
				});
				dispatch(hideLoading());
			}
		} catch (error) {
			dispatch(hideLoading());
			Toast({ type: ToastTypes.ERROR, title: 'Error deleting items' });
		}
	};
	return (
		<div>
			<div className='flex my-4 justify-between items-center'>
				<h1 className='text-leapblue-500 text-xl font-bold'>CART ITEMS</h1>
				{laneItems.length > 0 && (
					<img
						src={ClearTransaction}
						alt='clear-transactions'
						className='cursor-pointer aspect-auto'
						onClick={() => onClearLineitems()}
					/>
				)}
			</div>

			{laneItems.length > 0 && (
				<>
					<div className='bg-white grid grid-cols-4 gap-5  w-full'>
						<h1>Item</h1>
						<h1>Quantity</h1>
						<h1>Price</h1>
						<h1>Total</h1>
					</div>
					<div className='border my-5' />
					<div className='flex flex-col gap-1 overflow-auto overflow-y-scroll'>
						{laneItems.map((item: IProductInterface, index: number) => (
							<div
								key={index}
								className='bg-white grid grid-cols-4 gap-5 items-center'
							>
								<h1>{item.name}</h1>
								<div className='flex'>
									<input
										type='number'
										onChange={(e) =>
											dispatch(SetProductQuantity({ ...item, quantity: e.target.value }))
										}
										value={item.quantity}
										max={100}
										min={1}
										onKeyPress={(e: any) => {
											allowCharacters('numeric', e);
										}}
										className='w-14 rounded-md p-2 border-primary-500 border-2 focus:outline-primary-500 h-10 mx-5'
									/>
								</div>
								<h1>
									{currency} {item.product_price}
								</h1>
								<div className='flex items-center gap-2'>
									<h1>
										{currency} {item.product_price * item.quantity}
									</h1>
									<IconButton
										content={
											<Icon
												icon={IconTypes.Delete}
												className='cursor-pointer text-center'
											/>
										}
										size='small'
										onClick={() => onDelete(item)}
										id=''
										ariaLabel=''
										color='info'
									/>
								</div>
							</div>
						))}
					</div>
				</>
			)}
		</div>
	);
}

export default CartItems;
