import React from 'react';
import Modal from 'storybook-mui/build/components/Modal';
import CashImage from 'assets/images/pos-cash.png';
import VirtualCardImage from 'assets/images/pos-virtualcard.png';
import PaypalImage from 'assets/images/pos-paypal.png';
import PhysicalCardImage from 'assets/images/pos-physicalcard.png';
import GiftCardImage from 'assets/images/pos-gift-card.png';
import PunchCardImage from 'assets/images/pos-punch-card.png';
import { useSelector } from 'react-redux';
import { IPos } from 'reducers/POS';

function PaymentMethodSelection({
	index,
	setShowPaymentMethods,
	showPaymentMethods,
	selectedPaymentModes,
	setSelectedPaymentModes,
	type,
	setType,
	totalAmountDue,
}: PaymentMethodSelectionProps) {
	const { workStation }: IPos = useSelector((state: any) => state.posReducer);
	const availablePaymentMethods = [];

	if (workStation?.Available_Payment_Methods?.includes('Cash')) {
		availablePaymentMethods.push({
			name: 'Cash',
			value: 'cash',
			image: CashImage,
		});
	}
	if (workStation?.Available_Payment_Methods?.includes('Credit Card')) {
		availablePaymentMethods.push({
			name: 'Credit Card',
			value: 'virtualCards',
			image: VirtualCardImage,
		});
	}
	if (workStation?.Available_Payment_Methods?.includes('Physical Card')) {
		availablePaymentMethods.push({
			name: 'Physical Card',
			value: 'physicalCards',
			image: PhysicalCardImage,
		});
	}
	if (workStation?.Available_Payment_Methods?.includes('Paypal')) {
		availablePaymentMethods.push({
			name: 'Paypal',
			value: 'paypal',
			image: PaypalImage,
		});
	}

	if (workStation?.Available_Payment_Methods?.includes('Gift Card')) {
		availablePaymentMethods.push({
			name: 'Gift Card',
			value: 'giftcards',
			image: GiftCardImage,
		});
	}

	if (workStation?.Available_Payment_Methods?.includes('Punch Card')) {
		availablePaymentMethods.push({
			name: 'Punch Card',
			value: 'punchcards',
			image: PunchCardImage,
		});
	}

	const updatePaymentModeType = (i: number, value: string, image) => {
		const tempPaymentModes: any = [...selectedPaymentModes];
		tempPaymentModes[i].value = value;
		tempPaymentModes[i].name = availablePaymentMethods.find(
			(paymentMethod) => paymentMethod.value === value
		).name;
		tempPaymentModes[i].image = image;
		setSelectedPaymentModes(tempPaymentModes);
		setShowPaymentMethods(false);
		setType('add');
	};

	const addNewPaymentMode = (paymentMethod) => {
		setShowPaymentMethods(true);
		setSelectedPaymentModes([
			...selectedPaymentModes,
			{
				index: selectedPaymentModes.length,
				amount: Number(totalAmountDue),
				name: paymentMethod.name,
				value: paymentMethod.value,
				status: 'PENDING',
				image: paymentMethod.image,
			},
		]);
		setShowPaymentMethods(false);
	};
	return (
		<Modal
			title='Choose Payment Method'
			content={
				<>
					<div className='grid grid-cols-3 gap-5'>
						{availablePaymentMethods.map((paymentMethod) => (
							<div
								key={paymentMethod.value}
								className='flex items-center gap-5 justify-center p-4 border border-gray-300 shadow rounded-md cursor-pointer'
								onClick={() => {
									if (type === 'edit') {
										updatePaymentModeType(
											index,
											paymentMethod.value,
											paymentMethod.image
										);
									} else {
										addNewPaymentMode(paymentMethod);
									}
								}}
							>
								<img src={paymentMethod.image} alt='' className='h-12 w-18' />
								<h1 className='font-semibold'>{paymentMethod.name}</h1>
							</div>
						))}
					</div>
				</>
			}
			modalId={`paymentMethodSelection${index}`}
			open={showPaymentMethods}
			setOpen={() => setShowPaymentMethods(false)}
			closeIcon
			size='md'
		/>
	);
}

export default PaymentMethodSelection;

export interface PaymentMethodSelectionProps {
	showPaymentMethods: boolean;
	setShowPaymentMethods: (showPaymentMethods: boolean) => void;
	index: number;
	selectedPaymentModes: any;
	setSelectedPaymentModes: any;
	type: string;
	setType: any;
	totalAmountDue: number;
}
